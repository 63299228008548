import { v4 as uuid } from 'uuid'

import {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  PAPER_NAMES,
  ORIENTATION,
  // DATA_STRUCTURE,
  // NUMBERING_SYSTEM,
  NESTED_STRUCTURE_KEYS,
  NESTED_STRUCTURE_TYPES,
  SEGMENT_KEYS,
  IGNORE_STYLES,
  STYLES_PROPERTIES,
  STYLES_AFFIXES,
  STYLE_MAP,
  STYLES_DEFAULTS,
  RETAIN_KEYS,
  CASUS_IDS,
} from './constants'

const keyMapObject = {
  customStyle: 'styleName',
}

const valueMapObject = {
  id: id => id && String(id),
  styles: styles =>
    styles?.filter(s => !IGNORE_STYLES.includes(s)).map(s => Object.entries(STYLE_MAP).reduce((acc, [r, w]) => acc.replace(r, w), s)) || [],
  customStyle: cs => (!(cs && !IGNORE_STYLES.includes(cs)) ? '' : cs),
}

const mapDataStructureKey = key => (key && keyMapObject[key]) || key

const mapDataStructureValue = (key, value) => (key && valueMapObject[key] ? valueMapObject[key](value) : value)

const noIdTypes = [
  SEGMENT_TYPES.container,
  SEGMENT_TYPES.mark,
  SEGMENT_TYPES.tableHeader,
  SEGMENT_TYPES.tableBody,
  SEGMENT_TYPES.tableFooter,
  SEGMENT_TYPES.textChunk,
  SEGMENT_TYPES.textarea,
]

const parseAnswers = (answers = []) => answers.map(({ questionId: id, values }) => ({ id, values }))

// const compareObjects = (prev, next) =>
//   Object.entries(prev).reduce((acc, [key, value]) => acc && next[key] === value, true)

// const filterRedundantStyles = (styles = []) =>
//   styles.reduce((filtered, style) => {
//     const prevStyle = filtered[filtered.length - 1] || null
//     if (!(prevStyle && compareObjects(prevStyle, style))) filtered.push(style)
//     return filtered
//   }, [])

const extractStyles = (givenStyles = [], structure = {}) =>
  NESTED_STRUCTURE_KEYS.reduce(
    (acc, key) => (structure[key]?.length ? acc.concat(structure[key].reduce((acc, cur) => [...acc, ...extractStyles(givenStyles, cur)], [])) : acc),
    [structure.customStyle].concat(structure.styles).filter(s => s && !givenStyles.includes(s))
  )

const parseStyles = (cssData = {}, dataStructure = {}) => {
  const givenStyles = Object.keys(cssData.customStyles)
  const styles = Array.from(new Set(extractStyles(givenStyles, dataStructure).concat(Object.keys(STYLE_MAP))))
  // console.log('STYLES: ', styles)
  const dynamicStyles = styles.reduce(
    (acc, cur) => {
      // console.groupCollapsed(cur)
      const mappedArray = cur
        .split('-')
        .reduce(
          (accumulated, current) => {
            const joined = [accumulated.pop(), current].filter(s => s).join('-')
            const mapped = STYLE_MAP[joined]
            // console.log('ACCUMULATED: ', accumulated)
            // console.log('CURRENT: ', current)
            // console.log('JOINED: ', joined)
            // console.log('MAPPED: ', mapped)
            return accumulated.push(mapped || joined) && ((mapped && !accumulated.push('')) || accumulated)
          },
          ['']
        )
        .filter(s => s)
        .join('-')
        .split('-')
      // console.log('MAPPED ARRAY: ', mappedArray)
      const value = mappedArray.length > 1 && mappedArray.pop().replaceAll('__', ' ')
      // console.log('VALUE: ', value)
      const property = mappedArray.join('-')
      // console.log('PROPERTY: ', property)
      const defaultValue = STYLES_DEFAULTS[property]
      // console.log('DEFAULT VALUE: ', defaultValue)
      const style = acc[property]
      if (style && (value || defaultValue)) style.push(value || defaultValue)
      // console.log('STYLE: ', style)
      // console.groupEnd()
      return acc
    },
    Object.keys(STYLES_PROPERTIES).reduce((acc, cur) => Object.assign(acc, { [cur]: [] }), {})
  )
  return Object.entries(dynamicStyles).reduce((acc, [style, valArray]) => {
    valArray.forEach(value =>
      Object.assign(acc, {
        [`${style}-${value.replaceAll(' ', '__')}`]: {
          [STYLES_PROPERTIES[style]]: [STYLES_AFFIXES[style].prefix, value, STYLES_AFFIXES[style].suffix].filter(a => a).join(''),
        },
      })
    )
    return acc
  }, {})
}

const parseCustomStyle = (customStyle = '') => {
  const customStyleRegex = /(((?<=[.])[^>:@]+(?=:|>|$))|((?<=^)[^>:.]+(?=:|>|$)))((::|>)((?<=::|>).+))?/g
  const matches = [...customStyle.matchAll(customStyleRegex)][0]
  const className = (matches[2] || '').trim()
  const customProp = (matches[3] || '').trim()
  const appendix = (matches[4] || '').trim()
  const appendixSplitRegex = /((>|:|::)[^>:]+)/g
  const splitAppendix = [...appendix.matchAll(appendixSplitRegex)].map(([a]) => a.trim().replace(/\s+/g, ''))
  return [className, customProp, splitAppendix]
}

const assignStyleObject = (targetObject = {}, sourceObject = {}, path = []) => {
  if (!(Object.entries(sourceObject)?.length && path.length)) return targetObject
  const first = path.shift()
  const existing = targetObject[first] || {}
  if (!path.length) return Object.assign(targetObject, { [first]: { ...existing, ...sourceObject } })
  return Object.assign(targetObject, { [first]: assignStyleObject(existing, sourceObject, path) })
}

const propertyMap = {
  'margin-left': '--indent',
}

const parseCssData = (cssData = {}) =>
  Object.entries(cssData).reduce(
    (acc, [customStyle, styleString]) => {
      const propsRegex = /([^;]+)(?<=:)([^:]+)(?<=[;$])/g
      const styleObject = [...styleString.matchAll(propsRegex)].reduce((acc, cur) => {
        const property = cur[1].trim().replace(':', '').trim()
        const value = cur[2].trim().replace(';', '').trim()
        acc[propertyMap[property] || property] = value
        return acc
      }, {})
      const [className, customProp, appendices] = parseCustomStyle(customStyle)
      const path = customProp ? ['customProperties', customProp] : ['customStyles', className, ...appendices]
      return assignStyleObject(acc, styleObject, path)
    },
    {
      customStyles: {},
      customProperties: {},
    }
  )

const parseNumberingSystem = (numberingSystem, numbering) => Object.assign({}, numberingSystem, numbering)

const compareSets = (prev, next) => prev.size === next.size && Array.from(prev).every(s => next.has(s))

const mergeChunks = (chunks = []) =>
  chunks
    .reduce((acc, chunk) => {
      const { customStyle = '', styles = [], text = '' } = chunk
      const prevChunk = acc[acc.length - 1] || null
      const prevChunkStyles = [prevChunk?.customStyle, ...(prevChunk?.styles || [])].filter(s => s)
      const currentChunkStyles = [customStyle, ...styles].filter(s => s)
      const comparedStyles = compareSets(new Set(prevChunkStyles), new Set(currentChunkStyles))
      if (prevChunk && comparedStyles) prevChunk.text += text
      else acc.push(chunk)
      return acc
    }, [])
    .filter(({ text }) => text)

const parseDataStructure = (dataStructure = {}, defaultType = SEGMENT_TYPES.container) => {
  const { id, type = defaultType } = dataStructure
  const stripped = {}
  if (id === CASUS_IDS.dataStructure) Object.assign(stripped, { id })
  if (type) {
    Object.assign(
      [...SEGMENT_KEYS.all, ...(SEGMENT_KEYS[type] || []), ...RETAIN_KEYS]
        .filter(k => !(k === 'tag' || NESTED_STRUCTURE_KEYS.includes(k)))
        .reduce((acc, key) => Object.assign(acc, { [key]: mapDataStructureValue(key, dataStructure[mapDataStructureKey(key)]) }), stripped),
      { tag: SEGMENT_TAGS[type] }
    )
    if (!stripped.id && !noIdTypes.includes(type)) stripped.id = uuid()
    if (!stripped.type) stripped.type = type
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const { v2Styles: { pageBreak } = {} } = dataStructure
  // if (stripped.type === SEGMENT_TYPES.paragraph && Math.random() < 0.02) stripped.break = { type: 'page' }
  // if (stripped.type === SEGMENT_TYPES.paragraph && Math.random() < 0.005) stripped.break = { type: 'section' }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  NESTED_STRUCTURE_KEYS.reduce((acc, key) => {
    if (dataStructure[key]?.length) {
      const [defaultType] = NESTED_STRUCTURE_TYPES[key] || []
      const array = dataStructure[key].map(segment => parseDataStructure(segment, defaultType))
      const source = {
        [key]: key === 'textChunks' ? mergeChunks(array) : array,
      }
      Object.assign(acc, source)
    }
    return acc
  }, stripped)
  return stripped
}

const v3parse = (
  dataStructure = {},
  locations = { choice: {}, replacement: {} },
  cssData = {},
  questions = [],
  questionLayout = [],
  answers = []
) => {
  // console.log('V3 PARSE: ', dataStructure)
  const { numberingSystem, numberings } = dataStructure
  const parsedDataStructure = parseDataStructure(Object.assign(dataStructure, { id: CASUS_IDS.dataStructure }))
  // console.log('PARSED DATA STRUCTURE: ', parsedDataStructure)
  const parsedNumberingSystem = parseNumberingSystem(numberingSystem, numberings)
  const parsedCssData = parseCssData(cssData)
  const parsedStyles = parseStyles(parsedCssData, dataStructure)
  parsedCssData.dynamicStyles = parsedStyles
  const parsedAnswers = parseAnswers(answers)
  // console.log('PARSED ANSWERS: ', parsedAnswers, answers)
  return {
    dataStructure: parsedDataStructure,
    locations,
    cssData: parsedCssData,
    numberingSystem: parsedNumberingSystem,
    questions,
    questionLayout,
    answers: parsedAnswers,
  }
}

const unparseStyleString = (styleString, property = undefined, value = undefined) =>
  Object.entries(STYLE_MAP).find(
    entry =>
      (entry[1] === styleString && (property = entry[0])) ||
      (styleString.split('-').slice(0, -1).join('-') === entry[1] && (value = (property = entry[0]) && styleString.split('-').slice(-1)))
  )
    ? [property, value].filter(s => s).join('-')
    : styleString
const unparseMapObject = {
  styles: (structure, key) => structure[key].map(styleString => unparseStyleString(styleString)),
  id: (structure, key) => Number(structure[key]),
}
const unparseStructureKey = (structure, key) =>
  !(key === 'tag' || NESTED_STRUCTURE_KEYS.includes(key)) && structure[key]
    ? { [mapDataStructureKey(key)]: (unparseMapObject[key] && unparseMapObject[key](structure, key)) || structure[key] }
    : {}
const unparseNestedStructureKey = (structure, key) =>
  structure[key]?.length ? { [key]: structure[key].map(segment => unparseDataStructure(segment)) } : {}
const unparseDataStructure = (structure = {}) => {
  const { type } = structure
  const stripKeys = [...SEGMENT_KEYS.all, ...(SEGMENT_KEYS[type] || []), ...RETAIN_KEYS]
  const stripped = stripKeys.reduce((acc, key) => Object.assign(acc, unparseStructureKey(structure, key)), {})
  return NESTED_STRUCTURE_KEYS.reduce((acc, key) => Object.assign(acc, unparseNestedStructureKey(structure, key)), stripped)
}
const parseV3forBE = (payload = {}) =>
  payload.dataStructure ? Object.assign(payload, { dataStructure: unparseDataStructure(payload.dataStructure) }) : payload

export { parseAnswers, mergeChunks, v3parse, parseV3forBE }

/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////// OLD //////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////

// const KEYSTRINGS = {
//   remove: '_casus_remove',
//   keep: '_casus_keep',
//   highlight: '_casus_highlight',
// }

// const CASUS_IDS = {
//   rootElement: '_casus_root_element',
// }

// const CASUS_CLASSES = {
//   section: '_casus_section_root',
//   pageContentRoot: '_casus_page_content_root',
//   counterResetter: '_casus_counter_resetter',
//   segmentDiv: '_casus_segment_div',
//   configureDiv: '_casus_configure_div',
//   configureButton: '_casus_configure_button',
//   labelInput: '_casus_label_input',
//   textarea: '_casus_textarea',
//   textareaOverlay: '_casus_textarea_overlay',
//   paragraphSegment: '_casus_paragraph_segment',
//   tableSegment: '_casus_table_segment',
//   tableHeader: '_casus_table_header',
//   tableBody: '_casus_table_body',
//   tableFooter: '_casus_table_footer',
//   tableRow: '_casus_table_row',
//   tableCell: '_casus_table_cell',
//   textChunk: '_casus_text_chunk_span',
//   listDepthLevel: '_casus_list_depth_level',
// }

// const extractSubQuestions = (questionArray = []) =>
//   questionArray.reduce((acc, cur) => {
//     if (cur.options?.length)
//       cur.options.forEach(option => {
//         return acc.push(...extractSubQuestions(option?.subquestions))
//       })
//     acc.push(cur)
//     return acc
//   }, [])

const REPLACEMENT_TYPES = {
  text: 'QUESTION_TYPE_TEXT_BOX',
  date: 'QUESTION_TYPE_DATE',
  number: 'QUESTION_TYPE_NUMBER',
  radio: 'QUESTION_TYPE_RADIO_REPLACEMENT',
}

const CHOICE_TYPES = ['QUESTION_TYPE_CHECKBOX', 'QUESTION_TYPE_RADIO_LINK']
const MEASURE_RESET_KEYS = ['segments', 'content']

// const measure = (object = {}, start = 0) => {
//   if (object.text && object.text.length) {
//     return { ...object, start, length: object.text.length }
//   }
//   const [result, end] = NESTED_STRUCTURE_KEYS.reduce(
//     (acc, key) => {
//       if (Object.keys(acc[0]).includes(key) && acc[0][key].length) {
//         const calculatedStart = acc[0].id ? 0 : acc[1]
//         const [measuredArray, arrayEnd] = acc[0][key].reduce(
//           (accumulated, object) => {
//             const nestedObject = measure(object, MEASURE_RESET_KEYS.includes(key) ? 0 : accumulated[1])
//             accumulated[0].push(nestedObject)
//             accumulated[1] += nestedObject.length
//             return accumulated
//           },
//           [[], calculatedStart]
//         )
//         acc[0][key] = measuredArray
//         acc[1] += arrayEnd - calculatedStart
//       }
//       return acc
//     },
//     [object, start]
//   )
//   return { ...result, start, length: end - start }
// }

// const measureStructureSegments = (structure = {}, start = 0) =>
//   structure.segments?.reduce(
//     (acc, segment) => {
//       const measuredSegment = measure(segment, acc[1])
//       acc[0].push(measuredSegment)
//       acc[1] += measuredSegment.length
//       return acc
//     },
//     [[], start]
//   )[0] || []

// const measureStructureSections = (structure = {}) =>
//   structure?.sections?.map(section => {
//     const segments = measureStructureSegments(section)
//     const lastSegment = segments.length && segments[segments.length - 1]
//     const length = lastSegment ? lastSegment.start + lastSegment.length : 0
//     return {
//       id: section.id,
//       title: section.title,
//       tag: SEGMENT_TAGS[SEGMENT_TYPES.container],
//       segments,
//       length,
//     }
//   }) || []

// const parseQuestionLocations = question =>
//   (
//     (Object.values(REPLACEMENT_TYPES).includes(question.type)
//       ? question?.locations
//       : question?.options?.reduce(
//           (locations, option) => [
//             ...locations,
//             ...option.locations.map(location => ({
//               ...location,
//               optionId: option.id,
//             })),
//           ],
//           []
//         )) || []
//   ).map(({ id, questionId, locationId, optionId, start, length }) => ({
//     segmentId: id,
//     questionId,
//     locationId,
//     optionId,
//     start,
//     length,
//   }))

const findSegmentById = (segments = [], id = '') => {
  // if (id === CASUS_IDS.rootElement) return { length: segments.reduce((acc, cur) => acc + cur.length, 0) }
  let result = null
  segments.every(segment =>
    segment.id === id
      ? !(result = segment)
      : NESTED_STRUCTURE_KEYS.every(key => !(segment[key] && segment[key].length && (result = findSegmentById(segment[key], id))))
  )
  return result
}

const generateDefaultSectionLayout = () => ({
  id: uuid(),
  title: 'Generic section title',
  index: 0,
  layout: {
    orientation: ORIENTATION.vertical,
    paper: PAPER_NAMES.A4,
    margins: { top: 1, left: 1.252, bottom: 1, right: 1.252 },
  },
})

// const getDeepField = (section = {}, path) =>
//   path.reduce((acc, field, i) => {
//     if (typeof field === 'string') return acc[field] || Object.assign(acc, { [field]: [] })[field]
//     const parent = path
//       .slice(0, Math.max(i - 1, 0))
//       .reduce((s, k) => (typeof k === 'string' ? s[k] : s[k.index]), section)
//     if (acc[field.index]) return acc[field.index]
//     const difference = field.index - acc.length
//     if (difference) {
//       parent.index = difference
//       path[i].index = acc.length
//     }
//     acc.push(
//       Object.entries(field).reduce(
//         (acc, [key, value]) => Object.assign(acc, key === 'index' ? {} : { [key]: value }),
//         {}
//       )
//     )
//     return acc[acc.length - 1]
//   }, section)

// const breakDataStructure = (dataStructure = {}, breakType = 'section', accumulated = [{}], path = []) => {
//   const sectionCount = accumulated.length
//   const currentSection = accumulated[sectionCount - 1]
//   const scraped = {}
//   const keys = ['id', 'type', 'tag', 'customStyle', 'styles', 'break']
//   keys.forEach(k => {
//     if (dataStructure[k]) scraped[k] = dataStructure[k]
//   })
//   if (path.length) Object.assign(path[path.length - 1], scraped)
//   const deepField = getDeepField(currentSection, path)
//   if (dataStructure.break?.type === breakType) {
//     Object.assign(deepField, dataStructure)
//     if (breakType === 'section') {
//       Object.assign(currentSection, {
//         id: dataStructure.break.id,
//         title: dataStructure.break.title,
//         layout: dataStructure.break.layout,
//       })
//       accumulated.push(generateDefaultSectionLayout())
//     } else {
//       Object.assign(currentSection, { id: dataStructure.break.id })
//       accumulated.push({ id: uuid(), index: 0, sectionOffset: currentSection.sectionOffset || 0 })
//     }
//     return accumulated
//   }
//   NESTED_STRUCTURE_KEYS.forEach(key => {
//     if (dataStructure[key] && dataStructure[key].length) {
//       Object.assign(deepField, { [key]: [] })
//       if (key === 'textChunks') dataStructure[key].forEach(tc => deepField[key].push(tc))
//       else
//         dataStructure[key].forEach((segment, i) =>
//           breakDataStructure(segment, breakType, accumulated, [...path, key, { index: i }])
//         )
//     }
//   })
//   return accumulated
// }

const breakSegmentsIntoSections = (segments = []) => {
  return []
}

const dateTimeISOregex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/g

export {
  REPLACEMENT_TYPES,
  CHOICE_TYPES,
  MEASURE_RESET_KEYS,
  NESTED_STRUCTURE_KEYS,
  // KEYSTRINGS,
  // CASUS_IDS,
  // CASUS_CLASSES,
  // extractSubQuestions,
  // measure,
  // measureStructureSegments,
  // measureStructureSections,
  // parseQuestionLocations,
  findSegmentById,
  generateDefaultSectionLayout,
  // breakDataStructure,
  breakSegmentsIntoSections,
  dateTimeISOregex,
}
