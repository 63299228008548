import { TeamMembers } from '___types'

import API from './api'

class Team extends API {
  public constructor() {
    super('teamMembers/') // change to "team" when BE updates
  }

  public getTeam = async () => {
    const res = await this.get(undefined, undefined, 'v1')
    console.log('RES: ', res)
    return res.data.data as TeamMembers
  }
}

export const teamAPI = new Team()

export default teamAPI
