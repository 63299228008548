import {
  ExternalService,
  ExternalServiceField,
  ExternalServiceDisplayEntry,
  ExternalServiceEntry,
  EXTERNAL_SERVICE_ENTRIES_FIELDS,
  IUser,
} from '___types'

import API from './api'
import { auth as firebaseAuth } from 'utils'

class User extends API {
  public constructor() {
    super('users/')
  }

  public getUser = async (publicFlow: boolean = false) => {
    if (publicFlow) return
    const res = await this.get('me', undefined, 'v1')
    // /////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when BE changes field names
    const user = res.data.data.user
    Object.assign(user, { subscriptions: user.subs })
    delete user.subs
    Object.assign(user.privacy, { docxDownload: user.privacy.wordDownload })
    delete user.privacy.wordDownload
    return user as IUser
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // return res.data as IUser
  }

  public getExternalApiFields = async () => {
    const res = await this.get('external/services/fields', undefined, 'v1')
    // /////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // remove parsing when response fixes service structure
    const services = res?.data?.data?.services || []
    const parsedServices = services?.map(
      ({ service: { type, name }, list }: { service: { type: string; name: string }; list: ExternalServiceField }) => ({
        id: type,
        title: name,
        fields: list,
      })
    )
    return parsedServices as ExternalService[]
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // return res.data.data.services as ExternalService[]
  }

  public getExternalApiEntries = async (service: string) => {
    const field = EXTERNAL_SERVICE_ENTRIES_FIELDS[service as keyof typeof EXTERNAL_SERVICE_ENTRIES_FIELDS]
    const res = await this.post(`external/services/${field}`, { serviceType: service }, undefined, 'v1')
    const entries = (res?.data?.data || {})[field] || []
    return entries as ExternalServiceDisplayEntry[]
  }

  public getExternalApiEntry = async <T extends string[]>(service: string, id: string, fields: T) => {
    if (!(service && id)) return
    const field = EXTERNAL_SERVICE_ENTRIES_FIELDS[service as keyof typeof EXTERNAL_SERVICE_ENTRIES_FIELDS]
    const res = await this.post(`external/services/${field}/${id}`, { serviceType: service, fields }, undefined, 'v1')
    return res?.data?.data as ExternalServiceEntry<T>
  }

  public setAuthHeaders = (token: string, expirationTime: string) => {
    localStorage.setItem('casusToken', token)
    localStorage.setItem('casusExpiresIn', expirationTime)
  }

  public removeAuthHeaders = () => {
    localStorage.removeItem('casusToken')
    localStorage.removeItem('casusExpiresIn')
  }

  public logoutUser = async () => await firebaseAuth.signOut().finally(() => this.removeAuthHeaders())
}

export const userAPI = new User()

export default userAPI
