import React, { FunctionComponent } from 'react'

import { useDocument } from '___hooks'
import { Button } from 'components/CasusComponents'
import useModal from 'Modal'
import { MainLayoutRightPaneDocumentRelocationProps, mainLayoutRightPaneDocumentRelocationClasses as classes } from 'Layouts/___MainLayout'
import ModalContent from './Relocation.ModalContent'

export const Relocation: FunctionComponent<MainLayoutRightPaneDocumentRelocationProps> = React.memo(({ id }) => {
  const {
    data: { category } = {},
    // update,
    updating,
  } = useDocument(id)
  const open = useModal({
    header: 'Move document',
    close: false,
    content: (...params) => <ModalContent categoryId={category?.slice(-1)[0]} conclude={params[2]} />,
    onConclude: (_, categoryId) => console.log(categoryId),
    footer: false,
  })

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>
        Relocation
        <Button onClick={() => open()} onClickBlur disabled={updating} loading={updating}>
          Move
        </Button>
      </span>
    </div>
  )
})

Relocation.displayName = 'MainLayout-RightPane-Document-Relocation'

export default Relocation
