import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import { Plus, Template } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import Mine from './Templates.Mine'
import Shared from './Templates.Shared'
import { MainLayoutLeftPaneTemplatesProps, mainLayoutLeftPaneTemplatesClasses as classes } from '../../..'

export const Templates: FunctionComponent<MainLayoutLeftPaneTemplatesProps> = React.memo(() => {
  const history = useHistory()
  return (
    <div className={classes.wrapper}>
      <Button onClick={() => history.push('template/new')} primary>
        <Template />
        New Template
        <Plus />
      </Button>
      <Mine />
      <Shared />
    </div>
  )
})

Templates.displayName = 'MainLayout-LeftPane-Templates'

export default Templates
