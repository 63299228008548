import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENT_STATUSES, DocumentsFilter, IDocument } from '___types'
import { DOCUMENT_LIST_PER_PAGE, documentsAPI } from '___api'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type ApproveDocumentVariables = { id: string; updateCategory?: DocumentsFilter[] }
const approveDocumentMutationFunction = (variables: ApproveDocumentVariables) => documentsAPI.approveDocument(variables.id)

export const useApproveDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const documentApproveMutation = useMutation<IDocument | undefined, unknown, ApproveDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id],
    approveDocumentMutationFunction,
    {
      onMutate: variables => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
        const mutationId = uuid()
        if (currentDocument) {
          const originalDocument = currentDocument.original || currentDocument
          const optimisticDocument = Object.assign({}, originalDocument, { status: DOCUMENT_STATUSES.LOCKED, mutationId, original: originalDocument })
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, optimisticDocument)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], currentDocument.original)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, currentDocument.original)
            )
        }
      },
      onSuccess: (document, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], document)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, document!)
            )
        }
      },
      // onSettled: async (document, error, variables, context) => {
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //   if (variables) {
      //     const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? [])
      //     queryClient.cancelQueries(queryKey) // async
      //     queryClient.invalidateQueries(queryKey) // async
      //     queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   }
      //   // =========================================================================================== //
      // },
    }
  )

  const approveMutationFunction = (
    category?: DocumentsFilter[],
    options?: MutateOptions<IDocument | undefined, unknown, ApproveDocumentVariables, { mutationId: string }>
  ) => documentApproveMutation.mutate({ id: id!, updateCategory: category }, options)
  return { approve: approveMutationFunction, approving: documentApproveMutation.isLoading }
}

export type ApproveDocumentFunctionType = (
  category?: DocumentsFilter[],
  options?: MutateOptions<IDocument | undefined, unknown, ApproveDocumentVariables, { mutationId: string }>
) => void

export default useApproveDocument
