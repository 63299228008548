import React, { forwardRef, Dispatch, SetStateAction, RefCallback, useState, useMemo, useCallback, useEffect } from 'react'

import { Spinner } from 'assets/svgIconComponents'
import Background from './Button.Background'
import Overlay from './Button.Overlay'
import { ButtonProps, classes } from '.'

export const Button = React.memo(
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const [wrapper, setWrapper]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
    // const [button, setButton]: [HTMLButtonElement | undefined, Dispatch<SetStateAction<HTMLButtonElement | undefined>>] = useState()
    const {
      id,
      className: cn,
      disabled,
      dataSet,
      tabbable = true,
      loading,
      title,
      showSpinner = false,
      onClickBlur,
      noOverlaySVG = false,
      noBackgroundSVG = false,
      onClick,
      onFocus,
      children,
    } = props

    const className = useMemo(() => [classes.wrapper, cn].filter(c => c).join(' '), [cn])

    const wrapperRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setWrapper(node), [])
    // const buttonRef: RefCallback<HTMLButtonElement | undefined> = useCallback(node => node && setButton(node), [])

    const emphasis = ['emphasized', 'primary', 'secondary', 'tertiary', 'transparent'].find(prop => props[prop as keyof ButtonProps])
    const baselineDataSet = useMemo(() => ({ emphasis, loading, disabled }), [emphasis, loading, disabled])

    // const borderRadius = useMemo(() => {
    //   if (!wrapper) return 0
    //   const button = wrapper.querySelector(`button.${classes.button}`)
    //   if (!button) return 0
    //   return Number(getComputedStyle(button).getPropertyValue('border-radius').replace('px', '')) || 0
    // }, [wrapper])

    useEffect(() => {
      if (wrapper) {
        Object.keys(wrapper.dataset).forEach(key => delete wrapper.dataset[key])
        Object.entries(Object.assign({}, baselineDataSet, dataSet)).forEach(
          ([key, value]) => value !== undefined && (wrapper.dataset[key] = String(value))
        )
      }
    }, [wrapper, dataSet, baselineDataSet])

    return (
      <div ref={wrapperRef} className={className}>
        <button
          id={id}
          title={title}
          ref={ref}
          className={classes.button}
          type="button"
          disabled={disabled || undefined}
          tabIndex={tabbable && !disabled ? 0 : -1}
          onClick={event => {
            if (disabled) return
            const { currentTarget, clientX, clientY } = event
            if (typeof onClick === 'function') {
              if (onClickBlur) currentTarget.blur()
              onClick(event)
            }
            const backgroundElement = currentTarget.parentNode?.querySelector(`.${classes.background}`)
            if (backgroundElement) {
              const backgroundRect = backgroundElement?.getBoundingClientRect() || { width: 1, height: 1 }
              const relevantField = ['width', 'height'][Number(backgroundRect.width < backgroundRect.height)] as 'width' | 'height'
              const buttonRect = currentTarget.getBoundingClientRect() || { width: 1, height: 1 }
              const scaleRatio = buttonRect[relevantField] / 100
              const relativeX = (clientX - (buttonRect.x + buttonRect.width / 2)) / scaleRatio
              const relativeY = (clientY - (buttonRect.y + buttonRect.height / 2)) / scaleRatio
              backgroundElement?.setAttribute('style', `--click-response-relative-cx: ${relativeX}; --click-response-relative-cy: ${relativeY};`)
              //@ts-ignore
              backgroundElement?.querySelector('animate')?.beginElement()
            }
          }}
          onFocus={typeof onFocus === 'function' ? onFocus : undefined}
        >
          {children}
          {loading && showSpinner ? <Spinner strokeWidth={5} /> : null}
        </button>
        {!noBackgroundSVG ? <Background /> : null}
        {!noOverlaySVG ? <Overlay loading={loading} /> : null}
      </div>
    )
  })
)

Button.displayName = 'Casus-Components-Button'

export default Button
