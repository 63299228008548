import React, { FunctionComponent, useMemo } from 'react'
import useStore, { TemplatesFilterSelector } from '___store'

import {
  MainLayoutRightPaneTemplateRelocationModalContentProps,
  mainLayoutRightPaneTemplateRelocationModalContentClasses as classes,
} from 'Layouts/___MainLayout'
import FolderTree from 'Layouts/___MainLayout/components/LeftPane/LeftPane.components/FolderTree'
import { TEMPLATES_FILTERS } from '___types'
import { Spinner, TemplatePlaceholder } from 'assets/svgIconComponents'
import Info from './Info'
import { useTemplate } from '___hooks'
import { Button } from 'components/CasusComponents'

type UseStoreHookResultType = {
  templatesFilter: TemplatesFilterSelector
}

export const ModalContent: FunctionComponent<MainLayoutRightPaneTemplateRelocationModalContentProps> = React.memo(({ id, cancel, conclude }) => {
  const { previewImageSrcURL: { data: src, isLoading: imageLoading } = {} } = useTemplate(id!)

  const { templatesFilter } = useStore('selectTemplatesFilter') as UseStoreHookResultType

  const preview = useMemo(() => {
    if (imageLoading) return <Spinner />
    if (src) return <img src={src} alt="Template Preview" />
    return <TemplatePlaceholder randomize />
  }, [imageLoading, src])

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.info}>
          {preview}
          <Info id={id} renaming={false} renameCallback={() => {}} />
        </div>
        <FolderTree directory="templates" id={TEMPLATES_FILTERS.MINE} name="My Templates" />
      </div>
      <div className="Modal-footer">
        <Button onClick={cancel}>Cancel</Button>
        <Button onClick={() => conclude(templatesFilter.slice(-1)[0])} primary>
          Move Document
        </Button>
      </div>
    </>
  )
})

ModalContent.displayName = 'MainLayout-RightPane-Template-Publishing-ModalContent'

export default ModalContent
