import { QueryFunctionContext, UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query'

import { DOCUMENTS_FILTERS, IDocument } from '___types'
import { documentsAPI, DOCUMENT_LIST_PER_PAGE } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'
import { AxiosError } from 'axios'

export const getDocumentListQueryFunction = ({ queryKey, pageParam }: QueryFunctionContext) =>
  documentsAPI.getDocumentList(queryKey[1] as string, pageParam as string | null)

export const useFetchDocumentList = (filter: string | null = DOCUMENTS_FILTERS.MINE) => {
  const infiniteQueryPayload = {
    queryKey: [QUERY_KEYS.DOCUMENTS].concat(filter ?? []),
    queryFn: getDocumentListQueryFunction,
    getNextPageParam: (lastPage = []) => {
      if (!lastPage || lastPage.length < DOCUMENT_LIST_PER_PAGE) return
      const lastDocument = lastPage[lastPage.length - 1]
      return lastDocument?.id
    },
    staleTime: filter === DOCUMENTS_FILTERS.MINE ? Infinity : STALE_TIME,
    refetchOnWindowFocus: false,
  } as UseInfiniteQueryOptions<IDocument[], AxiosError>
  if (filter === DOCUMENTS_FILTERS.MINE) Object.assign(infiniteQueryPayload, { cacheTime: Infinity })
  const documentListQuery = useInfiniteQuery(infiniteQueryPayload)
  return documentListQuery
}

export default useFetchDocumentList
