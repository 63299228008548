import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { MutationOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplateFolder } from '___types'
import { templatesAPI } from '___api'
import { QUERY_KEYS } from '___queries'
// import { getTemplateFolderListQueryFunction } from './useFetchTemplateFolderList'

export type CreateTemplateFolderVariables = { name: string; parentFolder?: string | null }
const createTemplateFolderMutationFunction = (variables: CreateTemplateFolderVariables) =>
  templatesAPI.createTemplateFolder(variables.name, variables.parentFolder)

export const useCreateTemplateFolder = () => {
  const queryClient = useQueryClient()
  const generatedId = useMemo(() => uuid(), [])
  const templateFolderCreateMutation = useMutation<ITemplateFolder, unknown, CreateTemplateFolderVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, generatedId],
    createTemplateFolderMutationFunction,
    {
      onMutate: variables => {
        queryClient.setQueryData(
          [QUERY_KEYS.TEMPLATE_FOLDERS].concat(variables.parentFolder === undefined ? TEMPLATES_FILTERS.MINE : variables.parentFolder ?? []),
          (data: ITemplateFolder[] | undefined) =>
            (data ?? []).slice().concat({
              id: generatedId,
              name: variables.name,
              parentFolderId: variables.parentFolder,
              optimistic: true,
            } as unknown as ITemplateFolder)
        )
        return { mutationId: generatedId }
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData(
          [QUERY_KEYS.TEMPLATE_FOLDERS].concat(variables.parentFolder === undefined ? TEMPLATES_FILTERS.MINE : variables.parentFolder ?? []),
          (data: ITemplateFolder[] | undefined) => (data ?? []).filter(({ id }) => id !== context?.mutationId)
        )
      },
      onSuccess: (templateFolder, variables, context) => {
        queryClient.setQueryData(
          [QUERY_KEYS.TEMPLATE_FOLDERS].concat(variables.parentFolder === undefined ? TEMPLATES_FILTERS.MINE : variables.parentFolder ?? []),
          (data: ITemplateFolder[] | undefined) =>
            (data ?? []).map(folder => {
              if (folder.id === context?.mutationId) return templateFolder
              return folder
            })
        )
      },
      // onSettled: async (templateFolder, error, variables, context) => {
      //   const queryKey = [QUERY_KEYS.TEMPLATE_FOLDERS].concat(variables.parentFolder === undefined ? TEMPLATES_FILTERS.MINE : variables.parentFolder ?? [])
      //   queryClient.cancelQueries(queryKey) // async
      //   queryClient.invalidateQueries(queryKey) // async
      //   queryClient.fetchQuery({ queryKey, queryFn: getTemplateFolderListQueryFunction }) // async
      // },
    }
  )

  const createFolderMutationFunction = (
    name: string,
    parentFolder?: string,
    // category: TemplatesFilter | TemplatesFilter[] = TEMPLATES_FILTERS.MINE,
    options?: MutationOptions<ITemplateFolder, unknown, CreateTemplateFolderVariables, { mutationId: string }>
  ) => templateFolderCreateMutation.mutate({ name, parentFolder }, options)
  return { createFolder: createFolderMutationFunction, creatingFolder: templateFolderCreateMutation.isLoading }
}

export type CreateTemplateFolderFunctionType = (
  name: string,
  parentFolder?: string,
  // category?: TemplatesFilter | TemplatesFilter[],
  options?: MutationOptions<ITemplateFolder, unknown, CreateTemplateFolderVariables, { mutationId: string }>
) => void

export default useCreateTemplateFolder
