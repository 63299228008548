import React, { FunctionComponent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { WizardQuestionOrderSelector } from '___store'

import { Caret, Overview } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import ListQuestion from './QuestionOverview.ListQuestion'
import { wizardLayoutLeftPaneDocumentQuestionnaireQuestionOverviewClasses as classes } from 'Layouts/WizardLayout'

type UseStoreHookResultType = { wizardQuestionOrder: WizardQuestionOrderSelector }

export const QuestionOverview: FunctionComponent = React.memo(() => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.questionnaire' })
  const [collapsed, setCollapsed] = useState(true)

  const { wizardQuestionOrder } = useStore('selectWizardQuestionOrder') as UseStoreHookResultType

  const render = useMemo(
    () =>
      wizardQuestionOrder?.map(string => (
        <ListQuestion
          key={`WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview-ListQuestion-${string}`}
          id={string}
          collapseCallback={() => setCollapsed(true)}
        />
      )),
    [wizardQuestionOrder]
  )

  return (
    <div className={classes.wrapper} data-collapsed={collapsed || undefined}>
      <div className={classes.expandButton}>
        <Button onClick={() => setCollapsed(prev => !prev)} onClickBlur>
          {collapsed ? <Overview /> : <Caret />}
          {collapsed ? <span>{translate('all-questions')}</span> : null}
        </Button>
      </div>
      <span>Question Overview</span>
      <div className={classes.questionListDiv}>{render}</div>
    </div>
  )
})

QuestionOverview.displayName = 'WizardLayout-LeftPane-Document-Questionnaire-QuestionOverview'

export default QuestionOverview
