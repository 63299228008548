import { SignatureSecurityLevel } from '___types'

import Bin from './Bin'
import Bulb from './Bulb'
import Burger from './Burger'
import CasusLogo from './CasusLogo'
import CheckBox from './CheckBox'
import Clock from './Clock'
import Document from './Document'
import DocumentPlaceholder from './DocumentPlaceholder'
import Download from './Download'
import EditField from './EditField'
import Eye from './Eye'
import Folder from './Folder'
import Premium from './Premium'
import Public from './Public'
import Copy from './Copy'
import RadioButton from './RadioButton'
import Save from './Save'
import Security from './Security'
import Spinner from './Spinner'
import TemplatePlaceholder from './TemplatePlaceholder'

export {
  Bin,
  Bulb,
  Burger,
  CasusLogo,
  CheckBox,
  Clock,
  Document,
  DocumentPlaceholder,
  Download,
  EditField,
  Eye,
  Folder,
  Premium,
  Public,
  Copy,
  RadioButton,
  Save,
  Security,
  Spinner,
  TemplatePlaceholder,
}

export type BinProps = { full?: boolean }
export type BulbProps = { active?: boolean }
export type BurgerProps = { lines?: number }
export type CasusLogoProps = { animate?: boolean; strokeWidth?: string | number }
export type CheckBoxProps = { active?: boolean; strokeWidth?: string | number; rx?: string | number }
export type ClockProps = { h: number; m: number }
export type DocumentProps = { function?: 'create' | 'update' | 'edit' | 'duplicate' | 'remove' }
export type DocumentPlaceholderProps = { randomize?: boolean }
export type DownloadProps = { extension?: string }
export type EditFieldProps = { type?: 'text' | 'number' | 'currency' }
export type EyeProps = { active?: boolean }
export type FolderProps = { function?: 'add' | 'remove' }
export type PremiumProps = { available?: boolean }
export type PublicProps = {}
export type CopyProps = { type: 'plain' | 'link' }
export type RadioButtonProps = { active?: boolean; strokeWidth?: string | number }
export type SaveProps = { strokeWidth?: string | number }
export type SecurityProps = { level?: SignatureSecurityLevel }
export type SpinnerProps = { animate?: boolean; strokeWidth?: string | number }
export type TemplatePlaceholderProps = { randomize?: boolean }

export const className = 'SVG-Icon'

export const VIEWPORT_SIZE = 100

export const polarToRectangularOffset = (angle: number, length: number = 0, xOffset: number = 0, yOffset: number = 0): [number, number] => [
  xOffset + Math.sin(angle) * length,
  yOffset - Math.cos(angle) * length,
]
export const angleToDegrees = (angle: number): number => (angle / 180) * Math.PI

export const drawStarPath = (
  centerX: number = 50,
  centerY: number = 50,
  points: number = 8,
  tipWidth: number = 0,
  baseWidth: number = 0,
  tipLength: number = 30,
  baseLength: number = 10
): string => {
  const spikeAngle = (2 * Math.PI) / points
  const path = new Array(points).fill(null).reduce((acc: string, _, i) => {
    const tipStartAngle = spikeAngle * i - angleToDegrees(tipWidth / 2)
    const tipEndAngle = spikeAngle * i + angleToDegrees(tipWidth / 2)
    const baseStartAngle = spikeAngle * (i + 0.5) - angleToDegrees(baseWidth / 2)
    const baseEndAngle = spikeAngle * (i + 0.5) + angleToDegrees(baseWidth / 2)
    const [tipStartX, tipStartY] = polarToRectangularOffset(tipStartAngle, tipLength, centerX, centerY)
    const [tipEndX, tipEndY] = polarToRectangularOffset(tipEndAngle, tipLength, centerX, centerY)
    const [baseStartX, baseStartY] = polarToRectangularOffset(baseStartAngle, baseLength, centerX, centerY)
    const [baseEndX, baseEndY] = polarToRectangularOffset(baseEndAngle, baseLength, centerX, centerY)
    const start = `L ${tipStartX} ${tipStartY}`
    const tipArc = tipWidth ? `A ${tipLength} ${tipLength} 0 ${Number(tipWidth > 180)} 1 ${tipEndX} ${tipEndY}` : ''
    const toBase = `L ${baseStartX} ${baseStartY}`
    const baseArc = baseWidth ? `A ${baseLength} ${baseLength} 0 ${Number(baseWidth > 180)} 1 ${baseEndX} ${baseEndY}` : ''
    return acc + start + tipArc + toBase + baseArc
  }, '')
  return 'M' + path.slice(1) + ' Z'
}
