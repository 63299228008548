import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplate, PartialTemplate } from '___types'
import { templatesAPI } from '___api'
import { TEMPLATE_LIST_PER_PAGE } from '___api/api.templates'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type UpdateTemplateVariables = PartialTemplate & { id: string; publicFlow?: boolean; updateCategory?: string }
const updateTemplateMutationFunction = (variables: UpdateTemplateVariables) => {
  console.log('VARS: ', JSON.parse(JSON.stringify(variables)))
  const payload = Object.assign({}, variables)
  delete payload.publicFlow
  delete payload.updateCategory
  return templatesAPI.updateTemplate(payload, variables.publicFlow)
}

export const useUpdateTemplate = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const templateUpdateMutation = useMutation<ITemplate | undefined, unknown, UpdateTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, id],
    updateTemplateMutationFunction,
    {
      onMutate: variables => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        const mutationId = uuid()
        if (currentTemplate) {
          const originalTemplate = currentTemplate.original || currentTemplate
          const optimisticTemplate = Object.assign({}, originalTemplate, variables, { mutationId, original: originalTemplate })
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, optimisticTemplate)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, currentTemplate.original)
            )
        }
      },
      onSuccess: (template, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, template!)
            )
        }
      },
    }
  )

  const updateMutationFunction = (
    template: PartialTemplate,
    category: string = TEMPLATES_FILTERS.MINE,
    options?: MutateOptions<ITemplate | undefined, unknown, UpdateTemplateVariables, { mutationId: string }>
  ) => templateUpdateMutation.mutate(Object.assign(template, { id: id!, publicFlow, updateCategory: category }), options)
  return { update: updateMutationFunction, updating: templateUpdateMutation.isLoading }
}

export type UpdateTemplateFunctionType = (
  template: PartialTemplate,
  category?: string,
  options?: MutateOptions<ITemplate | undefined, unknown, UpdateTemplateVariables, { mutationId: string }>
) => void

export default useUpdateTemplate
