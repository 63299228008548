import React, { FunctionComponent, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useStore from '___store'

import { DOCUMENT_DIRECTORY, DOCUMENT_FLOW_MODES } from '___types'
import { CasusLogo, Plus, Template } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { HeaderProps } from '.'
import Navigation from './Header.Navigation'
import { headerClasses as classes } from '../../'

//
import * as T from 'constants/user-types'
import { useUser } from 'queries/user/useUser'
import { useMasterData } from 'queries/app/useMasterdata'
import Profile from './Header.Profile'

const useData = () => {
  const masterdata = useMasterData()
  const user = useUser()

  const teamClientAllowed = Boolean(masterdata?.client.team_members_enabled) && user?.type === T.USER_TYPE_CLIENT_ADMIN
  const teamLawyerAllowed = Boolean(masterdata?.lawyer.team_members_enabled) && user?.type === T.USER_TYPE_LAWYER_ADMIN

  return {
    teamPageDisabled: !Boolean(teamClientAllowed || teamLawyerAllowed),
    templatePageDisabled: Boolean(user?.type === 'USER_TYPE_CLIENT_EMPLOYEE'),
  }
}

export const Header: FunctionComponent<HeaderProps> = React.memo(() => {
  const { teamPageDisabled, templatePageDisabled } = useData()

  const history = useHistory()
  const location = useLocation()
  const pathname = useMemo(() => {
    const path = location.pathname.split('/')[1]
    return path.charAt(0).toUpperCase() + path.slice(1)
  }, [location])
  const { initializeWizard } = useStore('initializeWizard')

  const user = useUser()

  return (
    <div className={classes.wrapper}>
      <Navigation teamDisabled={teamPageDisabled} templateDisabled={templatePageDisabled} />
      <div onClick={() => history.push('/')} className={classes.logo}>
        <CasusLogo />
      </div>
      &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
      {pathname === '' ? 'Dashboard' : `${pathname}`}
      <Button
        className={classes.addDocumentButton}
        emphasized
        onClick={() => {
          if (user?.version === 'v1' || user?.version === 'v2') {
            history.push(`/document/new`)
          } else if (user?.version === 'v3') {
            initializeWizard({ mode: DOCUMENT_FLOW_MODES.TEMPLATE_SELECT })
            history.push(`/${DOCUMENT_DIRECTORY}`)
          }
        }}
      >
        <Plus />
      </Button>
      {!templatePageDisabled && (
        <Button className={classes.documentsButton} secondary onClick={() => history.push('/template/new')}>
          <Template />
        </Button>
      )}
      {/* <Dropdown className={classes.signaturesDropdown} secondary replaceCaret={<Signature />}></Dropdown> */}
      {/* <Dropdown className={classes.signaturesDropdown} secondary replaceCaret={<Signature />}>
        pera
        <div />
        <DropdownOption id="pera" label="mika" />
        <DropdownOption id="zika" label="franjo" />
        <DropdownOption id="omg" label="wtf" />
      </Dropdown> */}
      <Profile />
    </div>
  )
})

Header.displayName = 'Header'

export default Header
