import React, { FunctionComponent, useMemo } from 'react'
import { DateTime } from 'luxon'

import { Plus, DocumentPlaceholder, Public } from 'assets/svgIconComponents'
import { ThumbnailProps, thumbnailClasses as classes } from '.'

export const DocumentThumbnail: FunctionComponent<ThumbnailProps> = React.memo(
  ({
    id,
    type,
    selected,
    onClick,
    name,
    edited,
    isPublic,
    // sharedWith,
    beingDuplicated,
    beingDeleted,
  }) => {
    const documentPreviewImage = useMemo(() => {
      if (!id) return <Plus />
      return <DocumentPlaceholder randomize />
    }, [id])

    return (
      <button
        id={`Casus-Components-DocumentThumbnail-${id}`}
        className={classes.wrapper}
        type="button"
        onClick={event => typeof onClick === 'function' && !(beingDuplicated || beingDeleted) && onClick(event, id)}
        data-new={!id ? '' : undefined}
        data-selected={selected ? '' : undefined}
        data-being-duplicated={beingDuplicated ? '' : undefined}
        data-being-deleted={beingDeleted ? '' : undefined}
      >
        <div className={classes.content.wrapper}>
          {id && isPublic ? <Public /> : null}
          {documentPreviewImage}
          <div className={classes.content.info}>
            <span className={classes.content.name}>{id ? name : 'Generate a new document'}</span>
            <span className={classes.content.time}>{DateTime.fromMillis(edited?._seconds! * 1000).toRelative()}</span>
          </div>
        </div>
      </button>
    )
  }
)

DocumentThumbnail.displayName = 'Casus-Components-DocumentThumbnail'

export default DocumentThumbnail
