import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENT_STATUSES, DOCUMENTS_FILTERS, IDocument, SignatureSecurityLevel, Signee } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type PublishDocumentVariables = {
  id: string
  notifyEmail: string
  includeEmail: boolean
  previewAvailable: boolean
  signatureAvailable: boolean
  signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string }
  expires: boolean
  expirationTime?: string | null
  singleUse: boolean
  split?: string | null
  updateCategory?: string
}

const publishDocumentMutationFunction = (variables: PublishDocumentVariables) => {
  const payload = Object.assign({}, variables)
  delete payload.updateCategory
  //@ts-ignore
  delete payload.id
  return documentsAPI.publishDocument(variables.id, payload)
}

export const usePublishDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const documentPublishMutation = useMutation<IDocument, unknown, PublishDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id],
    publishDocumentMutationFunction,
    {
      onMutate: variables => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
        const mutationId = uuid()
        if (currentDocument) {
          const originalDocument = currentDocument.original || currentDocument
          const optimisticDocument = Object.assign({}, originalDocument, { status: DOCUMENT_STATUSES.LOCKED, mutationId, original: originalDocument })
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, optimisticDocument)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], currentDocument.original)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, currentDocument.original)
            )
        }
      },
      onSuccess: (document, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string; original: IDocument }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], document)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, document)
            )
        }
      },
      // onSettled: async (document, error, variables, context) => {
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //   if (variables) {
      //     const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? [])
      //     queryClient.cancelQueries(queryKey) // async
      //     queryClient.invalidateQueries(queryKey) // async
      //     queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   }
      //   // =========================================================================================== //
      // },
    }
  )

  const publishMutationFunction = (
    notifyEmail: string = '',
    includeEmail: boolean = false,
    previewAvailable: boolean = false,
    signatureAvailable: boolean = false,
    signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string },
    expires: boolean = false,
    expirationTime?: string | null,
    singleUse: boolean = false,
    split: string | null = null,
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutateOptions<IDocument, unknown, PublishDocumentVariables, { mutationId: string }>
  ) =>
    documentPublishMutation.mutate(
      {
        id: id!,
        notifyEmail,
        includeEmail,
        previewAvailable,
        signatureAvailable,
        signatureConfig,
        expires,
        expirationTime,
        singleUse,
        split,
        updateCategory: category,
      },
      options
    )
  return { publish: publishMutationFunction, publishing: documentPublishMutation.isLoading }
}

export type PublishDocumentFunctionType = (
  notifyEmail?: string,
  includeEmail?: boolean,
  previewAvailable?: boolean,
  signatureAvailable?: boolean,
  signatureConfig?: { security: SignatureSecurityLevel; signees: Signee[]; message?: string },
  expires?: boolean,
  expirationTime?: string | null,
  singleUse?: boolean,
  split?: string | null,
  category?: string,
  options?: MutateOptions<IDocument, unknown, PublishDocumentVariables, { mutationId: string }>
) => void

export default usePublishDocument
