import React, { FunctionComponent } from 'react'

// import { useFetchDocumentFolderList } from '___queries'
import { Button } from 'components/CasusComponents'
import {
  MainLayoutRightPaneDocumentRelocationModalContentProps,
  mainLayoutRightPaneDocumentRelocationModalContentClasses as classes,
} from 'Layouts/___MainLayout'

export const ModalContent: FunctionComponent<MainLayoutRightPaneDocumentRelocationModalContentProps> = React.memo(({ categoryId, conclude }) => {
  // const { data } = useFetchDocumentFolderList(categoryId)
  return (
    <div className={classes.wrapper}>
      {categoryId}
      <Button onClick={() => conclude('categoryId')}>CLOSE!</Button>
    </div>
  )
})

ModalContent.displayName = 'MainLayout-RightPane-Document-Publishing-ModalContent'

export default ModalContent
