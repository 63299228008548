import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplate } from '___types'
import { templatesAPI } from '___api'
import { TEMPLATE_LIST_PER_PAGE } from '___api/api.templates'
import { QUERY_KEYS, infiniteQueryFilter, infiniteQueryReplace } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type RemoveTemplateVariables = { id: string; updateCategory?: string }
const removeTemplateMutationFunction = (variables: RemoveTemplateVariables) => templatesAPI.removeTemplate(variables.id)

export const useRemoveTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const templateRemoveMutation = useMutation<void, unknown, RemoveTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, id],
    removeTemplateMutationFunction,
    {
      onMutate: variables => {
        const originalTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string }
        const mutationId = uuid()
        if (originalTemplate) {
          const optimisticTemplate = Object.assign({}, originalTemplate, { mutationId, beingDeleted: true })
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, ({ id: templateId }) => templateId === id, optimisticTemplate)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          const originalTemplate = Object.assign({}, currentTemplate)
          delete originalTemplate.beingDeleted
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], originalTemplate)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, ({ id: templateId }) => templateId === id, originalTemplate)
            )
        }
      },
      onSuccess: (template, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.removeQueries({ queryKey: [QUERY_KEYS.TEMPLATE, id] })
          queryClient.resetQueries({ queryKey: [QUERY_KEYS.TEMPLATE, id] })
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryFilter(data!, TEMPLATE_LIST_PER_PAGE, ({ id: templateId }) => templateId !== id)
            )
        }
      },
      // onSettled: async (template, error, variables, context) => {
      //   // =========================================================================================== //
      //   // ==================================== REFETCH TEMPLATES ==================================== //
      //   // =========================================================================================== //
      //   if (variables) {
      //     const queryKey = [QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? [])
      //     queryClient.cancelQueries(queryKey) // async
      //     queryClient.invalidateQueries(queryKey) // async
      //     queryClient.fetchInfiniteQuery({ queryKey, queryFn: getTemplateListQueryFunction }) // async
      //   }
      //   // =========================================================================================== //
      // },
    }
  )

  const removeMutationFunction = (
    category: string = TEMPLATES_FILTERS.MINE,
    options?: MutateOptions<void, unknown, RemoveTemplateVariables, { mutationId: string }>
  ) => templateRemoveMutation.mutate({ id: id!, updateCategory: category }, options)
  return { remove: removeMutationFunction, removing: templateRemoveMutation.isLoading }
}

export type RemoveTemplateFunctionType = (
  category?: string,
  options?: MutateOptions<void, unknown, RemoveTemplateVariables, { mutationId: string }>
) => void

export default useRemoveTemplate
