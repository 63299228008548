import React, { FunctionComponent } from 'react'

import { TEMPLATES_FILTERS } from '___types'
import FolderTree from './FolderTree'
import { MainLayoutLeftPaneTemplatesMineProps, mainLayoutLeftPaneTemplatesMineClasses as classes } from '../../..'

export const Mine: FunctionComponent<MainLayoutLeftPaneTemplatesMineProps> = React.memo(() => (
  <FolderTree className={classes.wrapper} directory="templates" id={TEMPLATES_FILTERS.MINE} name="My templates" />
))

Mine.displayName = 'MainLayout-LeftPane-Templates-Mine'

export default Mine
