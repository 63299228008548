const SEGMENT_TYPES = {
  container: 'container',
  mark: 'mark',
  textarea: 'textarea',
  paragraph: 'paragraph',
  textChunk: 'chunk',
  table: 'table',
  tableHeader: 'header',
  tableBody: 'body',
  tableFooter: 'footer',
  tableRow: 'row',
  tableData: 'cell',
  image: 'image',
}

const SEGMENT_TAGS = {
  [SEGMENT_TYPES.container]: 'div',
  [SEGMENT_TYPES.mark]: 'mark',
  [SEGMENT_TYPES.textarea]: 'textarea',
  [SEGMENT_TYPES.paragraph]: 'pre',
  [SEGMENT_TYPES.textChunk]: 'span',
  [SEGMENT_TYPES.table]: 'table',
  [SEGMENT_TYPES.tableHeader]: 'thead',
  [SEGMENT_TYPES.tableBody]: 'tbody',
  [SEGMENT_TYPES.tableFooter]: 'tfoot',
  [SEGMENT_TYPES.tableRow]: 'tr',
  [SEGMENT_TYPES.tableData]: 'td',
  [SEGMENT_TYPES.image]: 'img',
}
const CASUS_KEYSTRINGS = {
  keep: '_casus_keep',
  replace: '_casus_replace',
  highlight: '_casus_highlight',
  input: '_casus_input',
  numbering: '_casus_numbering',
  numberingSystemKey: '_casus_numbering_system_key',
  numberingLevel: '_casus_numbering_level',
}

const CASUS_IDS = {
  dataStructure: '_casus_data_structure',
}

const CASUS_CLASSES = {
  section: '_casus_section_root',
  pageContentRoot: '_casus_page_content_root',
  counterResetter: '_casus_counter_resetter',
  segmentsMarkerDiv: '_casus_segments_marker_div',
  segmentsMarkerContent: '_casus_segments_marker_content',
  segmentsMarkerHeader: '_casus_segments_marker_header',
  textMarker: '_casus_text_marker',
  textMarkerControls: '_casus_text_marker_controls',
  segmentDiv: '_casus_segment_div',
  configureDiv: '_casus_configure_div',
  configureButton: '_casus_configure_button',
  labelInput: '_casus_label_input',
  textarea: '_casus_textarea',
  textareaOverlay: '_casus_textarea_overlay',
  paragraphSegment: '_casus_paragraph_segment',
  paragraphControls: '_casus_paragraph_controls',
  tableSegment: '_casus_table_segment',
  tableHeader: '_casus_table_header',
  tableBody: '_casus_table_body',
  tableFooter: '_casus_table_footer',
  tableRow: '_casus_table_row',
  tableCell: '_casus_table_cell',
  textChunk: '_casus_text_chunk_span',
}

const CASUS_REGEXES = {
  customTextSplit: /(?=(?<!\\){{)|(?<=(?<!\\)}})/gm,
  markerReplaceMatchAll: new RegExp(`^{{${CASUS_KEYSTRINGS.replace}:(?<value>(.|\\s|\\S)+)}}$`, 'gm'),
}

const PAPER_NAMES = {
  LETTER: 'letter',
  LEGAL: 'legal',
  A3: 'a3',
  A4: 'a4',
  A5: 'a5',
  B4: 'b4',
  B5: 'b5',
  EURO_FANFOLD: 'euro-fanfold',
  EXECUTIVE1: 'executive1',
  EXECUTIVE2: 'executive2',
  EXECUTIVE3: 'executive3',
  FOLIO: 'folio',
  LEDGER: 'ledger',
  TABLOID: 'tabloid',
  QUATRO: 'quatro',
  SHORT: 'short',
  STATEMENT: 'statement',
  STATIONERY: 'stationery',
  NARROW: 'narrow',
  WIDE: 'wide',
  '1403W': '1403w',
  '1403WS': '1403ws',
  '3800N': '3800n',
  '3800NS': '3800ns',
  '3800W': '3800w',
  '3800WS': '3800ws',
  CUT_SHEET: 'cut-sheet',
}

const MMPI = 25.4
const PXPI = 96

const PAGE_SIZES_IMPERIAL = {
  [PAPER_NAMES.A5]: { width: 148 / MMPI, height: 210 / MMPI },
  [PAPER_NAMES.B5]: { width: 182 / MMPI, height: 257 / MMPI },
  [PAPER_NAMES.A4]: { width: 210 / MMPI, height: 297 / MMPI },
  [PAPER_NAMES.QUATRO]: { width: 215 / MMPI, height: 275 / MMPI },
  [PAPER_NAMES.EURO_FANFOLD]: { width: 250 / MMPI, height: 340 / MMPI },
  [PAPER_NAMES.B4]: { width: 257 / MMPI, height: 364 / MMPI },
  [PAPER_NAMES.A3]: { width: 297 / MMPI, height: 420 / MMPI },
  [PAPER_NAMES.STATEMENT]: { width: 5.5, height: 8.5 },
  [PAPER_NAMES.EXECUTIVE1]: { width: 7, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE2]: { width: 7.25, height: 10.5 },
  [PAPER_NAMES.EXECUTIVE3]: { width: 7.5, height: 10.5 },
  [PAPER_NAMES['3800NS']]: { width: 7.5, height: 11.5 },
  [PAPER_NAMES['3800WS']]: { width: 7.5, height: 13.5 },
  [PAPER_NAMES.STATIONERY]: { width: 8, height: 10 },
  [PAPER_NAMES['3800N']]: { width: 8.5, height: 10 },
  [PAPER_NAMES.SHORT]: { width: 8.5, height: 10.5 },
  [PAPER_NAMES.LETTER]: { width: 8.5, height: 11 },
  [PAPER_NAMES.FOLIO]: { width: 8.5, height: 13 },
  [PAPER_NAMES['1403WS']]: { width: 8.5, height: 13.5 },
  [PAPER_NAMES.LEGAL]: { width: 8.5, height: 14 },
  [PAPER_NAMES['3800W']]: { width: 10, height: 13.5 },
  [PAPER_NAMES.NARROW]: { width: 10, height: 14 },
  [PAPER_NAMES['1403W']]: { width: 11, height: 13.5 },
  [PAPER_NAMES.WIDE]: { width: 11, height: 14 },
  [PAPER_NAMES.TABLOID]: { width: 11, height: 17 },
  [PAPER_NAMES.CUT_SHEET]: { width: 17, height: 22 },
}

const PAGE_SIZES = Object.entries(PAGE_SIZES_IMPERIAL).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: { width: value.width * PXPI, height: value.height * PXPI },
  }),
  {}
)

const ORIENTATION = {
  horizontal: 'landscape',
  vertical: 'portrait',
}

const DATA_STRUCTURE = {
  id: CASUS_IDS.dataStructure,
  type: SEGMENT_TYPES.container,
  headers: [{ id: 'rId1', type: 'header', segments: [] }],
  footers: [{ id: 'rId2', type: 'footer', segments: [] }],
  segments: [
    {
      id: '10001',
      type: SEGMENT_TYPES.paragraph,
      tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
      customStyle: 'paragraph-class',
      styles: ['paragraph-style-1', 'paragraph-style-2'],
      break: {
        type: 'page',
        id: 'page-1',
      },
      textChunks: [
        {
          type: SEGMENT_TYPES.textChunk,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.textChunk],
          customStyle: 'chunk-class',
          styles: ['chunk-style-1', 'chunk-style-2'],
          text: 'sample text',
        },
      ],
    },
    {
      id: '10002',
      type: SEGMENT_TYPES.table,
      tag: SEGMENT_TAGS[SEGMENT_TYPES.table],
      customStyle: 'table-class',
      styles: ['table-style-1', 'table-style-2'],
      break: {
        type: 'section',
        id: 'section-1',
        layout: {
          page: PAPER_NAMES.A4,
          orientation: ORIENTATION.vertical,
        },
      },
      header: [
        {
          id: '100021',
          type: SEGMENT_TYPES.tableRow,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
          customStyle: 'table-header-class',
          styles: ['table-header-style-1', 'table-header-style-2'],
          cells: [
            {
              id: '1000211',
              type: SEGMENT_TYPES.tableData,
              tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
              customStyle: 'table-cell-class',
              styles: ['table-cell-style-1', 'table-cell-style-2'],
              content: [],
            },
          ],
        },
      ],
      body: [
        {
          id: '100022',
          type: SEGMENT_TYPES.tableRow,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
          customStyle: 'table-body-class',
          styles: ['table-body-style-1', 'table-body-style-2'],
          cells: [
            {
              id: '1000221',
              type: SEGMENT_TYPES.tableData,
              tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
              customStyle: 'table-cell-class',
              styles: ['table-cell-style-1', 'table-cell-style-2'],
              content: [
                {
                  id: '10001',
                  type: SEGMENT_TYPES.paragraph,
                  tag: SEGMENT_TAGS[SEGMENT_TYPES.paragraph],
                  customStyle: 'paragraph-class',
                  styles: ['paragraph-style-1', 'paragraph-style-2'],
                  break: {
                    type: 'page',
                    id: 'page-1',
                  },
                  textChunks: [
                    {
                      type: SEGMENT_TYPES.textChunk,
                      tag: SEGMENT_TAGS[SEGMENT_TYPES.textChunk],
                      customStyle: 'chunk-class',
                      styles: ['chunk-style-1', 'chunk-style-2'],
                      text: 'sample text',
                    },
                  ],
                },
                {
                  id: '10002',
                  type: SEGMENT_TYPES.table,
                  tag: SEGMENT_TAGS[SEGMENT_TYPES.table],
                  customStyle: 'table-class',
                  styles: ['table-style-1', 'table-style-2'],
                  break: {
                    type: 'section',
                    id: 'section-1',
                    layout: {
                      page: PAPER_NAMES.A4,
                      orientation: ORIENTATION.vertical,
                    },
                  },
                  header: [
                    {
                      id: '100021',
                      type: SEGMENT_TYPES.tableRow,
                      tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
                      customStyle: 'table-header-class',
                      styles: ['table-header-style-1', 'table-header-style-2'],
                      cells: [
                        {
                          id: '1000211',
                          type: SEGMENT_TYPES.tableData,
                          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
                          customStyle: 'table-cell-class',
                          styles: ['table-cell-style-1', 'table-cell-style-2'],
                          content: [],
                        },
                      ],
                    },
                  ],
                  body: [
                    {
                      id: '100022',
                      type: SEGMENT_TYPES.tableRow,
                      tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
                      customStyle: 'table-body-class',
                      styles: ['table-body-style-1', 'table-body-style-2'],
                      cells: [
                        {
                          id: '1000221',
                          type: SEGMENT_TYPES.tableData,
                          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
                          customStyle: 'table-cell-class',
                          styles: ['table-cell-style-1', 'table-cell-style-2'],
                          content: [],
                        },
                      ],
                    },
                  ],
                  footer: [
                    {
                      id: '100023',
                      type: SEGMENT_TYPES.tableRow,
                      tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
                      customStyle: 'table-footer-class',
                      styles: ['table-footer-style-1', 'table-footer-style-2'],
                      cells: [
                        {
                          id: '1000231',
                          type: SEGMENT_TYPES.tableData,
                          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
                          customStyle: 'table-cell-class',
                          styles: ['table-cell-style-1', 'table-cell-style-2'],
                          content: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      footer: [
        {
          id: '100023',
          type: SEGMENT_TYPES.tableRow,
          tag: SEGMENT_TAGS[SEGMENT_TYPES.tableRow],
          customStyle: 'table-footer-class',
          styles: ['table-footer-style-1', 'table-footer-style-2'],
          cells: [
            {
              id: '1000231',
              type: SEGMENT_TYPES.tableData,
              tag: SEGMENT_TAGS[SEGMENT_TYPES.tableData],
              customStyle: 'table-cell-class',
              styles: ['table-cell-style-1', 'table-cell-style-2'],
              content: [],
            },
          ],
        },
      ],
    },
  ],
}

const NUMBERING_SYSTEM = {
  systemName: [
    {
      prefix: '',
      type: 'decimal',
      suffix: '.',
      combined: false,
      combineString: '',
      styleName: 'style-name-1',
    },
    {
      prefix: '',
      type: 'lower-latin',
      suffix: ')',
      combined: true,
      combineString: '',
      styleName: 'style-name-2',
    },
  ],
}

const KEY_BLACKLIST = ['styles']

const extractNestedKeys = (structure, set = new Set()) =>
  Object.entries(structure).forEach(
    ([key, value]) => Array.isArray(value) && !KEY_BLACKLIST.includes(key) && set.add(key) && value.forEach(obj => extractNestedKeys(obj, set))
  ) || Array.from(set)

const NESTED_STRUCTURE_KEYS = extractNestedKeys(DATA_STRUCTURE)

const extractNestedTypes = (structure, types = {}) => {
  const reduceTypes = array => array.reduce((acc, { type }) => (type ? acc.add(type) : acc), new Set())
  const addTypes = key => Object.assign(types, { [key]: new Set([...(types[key] || []), ...reduceTypes(structure[key])]) })
  const keyCallback = key => Array.isArray(structure[key]) && addTypes(key) && structure[key].forEach(obj => extractNestedTypes(obj, types))
  NESTED_STRUCTURE_KEYS.forEach(keyCallback)
  return types
}

const NESTED_STRUCTURE_TYPES = Object.entries(extractNestedTypes(DATA_STRUCTURE)).reduce(
  (acc, [key, set]) => Object.assign(acc, { [key]: [...set] }),
  {}
)

const findNestedSegment = (dataStructure = {}, segmentType) => {
  let res = null
  NESTED_STRUCTURE_KEYS.every(
    key => !dataStructure[key]?.find(segment => (segment.type === segmentType ? (res = segment) : (res = findNestedSegment(segment, segmentType))))
  )
  return res
}

const SEGMENT_KEYS = Object.values(SEGMENT_TYPES).reduce(
  (acc, cur) =>
    Object.assign(acc, {
      [cur]: Object.keys(findNestedSegment(DATA_STRUCTURE, cur) || {}).filter(k => !acc.all.includes(k)),
    }),
  {
    all: ['type', 'tag', 'customStyle', 'styles'],
  }
)

const RETAIN_KEYS = ['v2Styles', 'fidChar', 'instrText', 'drawing', 'break']

const STOP_DEPTH_NODE_CLASSES = [CASUS_CLASSES.section]

const SKIP_DEPTH_NODE_CLASSES = [
  CASUS_CLASSES.pageContentRoot,
  // CASUS_CLASSES.segmentsMarkerDiv,
  CASUS_CLASSES.segmentsMarkerContent,
]

const IGNORE_NODE_CLASSES = [CASUS_CLASSES.segmentsMarkerHeader, CASUS_CLASSES.textMarkerControls, CASUS_CLASSES.paragraphControls]

const CASUS_PREFIX = '_casus_'

//const IGNORE_STYLES = ['Normal']
const IGNORE_STYLES = []

const STYLE_MAP = {
  _casus_fsize: '_casus_font-size',
  _casus_bold: '_casus_font-weight-700',
  _casus_italic: '_casus_font-style-italic',
  _casus_underline: '_casus_text-decoration-underline',
  '_casus_indentation-left': '_casus_indent',
  _casus_jl: '_casus_align-left',
  _casus_jc: '_casus_align-center',
  _casus_jr: '_casus_align-right',
  _casus_jj: '_casus_align-justify',
  '_casus_top-border': '_casus_border-top-1px__solid__black',
  '_casus_bottom-border': '_casus_border-bottom-1px__solid__black',
  '_casus_left-border': '_casus_border-left-1px__solid__black',
  '_casus_right-border': '_casus_border-right-1px__solid__black',
}

const stylesInfo = {
  'font-weight': { property: 'font-weight', prefix: '', suffix: '', defaultValue: 'inherit' },
  'font-style': { property: 'font-style', prefix: '', suffix: '', defaultValue: 'inherit' },
  'text-decoration': { property: 'text-decoration', prefix: '', suffix: '', defaultValue: 'inherit' },
  // indent: { property: 'margin-left', prefix: '', suffix: 'pt', defaultValue: '0' },
  indent: { property: '--indent', prefix: '', suffix: 'pt', defaultValue: '0' },
  align: { property: 'text-align', prefix: '', suffix: '', defaultValue: 'left' },
  border: { property: 'border', prefix: '', suffix: '', defaultValue: '1px solid black' },
  'border-top': { property: 'border-top', prefix: '', suffix: '', defaultValue: '1px solid black' },
  'border-bottom': { property: 'border-bottom', prefix: '', suffix: '', defaultValue: '1px solid black' },
  'border-left': { property: 'border-left', prefix: '', suffix: '', defaultValue: '1px solid black' },
  'border-right': { property: 'border-right', prefix: '', suffix: '', defaultValue: '1px solid black' },
  'spacing-before': { property: 'margin-top', prefix: '', suffix: 'pt', defaultValue: '0' },
  'spacing-after': { property: 'margin-bottom', prefix: '', suffix: 'pt', defaultValue: '0' },
  'font-family': { property: 'font-family', prefix: '"', suffix: '", sans-serif', defaultValue: 'Arial' },
  'font-size': { property: 'font-size', prefix: '', suffix: 'pt', defaultValue: '11' },
  color: { property: 'color', prefix: '#', suffix: '', defaultValue: 'black' },
  background: { property: 'background-color', prefix: '#', suffix: '', defaultValue: 'FFFFFF' },
  width: { property: 'width', prefix: '', suffix: 'pt', defaultValue: 'auto' },
  height: { property: 'height', prefix: '', suffix: 'pt', defaultValue: 'auto' },
}

const STYLES_PROPERTIES = Object.entries(stylesInfo).reduce(
  (acc, [key, { property }]) => Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: property }),
  {}
)

const STYLES_AFFIXES = Object.entries(stylesInfo).reduce(
  (acc, [key, { prefix, suffix }]) => Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: { prefix, suffix } }),
  {}
)

const STYLES_DEFAULTS = Object.entries(stylesInfo).reduce(
  (acc, [key, { defaultValue }]) => (defaultValue && Object.assign(acc, { [`${CASUS_PREFIX}${key}`]: defaultValue })) || acc,
  {}
)

// const CASUS_STYLE_CLASSES = {
//   bold: '_casus_bold',
//   italic: '_casus_italic',
//   underline: '_casus_underline',
// }

// const CASUS_STYLE_DEFAULTS = {
//   [CASUS_STYLE_CLASSES.bold]: {
//     'font-weight': 700,
//   },
//   [CASUS_STYLE_CLASSES.italic]: {
//     'font-style': 'italic',
//   },
//   [CASUS_STYLE_CLASSES.underline]: {
//     'text-decoration': 'underline',
//   },
// }

export {
  SEGMENT_TYPES,
  SEGMENT_TAGS,
  CASUS_KEYSTRINGS,
  CASUS_IDS,
  CASUS_CLASSES,
  CASUS_REGEXES,
  PAPER_NAMES,
  MMPI,
  PXPI,
  PAGE_SIZES,
  ORIENTATION,
  DATA_STRUCTURE,
  NUMBERING_SYSTEM,
  NESTED_STRUCTURE_KEYS,
  NESTED_STRUCTURE_TYPES,
  SEGMENT_KEYS,
  RETAIN_KEYS,
  STOP_DEPTH_NODE_CLASSES,
  SKIP_DEPTH_NODE_CLASSES,
  IGNORE_NODE_CLASSES,
  IGNORE_STYLES,
  STYLE_MAP,
  STYLES_PROPERTIES,
  STYLES_AFFIXES,
  STYLES_DEFAULTS,
  // CASUS_STYLE_CLASSES,
  // CASUS_STYLE_DEFAULTS,
}

