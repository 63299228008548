import React, { FunctionComponent, useRef, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useStore, { TemplatesFilterSelector, SelectedTemplateIdSelector, SetSelectedDocumentIdAction, SetTemplatesFilterAction } from '___store'

import { TEMPLATES_FILTERS } from '___types'
import { useTemplateList } from '___hooks'
import { Folder, Plus } from 'assets/svgIconComponents'
import { Button, Grid, Input, TemplateThumbnail } from 'components/CasusComponents'
import useModal from 'Modal'
import { MainLayoutContentTemplatesProps, mainLayoutContentTemplatesClasses as classes } from '../../..'

type UseStoreHookResultType = {
  templatesFilter: TemplatesFilterSelector
  selectedTemplateId: SelectedTemplateIdSelector
  setTemplatesFilter: SetTemplatesFilterAction
  setSelectedTemplateId: SetSelectedDocumentIdAction
}

export const Templates: FunctionComponent<MainLayoutContentTemplatesProps> = React.memo(() => {
  const history = useHistory()
  // const [input, setInput] = useState<HTMLInputElement>()
  // const inputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setInput(node), [])
  const inputRef = useRef<HTMLInputElement>()
  const { templatesFilter, selectedTemplateId, setTemplatesFilter, setSelectedTemplateId } = useStore(
    'selectTemplatesFilter',
    'selectSelectedTemplateId',
    'setTemplatesFilter',
    'setSelectedTemplateId'
  ) as UseStoreHookResultType

  const categoryId = templatesFilter.slice(-1)[0]

  const {
    list: { data, isLoading, isFetching, error, status, hasNextPage, isFetchingNextPage, fetchNextPage },
    folders: { data: folderData, isLoading: foldersLoading, isFetching: foldersFetching, error: folderError },
    createFolder,
    creatingFolder,
  } = useTemplateList(categoryId)

  const createFolderCallback = useCallback(
    () => createFolder((inputRef.current as HTMLInputElement).value, categoryId),
    [createFolder, inputRef, categoryId]
  )

  const open = useModal({
    header: 'New folder',
    footer: false,
    content: close => (
      <>
        <Input
          ref={inputRef}
          type="text"
          defaultValue=""
          placeholder="Folder name"
          autoselect
          onConfirm={() => {
            createFolderCallback()
            close()
          }}
          showActionButtons={false}
        />
        <Button
          onClick={() => {
            createFolderCallback()
            close()
          }}
        >
          <Plus />
          Create
        </Button>
      </>
    ),
    className: classes.newFolder,
  })

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') return error
    if (error instanceof Error) return error.message
    return ''
  }, [error])

  const folderErrorMessage = useMemo(() => {
    if (typeof folderError === 'string') return folderError
    if (folderError instanceof Error) return folderError.message
    return ''
  }, [folderError])

  const folders = useMemo(() => {
    const result =
      folderData?.map(folder => (
        <Button key={`MainLayout-Components-Templates-Folder-${folder.id}`} onClick={() => setTemplatesFilter([categoryId, folder.id])}>
          <Folder />
          <span>{folder.name}</span>
        </Button>
      )) || []
    if (categoryId !== TEMPLATES_FILTERS.SHARED)
      result.unshift(
        <Button
          key={`MainLayout-Components-Templates-Folder-new`}
          onClick={() => open()}
          dataSet={{ new: '' }}
          loading={creatingFolder}
          disabled={creatingFolder}
        >
          <Folder function="add" />
          <span>New Folder</span>
          <Plus />
        </Button>
      )
    return result
  }, [folderData, setTemplatesFilter, categoryId, open, creatingFolder])

  const templates = useMemo(() => {
    const result =
      data?.pages
        .flat(1)
        .map(template => (
          <TemplateThumbnail
            key={`MainLayout-Components-Templates-Thumbnail-${template?.id}`}
            id={template?.id}
            selected={template?.id === selectedTemplateId}
            onClick={() => setSelectedTemplateId(template?.id!)}
            name={template?.name}
            edited={template?.edited}
            beingDuplicated={template?.beingDuplicated}
            beingDeleted={template?.beingDeleted}
            paywallBlocked={template?.paywallBlocked}
            sharedWith={template?.sharedWith}
          />
        )) || []
    if (categoryId === TEMPLATES_FILTERS.MINE)
      result.unshift(
        <TemplateThumbnail key={`MainLayout-Components-Templates-Thumbnail-new`} folderId={categoryId} onClick={() => history.push('template/new')} />
      )
    return result
  }, [categoryId, history, data, selectedTemplateId, setSelectedTemplateId])

  const buttonLabel = useMemo(() => {
    if (status === 'error') return 'Error loading templates'
    if (status === 'loading') return 'Loading...'
    if (isFetchingNextPage) return 'Loading more...'
    return 'Load more'
  }, [status, isFetchingNextPage])

  return (
    <div className={classes.wrapper}>
      <Grid className={classes.folders} isLoading={foldersLoading} isFetching={foldersFetching} error={folderErrorMessage}>
        {folders.length ? folders : null}
      </Grid>
      <Grid className={classes.templates} isLoading={isLoading} isFetching={isFetching} error={errorMessage}>
        {templates.length ? templates : <span>No available templates</span>}
      </Grid>
      {hasNextPage ? (
        <Button
          onClick={() => fetchNextPage()}
          onClickBlur
          disabled={!hasNextPage || isFetchingNextPage}
          loading={status === 'loading' || isFetchingNextPage}
          showSpinner
        >
          {buttonLabel}
        </Button>
      ) : null}
    </div>
  )
})

Templates.displayName = 'MainLayout-Components-Templates'

export default Templates
