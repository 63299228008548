import React, { FunctionComponent, useCallback, useMemo } from 'react'
import useStore, { DocumentsFilterSelector, TemplatesFilterSelector, SetDocumentsFilterAction, SetTemplatesFilterAction } from '___store'

import { useDocumentList, useTemplateList } from '___hooks'
import { Caret, Circle, Spinner } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import { MainLayoutLeftPaneFolderTreeProps, mainLayoutLeftPaneFolderTreeClasses as classes } from '../../..'

type UseStoreHookResultType = {
  documentsFilter: DocumentsFilterSelector
  templatesFilter: TemplatesFilterSelector
  setDocumentsFilter: SetDocumentsFilterAction
  setTemplatesFilter: SetTemplatesFilterAction
}

export const FolderTree: FunctionComponent<MainLayoutLeftPaneFolderTreeProps> = React.memo(({ directory, id, name, parentId, className }) => {
  const { documentsFilter, templatesFilter, setDocumentsFilter, setTemplatesFilter } = useStore(
    'selectDocumentsFilter',
    'selectTemplatesFilter',
    'setDocumentsFilter',
    'setTemplatesFilter'
  ) as UseStoreHookResultType

  const activeFilter = useMemo(() => {
    if (directory === 'documents') return documentsFilter
    if (directory === 'templates') return templatesFilter
    return []
  }, [directory, documentsFilter, templatesFilter])

  const categoryId = activeFilter.slice(-1)[0]
  const active = useMemo(() => categoryId === id, [categoryId, id])
  const focus = useMemo(() => activeFilter.includes(id), [activeFilter, id])

  const {
    folders: { data: documentFolders, isLoading: documentFoldersLoading },
  } = useDocumentList(directory === 'documents' && focus ? id : null)
  const {
    folders: { data: templateFolders, isLoading: templateFoldersLoading },
  } = useTemplateList(directory === 'templates' && focus ? id : null)

  const data = useMemo(() => {
    if (directory === 'documents') return documentFolders
    if (directory === 'templates') return templateFolders
  }, [directory, documentFolders, templateFolders])

  const isLoading = useMemo(() => {
    if (directory === 'documents') return documentFoldersLoading
    if (directory === 'templates') return templateFoldersLoading
    return false
  }, [directory, documentFoldersLoading, templateFoldersLoading])

  const buttonClickCallback = useCallback(() => {
    if (directory === 'documents') return setDocumentsFilter([parentId ?? [], id].flat(1) as [string, string])
    if (directory === 'templates') return setTemplatesFilter([parentId ?? [], id].flat(1) as [string, string])
  }, [directory, setDocumentsFilter, parentId, id, setTemplatesFilter])

  const render = useMemo(
    () => data?.map(folder => <FolderTree directory={directory} id={folder.id} name={folder.name} parentId={id} />),
    [data, directory, id]
  )

  const cn = [classes.wrapper, className].filter(s => s).join(' ')

  return (
    <div className={cn} data-active={active ? '' : undefined} data-nested={data?.length ? '' : undefined}>
      <Button
        onClick={buttonClickCallback}
        transparent
        onClickBlur
        dataSet={{ active: active ? '' : undefined, nested: data?.length ? '' : undefined, focus: focus ? '' : undefined }}
        noBackgroundSVG
        noOverlaySVG
      >
        {isLoading ? <Spinner /> : data?.length ? <Caret /> : <Circle />}
        <span>{name}</span>
      </Button>
      {render?.length ? <div className={classes.entries}>{render}</div> : null}
    </div>
  )
})

FolderTree.displayName = 'MainLayout-LeftPane-FolderTree'

export default FolderTree
