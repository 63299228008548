import { AppState } from '.'

export type SetDocumentsFilterPayload = string | [string, string]
const setDocumentsFilter = (state: AppState, payload: SetDocumentsFilterPayload): AppState => {
  const index = state.documents.filter.indexOf(typeof payload === 'string' ? payload : payload[0])
  const resultingFilter = (index === -1 ? [] : state.documents.filter.slice(0, index)).concat(payload)
  if (!payload || resultingFilter.join('; ') === state.documents.filter.join('; ')) return state
  return Object.assign(state.documents, { filter: resultingFilter, selected: null }) && Object.assign({}, state)
}

export type SetSelectedDocumentIdPayload = string
const setSelectedDocumentId = (state: AppState, payload: SetSelectedDocumentIdPayload): AppState =>
  !payload || payload === state.documents.selected ? state : Object.assign(state.documents, { selected: payload }) && Object.assign({}, state)

const deselectAllDocuments = (state: AppState): AppState =>
  !state.documents.selected ? state : Object.assign(state.documents, { selected: null }) && Object.assign({}, state)

export { setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments }
