import { v4 as uuid } from 'uuid'
import { InfiniteData, MutationOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENTS_FILTERS, IDocument } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryUnshift, infiniteQueryFilter, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type DuplicateDocumentVariables = { id: string; category?: string }
const duplicateDocumentMutationFunction = (variables: DuplicateDocumentVariables) => documentsAPI.duplicateDocument(variables.id)

export const useDuplicateDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const documentDuplicateMutation = useMutation<IDocument, unknown, DuplicateDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id],
    duplicateDocumentMutationFunction,
    {
      onMutate: variables => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument
        const mutationId = uuid()
        const duplicatedDocument = Object.assign(JSON.parse(JSON.stringify(currentDocument)), { id: mutationId, beingDuplicated: true })
        queryClient.setQueryData([QUERY_KEYS.DOCUMENT, mutationId], duplicatedDocument)
        if (variables.category)
          queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.category ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
            infiniteQueryUnshift(data!, DOCUMENT_LIST_PER_PAGE, duplicatedDocument)
          )
        return { mutationId }
      },
      onError: (error, variables, context) => {
        if (variables.category)
          queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.category ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
            infiniteQueryFilter(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === context?.mutationId)
          )
      },
      onSuccess: (document, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.DOCUMENT, context?.mutationId], document)
        if (variables.category)
          queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.category ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
            infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === context?.mutationId, document)
          )
      },
      // onSettled: async (document, error, variables, context) => {
      //   queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.id])
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //   const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.category ?? [])
      //   queryClient.cancelQueries(queryKey) // async
      //   queryClient.invalidateQueries(queryKey) // async
      //   queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   // =========================================================================================== //
      // },
    }
  )

  const duplicateMutationFunction = (
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutationOptions<IDocument, unknown, DuplicateDocumentVariables, { mutationId: string }>
  ) => documentDuplicateMutation.mutate({ id: id!, category }, options)

  return { duplicate: duplicateMutationFunction, duplicating: documentDuplicateMutation.isLoading }
}

export type DuplicateDocumentFunctionType = (
  category?: string,
  options?: MutationOptions<IDocument, unknown, DuplicateDocumentVariables, { mutationId: string }>
) => void

export default useDuplicateDocument
