import { IAnswer, DownloadFormats, IUpdateDocumentProps, DocumentType, IPermissions, IDocument, ITemplate } from 'types'
import Api from './api'
import TemplatesApi from 'api/templates.api'
import { analytics, StorageService } from 'utils'
import templateApi from 'api/templates.api'
// import { generateDocumentDataStructure } from 'utilities/dataStructureGeneration'
import { generateHtml } from 'utilities'
// import { generateDataStructure } from '___store/storeSegments/wizard/helpers'
import { parseAnswers, parseV3forBE } from 'Wizard/parsing'
import { evaluateAllMarkers } from '___store/storeSegments/wizard/helpers/index'
import { applyAnswerValuesToDataStructure } from 'Wizard/helpers'
import { WizardState, initializeAnswerRelevance, evaluateMarkers } from '___store/storeSegments/wizard/typified/helpers'
import { rasterizeDataStructure } from 'Layouts/WizardLayout/components/RightPane/RightPane.Editor.Components'
import { CASUS_IDS, DOCUMENT_FLOW_MODES, Segment, Segments } from '___types'

export interface ICreateDocumentPropsApi {
  templateId: string
  answers?: IAnswer[]
  v3Answers?: IAnswer[]
  htmlData?: string
  category?: string[]
  new?: boolean
  name?: string
}

export interface IUploadDocumentPropsApi {
  category: string[]
  filename: string
  base64data: string
}

export interface IGetDocumentsApi {
  type: DocumentType
  lastItemFetched?: string
  limit?: number
  folderId?: string
}

export interface IGetDocumentsWithSignaturesApi {
  filter: string | 'all'
  lastItemFetched?: string
  limit?: number
}

interface IUpdateDocument {
  id: string
  name?: string
  data: IUpdateDocumentProps
  isPublic?: boolean
  version?: 'v1' | 'v2'
}

interface ISignatureEntity {
  email: string
  firstName: string
  lastName: string
}

interface ISignDocument {
  documentId: string
  title: string
  message: string
  signatureEntities: ISignatureEntity[]
  quality: 'SES' | 'AES' | 'QES'
  legislation: 'ZERTES' | 'EIDAS'
  splitId?: string
}

interface ISignPdf {
  documentId: string
  title: string
  message: string
  signatureEntities: string[]
  quality: 'SES' | 'AES' | 'QES'
  legislation: 'ZERTES' | 'EIDAS'
}

interface IDuplicateDocument {
  id: string
}

const keyMap = { tableHeader: 'header', tableBody: 'body', tableFooter: 'footer', customStyle: 'styleName' } as Record<string, string>
const keyFilter = ['tag', 'start', 'length']
const keyIgnore = ['id', 'styles']
const parseSegmentsForDownload = (segments: Segments) =>
  segments.map(segment =>
    Object.entries(segment).reduce((acc, [key, value]) => {
      if (keyFilter.includes(key)) return acc
      const mapped = (
        keyIgnore.includes(key) ? { [key]: value } : { [keyMap[key] || key]: Array.isArray(value) ? parseSegmentsForDownload(value) : value }
      ) as Record<string, unknown>
      return Object.assign(acc, mapped)
    }, {} as Segment)
  )

class DocsApi extends Api {
  public constructor() {
    super('/documents', true)
  }

  public create = async (data: ICreateDocumentPropsApi, isPublic?: boolean): Promise<IDocument> => {
    let res = {} as any
    if (data.new) {
      res = await this.api().post(`/v2/documents/${isPublic ? 'public' : ''}`, data)
    } else {
      res = await this.api().post(`/v1/documents/${isPublic ? 'public' : ''}`, data)
    }
    return res.data.data
  }

  public update = async ({ id, data, isPublic, version = 'v1' }: IUpdateDocument): Promise<IDocument> => {
    const res = await this.api().patch(`/${version}/documents/${isPublic ? 'public/' : ''}${id}`, data)
    return res.data.data
  }

  public uploadLogo = async (documentId: string, imageData: string): Promise<string> => {
    const res = await this.api().post(`/v1/documents/${documentId}/logo/upload`, { imageData })
    return res.data.data.url
  }

  public getOne = async (documentId: string, newDocxMicroservice?: boolean): Promise<IDocument> => {
    const version = newDocxMicroservice ? 'v2' : 'v1'
    const res = await this.api().get(`/${version}/documents/${documentId}`)
    return res.data.data
  }

  public getOnePublic = async (documentId: string, newDocxMicroservice?: boolean): Promise<IDocument> => {
    const version = newDocxMicroservice ? 'v2' : 'v1'
    const res = await this.api().get(`/${version}/documents/public/${documentId}`)
    return res.data.data
  }

  public async extractParties(documentId: string, query: string) {
    // const docRes = await this.getOne(documentId, true)
    const docRes = await this.api().post(`/v2/documents/${documentId}/extract/parties`, {
      query: query,
    })
    // call api to query gpt
    return docRes
  }

  public fetchEditDocumentData = async (
    documentId: string,
    useLatestTemplateVersion?: boolean
  ): Promise<{ documentFile: IDocument; template: ITemplate }> => {
    const documentFile = await this.getOne(documentId)
    const contentVersionId = documentFile.templateContentVersionId
    const useLatest = useLatestTemplateVersion || !contentVersionId
    const template = useLatest
      ? await TemplatesApi.getOne(documentFile.templateId)
      : await TemplatesApi.getTemplateVersion(documentFile.templateId, documentFile.templateContentVersionId, true)

    return { documentFile, template }
  }

  public get = async (data: IGetDocumentsApi): Promise<IDocument[]> => {
    const { type, lastItemFetched, limit, folderId } = data
    let url = `/v1/documents/list/${type}`
    if (folderId) {
      url = `v1/documents/list/${type}/category`
    }
    const params = new URLSearchParams()
    if (limit) params.set('limit', limit.toString())
    if (lastItemFetched) params.set('startAfter', lastItemFetched)
    if (!folderId) url = url + '?' + params.toString()

    try {
      await this.convertPublicDocument()
      const res = !folderId ? await this.api().get(url) : await this.api().post(url, { category: folderId })

      return res.data.data.documents.map((doc: IDocument) => ({
        ...doc,
        rootFolder: type,
      }))
    } catch (err) {
      throw err
    }
  }

  public getWithSignatures = async (data: IGetDocumentsWithSignaturesApi): Promise<IDocument[]> => {
    const { filter, lastItemFetched, limit } = data
    let url = `/v2/documents/list/signature`
    const params = new URLSearchParams()
    if (limit) params.set('limit', limit.toString())
    if (lastItemFetched) params.set('startAfter', lastItemFetched)
    if (filter) params.set('filter', filter)

    url = url + '?' + params.toString()

    try {
      const res = await this.api().get(url)

      return res.data.data.documents.map((doc: IDocument) => ({
        ...doc,
      }))
    } catch (err) {
      throw err
    }
  }

  public duplicate = async ({ id }: IDuplicateDocument): Promise<void> => {
    await this.api().post(`/v2/documents/${id}/duplicate`)
  }

  public convertPublicDocument = async (): Promise<void> => {
    const publicTemplatePurchase = StorageService.read('publicTemplatePurchase')
    if (!publicTemplatePurchase) return
    const id = publicTemplatePurchase?.documentId
    const templateId = publicTemplatePurchase?.documentId
    const templateName = publicTemplatePurchase?.templateName

    if (id) {
      await this.api().patch(`/v1/documents/public/${id}/mine`)
      analytics.logEvent('public_document_created', {
        templateId,
        templateName,
      })
      StorageService.remove('publicTemplatePurchase')
    }
  }

  public emptyTrashed = async () => {
    await this.api().post('/v1/documents/empty/trashed')
  }

  public share = async (templateId: string, email: string): Promise<IDocument> => {
    const res = await this.api().post(`/v1/documents/${templateId}/share`, {
      email,
      permissions: { read: true, write: false },
    })
    return res.data.data
  }

  public permissionsEdit = async (templateId: string, personId: string, permissions: IPermissions): Promise<IDocument> => {
    const res = await this.api().patch(`/v1/documents/${templateId}/share/${personId}`, {
      permissions,
    })
    return res.data.data
  }

  public permissionsDelete = async (templateId: string, personId: string): Promise<IDocument> => {
    const res = await this.api().delete(`/v1/documents/${templateId}/share/${personId}`)
    return res.data.data
  }

  public async download(documentId: string, format: DownloadFormats, newDocxMicroservice?: boolean, version?: string) {
    const dxmVersion = newDocxMicroservice ? 'v2' : 'v1'
    const docRes = await this.getOne(documentId, newDocxMicroservice)
    let res: any
    if (dxmVersion === 'v1') {
      res = await this.api({ responseType: 'blob' }).get(`${dxmVersion}/documents/${documentId}/export/${format}`)
    } else {
      const { v3Answers, answers, templateId } = docRes
      const v3answers = parseAnswers(v3Answers)
      const template = (await templateApi.getOne(templateId, false, newDocxMicroservice)) as any
      const {
        v3 = {},
        dataStructure: { storageId },
      } = template || {}
      if (!storageId) return
      const { dataStructure: v3dataStructure = {}, locations: v3locations = { choice: {}, replacement: {} }, questions: v3questions = [] } = v3

      let dataStructure = undefined
      if (version === 'v3') {
        const pseudoState = { questions: v3questions, answers: v3answers, locations: v3locations, mode: 'document-generation' }
        const evaluatedState = evaluateMarkers(initializeAnswerRelevance(pseudoState as WizardState), false)
        const evaluatedMarkers = evaluatedState.locations
        const answered = applyAnswerValuesToDataStructure(v3dataStructure, evaluatedMarkers!)
        dataStructure = (parseV3forBE({ dataStructure: answered }) as any)?.dataStructure
      } else dataStructure = generateHtml(template.dataStructure, template.questions, answers)[1] as any

      const keyMap = { tableHeader: 'header', tableBody: 'body', tableFooter: 'footer', customStyle: 'styleName' } as any
      const keyFilter = ['tag', 'start', 'length'] as any
      const keyIgnore = ['id', 'styles'] as any
      const parseForBE = (segments = [] as any) =>
        segments.map((segment: any) =>
          Object.entries(segment).reduce((acc, [key, value]) => {
            if (keyFilter.includes(key)) return acc
            // @ts-ignore
            if (keyIgnore.includes(key)) acc[key] = value
            else {
              const newKey = (keyMap[key] || key) as String
              const newValue = Array.isArray(value) ? parseForBE(value) : value
              // @ts-ignore
              if (!newKey) acc[key] = value
              // @ts-ignore
              else acc[newKey] = newValue as any
            }
            return acc
          }, {})
        )

      const allLocationParents = Object.keys(Object.assign({}, v3locations.segments, v3locations.text))
      const payload = { storageId: storageId, paragraphs: parseForBE(dataStructure?.segments || []) }
      type HeaderFooterType = Record<string, unknown> & { id: string; segments: Record<string, unknown>[] }
      const changedHeaders = dataStructure?.headers?.reduce(
        (headers: HeaderFooterType[], header: HeaderFooterType) =>
          headers.concat(
            allLocationParents.includes(header.id) ||
              header.segments?.map(({ id }) => String(id)).some((id: string) => allLocationParents.includes(id))
              ? Object.assign({}, header, { segments: parseForBE(header.segments) })
              : []
          ),
        [] as HeaderFooterType[]
      )
      const changedFooters = dataStructure?.footers?.reduce(
        (footers: HeaderFooterType[], footer: HeaderFooterType) =>
          footers.concat(
            allLocationParents.includes(footer.id) ||
              footer.segments?.map(({ id }) => String(id)).some((id: string) => allLocationParents.includes(id))
              ? Object.assign({}, footer, { segments: parseForBE(footer.segments) })
              : []
          ),
        [] as HeaderFooterType[]
      )
      if (changedHeaders?.length) Object.assign(payload, { headers: changedHeaders })
      if (changedFooters?.length) Object.assign(payload, { footers: changedFooters })

      res = await this.api({ responseType: 'blob' }).post(`${dxmVersion}/documents/${documentId}/export/${format}`, payload)
    }

    if (format === 'docx' || format === 'pdf') {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(res.headers['content-disposition'])
      const filename = matches ? matches[1] : ''

      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = window.document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      window.document.body.appendChild(link)
      link.click()
      window.document.body.removeChild(link)
    }

    return res
  }

  public async downloadPublic(documentId: string, format: DownloadFormats, newDocxMicroservice?: boolean, version?: string) {
    const dxmVersion = newDocxMicroservice ? 'v2' : 'v1'
    const docRes = await this.getOnePublic(documentId, newDocxMicroservice)
    let res: any
    if (dxmVersion === 'v1') {
      res = await this.api({ responseType: 'blob' }).get(`${dxmVersion}/documents/${documentId}/export/${format}`)
    } else {
      const { v3Answers, answers, templateId } = docRes
      const v3answers = parseAnswers(v3Answers)
      const template = (await templateApi.getOne(templateId, true, newDocxMicroservice)) as any
      const {
        v3 = {},
        dataStructure: { storageId },
      } = template || {}
      if (!storageId) return
      const { dataStructure: v3dataStructure = {}, locations: v3locations = { choice: {}, replacement: {} }, questions: v3questions = [] } = v3

      let dataStructure = undefined
      if (version === 'v3') {
        const pseudoState = { questions: v3questions, answers: v3answers, locations: v3locations, mode: 'document-generation' }
        const evaluatedState = evaluateAllMarkers(pseudoState)
        const evaluatedMarkers = evaluatedState.locations
        dataStructure = (
          parseV3forBE({
            dataStructure: applyAnswerValuesToDataStructure(v3dataStructure, evaluatedMarkers),
          }) as any
        )?.dataStructure
      } else dataStructure = generateHtml(template.dataStructure, template.questions, answers)[1] as any

      const keyMap = {
        tableHeader: 'header',
        tableBody: 'body',
        tableFooter: 'footer',
        customStyle: 'styleName',
      } as any
      const keyFilter = ['tag', 'start', 'length'] as any
      const keyIgnore = ['id', 'styles'] as any
      const parseForBE = (segments = [] as any) =>
        segments.map((segment: any) =>
          Object.entries(segment).reduce((acc, [key, value]) => {
            if (keyFilter.includes(key)) return acc
            // @ts-ignore
            if (keyIgnore.includes(key)) acc[key] = value
            else {
              const newKey = (keyMap[key] || key) as String
              const newValue = Array.isArray(value) ? parseForBE(value) : value
              // @ts-ignore
              if (!newKey) acc[key] = value
              // @ts-ignore
              else acc[newKey] = newValue as any
            }
            return acc
          }, {})
        )

      res = await this.api({
        responseType: 'blob',
      }).post(`${dxmVersion}/documents/public/${documentId}/export/${format}`, {
        storageId: storageId,
        paragraphs: parseForBE(dataStructure?.segments || []),
      })
    }

    if (format === 'docx' || format === 'pdf') {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(res.headers['content-disposition'])
      const filename = matches ? matches[1] : ''

      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = window.document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      window.document.body.appendChild(link)
      link.click()
      window.document.body.removeChild(link)
    }

    return res
  }

  public async downloadSignedSkribble(documentId: string, splitId: string) {
    const pdf = await this.getSkribblePdf(documentId, splitId, true)
    const url = window.URL.createObjectURL(new Blob([pdf]))
    const link = window.document.createElement('a')
    link.href = url
    link.setAttribute('download', 'signed.pdf')
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }

  public getSkribblePdf = async (documentId: string, splitId: string, isExport?: boolean): Promise<ArrayBuffer> => {
    const res = await this.api({ responseType: 'arraybuffer' }).get(`v2/documents/${documentId}/${splitId}/signature/pdf${isExport ? '/export' : ''}`)
    return res.data
  }
  public signatureSendReminder = async (documentId: string, signatureId: string, email: string) => {
    const res = await this.api({ responseType: 'arraybuffer' }).post(`v2/documents/${documentId}/signature/${signatureId}/reminder`, {
      email,
    })
    return res.data
  }
  public getPdfData = async (documentId: string): Promise<ArrayBuffer> => {
    const res = await this.api({ responseType: 'arraybuffer' }).get(`v1/documents/documentsPDF/${documentId}`)
    return res.data
  }

  public getApprovalDocuments = async (): Promise<IDocument[]> => {
    try {
      const res = await this.api().get(`/v2/documents/list/approval`)
      return res.data.documents
    } catch (err) {
      throw err
    }
  }

  public async sign({ documentId, signatureEntities, message, title, quality, legislation, splitId }: ISignDocument): Promise<IDocument> {
    const docRes = await this.getOne(documentId, true)
    const template = await templateApi.getOne(docRes.templateId, false, true)
    let res: any
    //@ts-ignore
    if (template.version === 'v1') {
      Promise.reject()
      //@ts-ignore
    } else if (template.version === 'v3') {
      //@ts-ignore
      const { languages, externalAPIs, answers } = docRes
      //@ts-ignore
      const { dataStructure, questions, locations } = template
      const evaluatedDataStructure = rasterizeDataStructure(
        DOCUMENT_FLOW_MODES.PREVIEW,
        Object.assign({}, dataStructure, { id: CASUS_IDS.DATASTRUCTURE_ID }),
        locations,
        //@ts-ignore
        questions,
        languages,
        externalAPIs,
        splitId || null,
        //@ts-ignore
        answers
      )[0]
      const parsedDataStructure = (parseV3forBE({ dataStructure: evaluatedDataStructure }) as ITemplate).dataStructure
      const paragraphs = parseSegmentsForDownload(parsedDataStructure.segments)
      const payload = {
        documentId,
        title: title,
        message: message,
        quality: quality,
        legislation: legislation,
        signatureEntities: signatureEntities,
        requireSkribbleAccount: false,
        paragraphs,
        splitId,
      }
      //@ts-ignore
      res = await this.api().post(`v2/documents/${documentId}/signature`, payload)
    } else {
      const { answers } = docRes
      const {
        dataStructure: { storageId },
      } = template || {}
      if (!storageId) Promise.reject()

      let dataStructure = undefined
      //@ts-ignore
      dataStructure = generateHtml(template.dataStructure, template.questions, answers)[1] as any

      const keyMap = { tableHeader: 'header', tableBody: 'body', tableFooter: 'footer', customStyle: 'styleName' } as any
      const keyFilter = ['tag', 'start', 'length'] as any
      const keyIgnore = ['id', 'styles'] as any
      const parseForBE = (segments = [] as any) =>
        segments.map((segment: any) =>
          Object.entries(segment).reduce((acc, [key, value]) => {
            if (keyFilter.includes(key)) return acc
            // @ts-ignore
            if (keyIgnore.includes(key)) acc[key] = value
            else {
              const newKey = (keyMap[key] || key) as String
              const newValue = Array.isArray(value) ? parseForBE(value) : value
              // @ts-ignore
              if (!newKey) acc[key] = value
              // @ts-ignore
              else acc[newKey] = newValue as any
            }
            return acc
          }, {})
        )

      // missing header and footer support on signature

      res = await this.api().post(`v2/documents/${documentId}/signature`, {
        documentId,
        title: title,
        message: message,
        quality: quality,
        legislation: legislation,
        signatureEntities: signatureEntities,
        requireSkribbleAccount: false,
        paragraphs: parseForBE(dataStructure.segments),
        splitId: 0,
      })
    }

    try {
      return res.data.data
    } catch (e) {
      throw res.data.error_code
    }
  }

  public async signPdf({ title, message, signatureEntities, quality, legislation, documentId }: ISignPdf): Promise<IDocument> {
    const res = await this.api().post(`v2/documents/${documentId}/pdf/signature`, {
      documentId,
      title,
      message,
      quality,
      legislation,
      signatureEntities,
      requireSkribbleAccount: false,
    })

    try {
      return res.data.data
    } catch (e) {
      throw res.data.error_code
    }
  }

  public async uploadToExternal(documentId: string, employeeId: string, serviceType: string, categoryId: string, isShared: boolean) {
    const docRes = await this.getOne(documentId, true)
    let res: any
    const { v3Answers, templateId } = docRes
    const v3answers = parseAnswers(v3Answers)
    const template = (await templateApi.getOne(templateId, false, true)) as any
    const {
      v3 = {},
      dataStructure: { storageId },
    } = template || {}
    if (!storageId) return
    const { dataStructure: v3dataStructure = {}, locations: v3locations = { choice: {}, replacement: {} }, questions: v3questions = [] } = v3

    let dataStructure = undefined
    const pseudoState = { questions: v3questions, answers: v3answers, locations: v3locations, mode: 'document-generation' }
    const evaluatedState = evaluateAllMarkers(pseudoState)
    const evaluatedMarkers = evaluatedState.locations
    dataStructure = (
      parseV3forBE({
        dataStructure: applyAnswerValuesToDataStructure(v3dataStructure, evaluatedMarkers),
      }) as any
    )?.dataStructure

    const keyMap = {
      tableHeader: 'header',
      tableBody: 'body',
      tableFooter: 'footer',
      customStyle: 'styleName',
    } as any
    const keyFilter = ['tag', 'start', 'length'] as any
    const keyIgnore = ['id', 'styles'] as any
    const parseForBE = (segments = [] as any) =>
      segments.map((segment: any) =>
        Object.entries(segment).reduce((acc, [key, value]) => {
          if (keyFilter.includes(key)) return acc
          // @ts-ignore
          if (keyIgnore.includes(key)) acc[key] = value
          else {
            const newKey = (keyMap[key] || key) as String
            const newValue = Array.isArray(value) ? parseForBE(value) : value
            // @ts-ignore
            if (!newKey) acc[key] = value
            // @ts-ignore
            else acc[newKey] = newValue as any
          }
          return acc
        }, {})
      )

    res = await this.api().post(`/v1/users/external/services/employee/upload`, {
      storageId: storageId,
      paragraphs: parseForBE(dataStructure?.segments || []),
      serviceType,
      isShared,
      name: docRes.name,
      categoryId,
      employeeId,
    })

    return res
  }
}

class DocsUploadApiWrapper extends Api {
  constructor() {
    super('/', true)
  }

  public uploadPDF = async (data: IUploadDocumentPropsApi): Promise<IDocument> => {
    const res = await this.api().post(`/v1/documents/documentsPDF`, data)
    return res.data.data
  }
}

export const DocsUploadApi = new DocsUploadApiWrapper()

const documentsApi = new DocsApi()

export default documentsApi
