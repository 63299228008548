import { MouseEvent } from 'react'
import { Author, Time } from '___types'

import TemplateThumbnail from './Template.Thumbnail'
import TemplateListItem from './Template.ListItem'

import './style.scss'

export const listItemClasses = {
  wrapper: 'Casus-Components-TemplateListItem-wrapper',
  content: 'Casus-Components-TemplateListItem-content',
}
export const thumbnailClasses = {
  wrapper: 'Casus-Components-TemplateThumbnail-wrapper',
  content: {
    wrapper: 'Casus-Components-TemplateThumbnail-content-wrapper',
    info: 'Casus-Components-TemplateThumbnail-content-info',
    name: 'Casus-Components-TemplateThumbnail-content-name',
    time: 'Casus-Components-TemplateThumbnail-content-time',
  },
}
const classes = {
  listItem: listItemClasses,
  thumbnail: thumbnailClasses,
} as const

export type ListItemProps = {
  id: string
  onClick?: (event: MouseEvent<HTMLButtonElement>, templateId: string) => void
}
export type ThumbnailProps = {
  id?: string | null
  folderId?: string | null
  selected?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>, templateId?: string | null) => void
  name?: string
  edited?: Time
  beingDuplicated?: boolean
  beingDeleted?: boolean
  paywallBlocked?: boolean
  sharedWith?: Author[]
}

export { TemplateListItem, TemplateThumbnail, classes }
