import React, { FunctionComponent, useMemo, useEffect, useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import useStore, {
  WizardInitializedSelector,
  WizardModeSelector,
  WizardTemplateIdSelector,
  WizardTitleSelector,
  WizardEditorStylesSelector,
  WizardDataStructureSelector,
  WizardLocationsSelector,
  WizardQuestionsSelector,
  WizardQuestionLayoutSelector,
  WizardLanguagesSelector,
  WizardIntegrationEntriesSelector,
  WizardExternalAPIsSelector,
  WizardSplitsSelector,
  WizardAnswersSelector,
  ResetWizardAction,
  InitializeWizardAction,
  UpdateWizardStateAction,
  UpdateWizardStatePayload,
  WizardActiveSplitSelector,
  WizardState,
} from '___store'

import {
  DIRECTORY_DEFAULT_FLOW_MODE,
  DIRECTORY_FLOWS,
  DIRECTORY_MODE_PATHNAME_MATCH,
  DOCUMENT_DIRECTORY,
  DOCUMENT_FLOW_MODES,
  Directory,
  DocumentFlowMode,
  SubRecord,
  WIZARD_URL_PARAM_MATCH_ALL,
  WizardMode,
} from '___types'
import { useDocument, useTemplate } from '___hooks'
import { Spinner } from 'assets/svgIconComponents'
import { Header, LeftPane, RightPane, WizardLayoutContextProvider, wizardLayoutClasses as classes } from '.'

type UseStoreHookResultType = {
  wizardInitialized: WizardInitializedSelector
  wizardMode: WizardModeSelector
  wizardTemplateId: WizardTemplateIdSelector
  wizardTitle: WizardTitleSelector
  wizardEditorStyles: WizardEditorStylesSelector
  wizardDataStructure: WizardDataStructureSelector
  wizardLocations: WizardLocationsSelector
  wizardQuestions: WizardQuestionsSelector
  wizardQuestionLayout: WizardQuestionLayoutSelector
  wizardLanguages: WizardLanguagesSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  wizardExternalAPIs: WizardExternalAPIsSelector
  wizardSplits: WizardSplitsSelector
  wizardActiveSplit: WizardActiveSplitSelector
  wizardAnswers: WizardAnswersSelector
  resetWizard: ResetWizardAction
  initializeWizard: InitializeWizardAction
  updateWizardState: UpdateWizardStateAction
}

export const WizardLayout: FunctionComponent = React.memo(() => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const {
    templateId: urlTemplateId,
    documentId: urlDocumentId,
    mode: urlMode,
  } = useParams() as { templateId: string | undefined; documentId: string | undefined; mode: string | undefined }
  // console.groupCollapsed('WIZARD LAYOUT')
  // console.log('PATHNAME: ', pathname)
  // console.log('PARAMS: ', urlTemplateId, urlDocumentId, urlMode)

  const {
    wizardInitialized,
    wizardMode,
    wizardTemplateId,
    wizardTitle,
    wizardEditorStyles,
    wizardDataStructure,
    wizardLocations,
    wizardQuestions,
    wizardQuestionLayout,
    wizardLanguages,
    wizardIntegrationEntries,
    wizardExternalAPIs,
    wizardSplits,
    wizardActiveSplit,
    wizardAnswers,
    resetWizard,
    initializeWizard,
    updateWizardState,
  } = useStore(
    'selectWizardInitialized',
    'selectWizardMode',
    'selectWizardTemplateId',
    'selectWizardTitle',
    'selectWizardEditorStyles',
    'selectWizardDataStructure',
    'selectWizardLocations',
    'selectWizardQuestions',
    'selectWizardQuestionLayout',
    'selectWizardLanguages',
    'selectWizardIntegraionEntries',
    'selectWizardExternalAPIs',
    'selectWizardSplits',
    'selectWizardActiveSplit',
    'selectWizardAnswers',
    'resetWizard',
    'initializeWizard',
    'updateWizardState'
  ) as UseStoreHookResultType

  const [directory, mode] = useMemo(() => {
    const { directory: extractedDirectory } = pathname.match(DIRECTORY_MODE_PATHNAME_MATCH)?.groups || {}
    if (!extractedDirectory) return [undefined, undefined]
    if (wizardMode) return [extractedDirectory, wizardMode]
    if (!urlMode) {
      if ((!urlDocumentId && urlTemplateId) || urlDocumentId === 'new') return [extractedDirectory, DOCUMENT_FLOW_MODES.NEW]
      if (urlDocumentId) return [extractedDirectory, DOCUMENT_FLOW_MODES.EDIT]
      return [extractedDirectory, DOCUMENT_FLOW_MODES.TEMPLATE_SELECT]
    }
    const flow = DIRECTORY_FLOWS[extractedDirectory === 'public' ? DOCUMENT_DIRECTORY : (extractedDirectory as Directory)]
    const urlFlowMode = flow[urlMode?.toUpperCase() as keyof typeof flow]
    return [extractedDirectory, urlFlowMode || DIRECTORY_DEFAULT_FLOW_MODE[extractedDirectory as Directory]] as [Directory, WizardMode]
  }, [pathname, wizardMode, urlMode, urlDocumentId, urlTemplateId])
  // console.log('DIRECTORY: ', directory)
  // console.log('MODE: ', mode)

  const reset = useCallback(() => {
    // console.log('%cRESETTING!', 'color: red')
    resetWizard()
    history.push(`/${directory ?? ''}`)
  }, [resetWizard, history, directory])

  // ======================================================================================= //
  // ======================================= QUERIES ======================================= //
  // ======================================================================================= //
  const documentId = (urlDocumentId && urlDocumentId !== 'new' ? urlDocumentId : null)!
  const {
    data: document,
    isLoading: documentLoading,
    isFetching: documentFetching,
    isError: documentError,
  } = useDocument(documentId, directory === 'public')
  const {
    answers,
    languages: selectedLanguages,
    integrationEntries,
    externalAPIs: integrationValues,
    publicSettings: documentPublicSettings,
  } = document || {}
  const computedTemplateId = wizardTemplateId || document?.templateId || urlTemplateId
  const templateId = (computedTemplateId && computedTemplateId !== 'unknown' ? computedTemplateId : null)!
  const {
    data: template,
    isLoading: templateLoading,
    isFetching: templateFetching,
    isError: templateError,
    // paywallBlocked,
  } = useTemplate(templateId, directory === 'public')
  const { publicSettings: templatePublicSettings } = template || {}
  const publicSettings = Object.assign({}, templatePublicSettings, documentPublicSettings)
  // ======================================================================================= //
  //
  //
  //
  // ======================================================================================= //
  // ==================================== ERROR EFFECTS ==================================== //
  // ======================================================================================= //
  useEffect(() => {
    // console.log('%cERROR EFFECTS!', 'color: orange', directory === 'document' && Boolean(documentError || templateError || paywallBlocked))
    if (!directory) return reset()
    if (directory === 'document') {
      if (documentError) throw reset()
      if (templateError) throw reset()
      // if (paywallBlocked) {
      //   initializeWizard({ mode: DIRECTORY_FLOWS[directory].TEMPLATE_SELECT })
      //   history.push(`/${DOCUMENT_DIRECTORY}`)
      // }
    }
  }, [
    reset,
    directory,
    documentError,
    templateError,
    // paywallBlocked, initializeWizard, history
  ])
  // ======================================================================================= //
  //
  //
  //
  // ======================================================================================= //
  // =================================== URL CORRECTIONS =================================== //
  // ======================================================================================= //
  let targetTemplateId
  if (([DOCUMENT_FLOW_MODES.NEW, DOCUMENT_FLOW_MODES.EDIT, DOCUMENT_FLOW_MODES.PREVIEW] as DocumentFlowMode[]).includes(mode as DocumentFlowMode))
    targetTemplateId = computedTemplateId
  let targetDocumentId
  if (targetTemplateId) targetDocumentId = urlDocumentId || 'new'
  let targetMode
  if (mode === DOCUMENT_FLOW_MODES.PREVIEW) targetMode = DOCUMENT_FLOW_MODES.PREVIEW.split('-').slice(1).join('-')
  let targetSplit = wizardActiveSplit
  if (directory === 'public' && publicSettings?.split) targetSplit = publicSettings.split
  const searchParams = Object.assign(
    Array.from(search.matchAll(WIZARD_URL_PARAM_MATCH_ALL)).reduce(
      (result, { groups: { key, value } = {} }) => (key ? Object.assign(result, { [key]: value }) : result),
      {}
    ) as Record<string, string>,
    targetSplit ? { split: targetSplit } : undefined
  )
  let targetSearch = ''
  if (Object.keys(searchParams).length)
    targetSearch = `?${Object.entries(searchParams)
      .reduce((entries, entry) => entries.concat(entry.join('=')), [] as string[])
      .join('&')}`

  let targetPath = `/${directory}`
  if (targetTemplateId) {
    targetPath += `/${targetTemplateId}`
    if (targetDocumentId) {
      targetPath += `/${targetDocumentId}`
      if (targetMode) targetPath += `/${targetMode}`
    }
  }
  const fullPath = `${targetPath}${targetSearch}`

  const pathMismatch = targetPath !== pathname
  const searchMismatch = targetSearch !== search
  // console.log('PATH: ', targetPath, pathname)
  // console.log('SEARCH: ', targetSearch, search)
  // console.log('URL MISMATCH: ', pathMismatch, searchMismatch)

  useEffect(() => {
    if (![DOCUMENT_DIRECTORY, 'public'].includes(directory as string)) return
    if (pathMismatch || searchMismatch) history.push(fullPath)
  }, [directory, pathMismatch, searchMismatch, history, fullPath])
  // ======================================================================================= //
  //
  //
  //
  // ======================================================================================= //
  // ================================ WIZARD INITIALIZATION ================================ //
  // ======================================================================================= //
  const isLoading = templateLoading || documentLoading
  const isFetching = templateFetching || documentFetching
  const isSettled = !(isLoading || isFetching)
  // console.groupCollapsed('QUERIES: ', templateId, documentId)
  // console.log('LOADING: ', isLoading, templateLoading, documentLoading)
  // console.log('FETCHING: ', isFetching, templateFetching, documentFetching)
  // console.log('SETTLED: ', isSettled)
  // console.log('TEMPLATE: ', computedTemplateId, template)
  // console.log('DOCUMENT: ', urlDocumentId, document)
  // console.groupEnd()
  const hasErrors = documentError || templateError || pathMismatch || searchMismatch
  // console.log('ERRORS: ', documentError, templateError, pathMismatch, searchMismatch)

  useEffect(() => {
    if (!isSettled || hasErrors) return
    // console.log('%cWIZARD INITIALIZATION!', 'color: orange')
    if (directory) {
      let update = false
      const payload = {} as SubRecord<WizardState>
      if (mode) {
        Object.assign(payload, { mode })
        update = update || mode !== wizardMode
      }
      if (templateId) {
        Object.assign(payload, { templateId })
        update = update || templateId !== wizardTemplateId
      }
      if (searchParams.split) {
        Object.assign(payload, { activeSplit: searchParams.split })
        update = update || searchParams.split !== wizardActiveSplit
      }
      // console.log('WIZARD INITIALIZED: ', wizardInitialized, update)
      // console.log('WIZARD INITIALIZE/UPDATE PAYLOAD: ', payload)
      if (update)
        if (!wizardInitialized) initializeWizard(payload)
        else updateWizardState(payload)
    }
  }, [
    isSettled,
    hasErrors,
    directory,
    mode,
    wizardMode,
    templateId,
    wizardTemplateId,
    searchParams,
    wizardActiveSplit,
    wizardInitialized,
    initializeWizard,
    updateWizardState,
  ])
  // ======================================================================================= //
  //
  //
  //
  // ======================================================================================= //
  // ================================= WIZARD STATE UPDATE ================================= //
  // ======================================================================================= //
  useEffect(() => {
    // console.log('%cWIZARD STATE UPDATE!', 'color: orange')
    if (isSettled && template && wizardInitialized) {
      const payload = {} as UpdateWizardStatePayload
      const { name, styles, dataStructure, locations, questions, questionLayout, languages, externalAPIs, splits } = JSON.parse(
        JSON.stringify(template)
      )
      if (!wizardTitle && name) Object.assign(payload, { title: name })
      if (!wizardEditorStyles && styles) Object.assign(payload, { styles })
      if (!wizardDataStructure && dataStructure) Object.assign(payload, { dataStructure })
      if (!wizardLocations && locations) Object.assign(payload, { locations })
      if (!wizardQuestions && questions) Object.assign(payload, { questions })
      if (!wizardQuestionLayout && questionLayout) Object.assign(payload, { questionLayout })
      if (!wizardLanguages && languages)
        Object.assign(payload, { languages: Object.assign({}, languages, { selected: selectedLanguages?.slice() || [] }) })
      if (!wizardIntegrationEntries && integrationEntries) Object.assign(payload, { integrationEntries })
      if (!wizardExternalAPIs && externalAPIs) Object.assign(payload, { externalAPIs: integrationValues || externalAPIs })
      if (!wizardSplits && splits) Object.assign(payload, { splits })
      if (!wizardAnswers && answers) Object.assign(payload, { answers: JSON.parse(JSON.stringify(answers)) })
      if (Object.keys(payload).length) updateWizardState(payload)
    }
  }, [
    isSettled,
    template,
    wizardInitialized,
    wizardTitle,
    wizardEditorStyles,
    wizardDataStructure,
    wizardLocations,
    wizardQuestions,
    wizardQuestionLayout,
    wizardLanguages,
    selectedLanguages,
    wizardIntegrationEntries,
    integrationEntries,
    wizardExternalAPIs,
    integrationValues,
    wizardSplits,
    wizardAnswers,
    answers,
    updateWizardState,
  ])
  // ======================================================================================= //

  const hideRightPane = useMemo(
    () => wizardMode === DOCUMENT_FLOW_MODES.TEMPLATE_SELECT || (directory === 'public' && !publicSettings?.previewAvailable),
    [wizardMode, directory, publicSettings]
  )

  useEffect(() => {
    return resetWizard
  }, [resetWizard])

  // console.groupEnd()

  return (
    <main
      className={classes.wrapper}
      data-loading={isLoading ? '' : undefined}
      data-show-header
      data-show-left-pane
      data-show-right-pane={!hideRightPane ? '' : undefined}
      data-mode={mode || undefined}
    >
      <WizardLayoutContextProvider publicFlow={directory === 'public'}>
        {isLoading ? <Spinner /> : null}
        {!isLoading ? <Header /> : null}
        {!isLoading ? <LeftPane /> : null}
        {!(isLoading || hideRightPane) ? <RightPane key={wizardTemplateId} /> : null}
      </WizardLayoutContextProvider>
    </main>
  )
})

WizardLayout.displayName = 'WizardLayout'

export default WizardLayout
