import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENT_STATUSES, DOCUMENTS_FILTERS, IDocument } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type SubmitDocumentVariables = { id: string; publicFlow?: boolean; updateCategory?: string }
const submitDocumentMutationFunction = (variables: SubmitDocumentVariables) => documentsAPI.submitDocument(variables.id, variables.publicFlow)

export const useSubmitDocument = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const documentSubmitMutation = useMutation<IDocument, unknown, SubmitDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []),
    submitDocumentMutationFunction,
    {
      onMutate: variables => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument & {
          mutationId?: string
          original: IDocument
        }
        const mutationId = uuid()
        if (currentDocument) {
          const originalDocument = currentDocument.original || currentDocument
          const optimisticDocument = Object.assign({}, originalDocument, { status: DOCUMENT_STATUSES.LOCKED, mutationId, original: originalDocument })
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []), optimisticDocument)
          if (!publicFlow && variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, optimisticDocument)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument & {
          mutationId?: string
          original: IDocument
        }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []), currentDocument.original)
          if (!publicFlow && variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, currentDocument.original)
            )
        }
      },
      onSuccess: (document, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument & {
          mutationId?: string
          original: IDocument
        }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []), document)
          if (!publicFlow && variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, document)
            )
        }
      },
      // onSettled: async (document, error, variables, context) => {
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //     if (!publicFlow && variables.category)
      //     const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? [])
      //     queryClient.cancelQueries(queryKey) // async
      //     queryClient.invalidateQueries(queryKey) // async
      //     queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   }
      //   // =========================================================================================== //
      // },
    }
  )

  const submitMutationFunction = (
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutateOptions<IDocument, unknown, SubmitDocumentVariables, { mutationId: string }>
  ) => documentSubmitMutation.mutate({ id: id!, publicFlow, updateCategory: category }, options)
  return { submit: submitMutationFunction, submitting: documentSubmitMutation.isLoading }
}

export type SubmitDocumentFunctionType = (
  category?: string,
  options?: MutateOptions<IDocument, unknown, SubmitDocumentVariables, { mutationId: string }>
) => void

export default useSubmitDocument
