import React, { FunctionComponent, ReactNode, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import useStore, { DocumentsFilterSelector, SelectedDocumentIdSelector, SetSelectedDocumentIdAction } from '___store'

import { DOCUMENT_DIRECTORY, DOCUMENTS_FILTERS } from '___types'
import { useDocumentList } from '___hooks'
import { Button, Grid, DocumentThumbnail } from 'components/CasusComponents'
import { MainLayoutContentDocumentsProps, mainLayoutContentDocumentsClasses as classes } from '../../..'

type UseStoreHookResultType = {
  documentsFilter: DocumentsFilterSelector
  selectedDocumentId: SelectedDocumentIdSelector
  setSelectedDocumentId: SetSelectedDocumentIdAction
}

export const Documents: FunctionComponent<MainLayoutContentDocumentsProps> = React.memo(() => {
  const history = useHistory()
  const { documentsFilter, selectedDocumentId, setSelectedDocumentId } = useStore(
    'selectDocumentsFilter',
    'selectSelectedDocumentId',
    'setSelectedDocumentId'
  ) as UseStoreHookResultType

  const categoryId = documentsFilter.slice(-1)[0]

  const {
    list: { data, isLoading, isFetching, error, status, hasNextPage, isFetchingNextPage, fetchNextPage },
  } = useDocumentList(categoryId)

  const errorMessage = useMemo(() => {
    if (typeof error === 'string') return error
    if (error instanceof Error) return error.message
    return ''
  }, [error])

  const documents = useMemo(
    () =>
      (
        (documentsFilter[0] === DOCUMENTS_FILTERS.MINE
          ? [
              <DocumentThumbnail
                key={`MainLayout-Components-Documents-Thumbnail-new`}
                folderId={categoryId}
                onClick={() => history.push(`/${DOCUMENT_DIRECTORY}`)}
              />,
            ]
          : []) as ReactNode[]
      ).concat(
        data?.pages
          .flat(1)
          .map(document => (
            <DocumentThumbnail
              key={`MainLayout-Components-Documents-Thumbnail-${document?.id}`}
              id={document?.id}
              type={document?.type}
              selected={document?.id === selectedDocumentId}
              onClick={() => setSelectedDocumentId(document?.id!)}
              name={document?.name}
              edited={document?.edited}
              isPublic={document?.isPublic}
              beingDeleted={document?.beingDeleted}
            />
          )) || []
      ),
    [documentsFilter, categoryId, history, data, selectedDocumentId, setSelectedDocumentId]
  )

  const buttonLabel = useMemo(() => {
    if (status === 'error') return 'Error loading documents'
    if (status === 'loading') return 'Loading...'
    if (isFetchingNextPage) return 'Loading more...'
    return 'Load more'
  }, [status, isFetchingNextPage])

  return (
    <div className={classes.wrapper}>
      <Grid isLoading={isLoading} isFetching={isFetching} error={errorMessage}>
        {documents.length ? documents : <span>No available documents</span>}
      </Grid>
      {hasNextPage ? (
        <Button
          onClick={() => fetchNextPage()}
          onClickBlur
          disabled={isFetchingNextPage}
          loading={status === 'loading' || isFetchingNextPage}
          showSpinner
        >
          {buttonLabel}
        </Button>
      ) : null}
    </div>
  )
})

Documents.displayName = 'MainLayout-Components-Documents'

export default Documents
