import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'
import { WizardState, generateAnswerRelevance, generateQuestionOrder } from '___store'

import { Answers, DocumentProgress, DOCUMENTS_FILTERS, IDocument, ITemplate, PartialDocument, QuestionLayout, Questions } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

const generateDocumentProgress = (questionLayout: QuestionLayout, questions: Questions, answers?: Answers) => {
  const pseudoState = { questions: questions, answers: answers } as WizardState
  const answerRelevance = generateAnswerRelevance(pseudoState)
  const questionOrder = generateQuestionOrder(questionLayout, questions, answerRelevance)
  return questionOrder.reduce((result, questionOrderEntry) => {
    const questionId = questionOrderEntry.split(':')[1]
    const question = questions.find(({ id }) => id === questionId)
    if (!question) return result
    const key = question.private ? 'private' : 'public'
    const resultingQuestionCount = (result[key]?.questions || 0) + 1
    const resultingAnsweredCount = (result[key]?.answered || 0) + Number(Boolean((answers || []).find(({ id }) => id === questionId)))
    Object.assign(result, { [key]: { questions: resultingQuestionCount, answered: resultingAnsweredCount } })
    return result
  }, {} as DocumentProgress)
}

export type UpdateDocumentVariables = PartialDocument & { id: string } & { publicFlow?: boolean; updateCategory?: string }
const updateDocumentMutationFunction = (variables: UpdateDocumentVariables) => {
  const payload = Object.assign({}, variables)
  delete payload.publicFlow
  delete payload.updateCategory
  return documentsAPI.updateDocument(payload, variables.publicFlow)
}

export const useUpdateDocument = (id?: string | null, publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const documentUpdateMutation = useMutation<IDocument | undefined, unknown, UpdateDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []),
    updateDocumentMutationFunction,
    {
      onMutate: variables => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument & {
          mutationId?: string
          original: IDocument
        }
        const mutationId = uuid()
        if (currentDocument) {
          const originalDocument = currentDocument.original || currentDocument
          const optimisticDocument = Object.assign({}, originalDocument, variables, { mutationId, original: originalDocument })
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []), optimisticDocument)
          if (!publicFlow && variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, optimisticDocument)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument & {
          mutationId?: string
          original: IDocument
        }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []), currentDocument.original)
          if (!publicFlow && variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, currentDocument.original)
            )
        }
      },
      onSuccess: (document, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument & {
          mutationId?: string
          original: IDocument
        }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : []), document)
          if (!publicFlow && variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, entry => entry.id === id, document!)
            )
        }
      },
    }
  )

  const updateMutationFunction = (
    document: PartialDocument,
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutateOptions<IDocument | undefined, unknown, UpdateDocumentVariables, { mutationId: string }>
  ) => {
    if (document.answers) {
      const originalDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id].concat(publicFlow ? 'public' : [])) as IDocument
      const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, originalDocument.templateId]) as ITemplate
      if (template) {
        const { questionLayout, questions } = template
        Object.assign(document, { progress: generateDocumentProgress(questionLayout, questions, document.answers) })
      }
    }
    return documentUpdateMutation.mutate(Object.assign(document, { id: id!, publicFlow, updateCategory: category }), options)
  }
  return { update: updateMutationFunction, updating: documentUpdateMutation.isLoading }
}

export type UpdateDocumentFunctionType = (
  document: PartialDocument,
  category?: string,
  options?: MutateOptions<IDocument | undefined, unknown, UpdateDocumentVariables, { mutationId: string }>
) => void

export default useUpdateDocument
