import React from 'react'
import { classes } from '.'
import { useTranslation } from 'react-i18next'
import EmptyTemplatesIllustration from 'assets/illustrations/emptyState.svg'
import Button from 'components/CasusComponents/Button'
import { useHistory } from 'react-router-dom'
import { openIntercomChat } from 'components/helpers/intercom/services/openIntercomChat'
import { isTenant } from '___utilities/___general'

const EmptyTemplates: React.FC = () => {
  const { t } = useTranslation()
  const { push } = useHistory()

  // Tenants
  const isSBV = isTenant('sbv', window.location.host)

  return (
    <div className={classes.empty.wrapper}>
      <div className={classes.empty.leftWrapper}>
        <div className={classes.empty.textWrapper}>
          <span className={classes.empty.title}>{isSBV ? t('sbv_empty_state_newdoc_title') : t('empty_state_newdoc_title')}</span>
          <span className={classes.empty.description}>{isSBV ? t('sbv_empty_state_newdoc_description') : t('empty_state_newdoc_description')}</span>
          <div className={classes.empty.buttonsGroup}>
            <Button className={classes.empty.button} primary onClick={() => push('/documents/configure')}>
              {t('browse_templates')}
            </Button>
            <Button className={classes.empty.button} onClick={() => openIntercomChat()}>
              {t('need_help')}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.empty.paperWrapper}>
        <img alt="document" src={EmptyTemplatesIllustration} />
      </div>
    </div>
  )
}

export default EmptyTemplates

