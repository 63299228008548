import {
  DOCUMENTS_FILTERS,
  DocumentsFilter,
  Answer,
  Answers,
  IDocument,
  PartialDocument,
  mapDocumentsFilter,
  QuestionnaireOutputEntry,
  DocumentDownloadFormat,
  IDocumentFolder,
  Signee,
  SignatureSecurityLevel,
  Segment,
} from '___types'

import API from './api'
import { FILE_NAME_REGEX } from '___api'

export const DOCUMENT_LIST_PER_PAGE = 10

const parseDocument = (document: any) => {
  const parsedAnswers = (document.v3Answers
    ?.map((answer: any) => {
      if (!answer.questionId) return null
      const parsed = Object.assign({}, answer, { id: answer.questionId })
      delete parsed.questionId
      return parsed
    })
    .filter((a: Answer | null) => a) || []) as Answers
  Object.assign(document, { answers: parsedAnswers || [] })
  delete document.v3Answers
  Object.assign(document, { signatureSecurityLevel: document.signatureLevel })
  delete document.signatureLevel
}

class Documents extends API {
  public constructor() {
    super('documents/')
  }

  public getDocumentList = async (categoryId?: string | null, lastDocumentId?: string | null): Promise<IDocument[] | undefined> => {
    if (!categoryId) return
    const params = new URLSearchParams({ limit: String(DOCUMENT_LIST_PER_PAGE) })
    if (lastDocumentId) params.set('startAfter', lastDocumentId)
    let res = undefined
    if (Object.values(DOCUMENTS_FILTERS).includes(categoryId as DocumentsFilter))
      res = await this.get(`list/${mapDocumentsFilter(categoryId)}?${String(params)}`, undefined, 'v1')
    else
      res = await this.post(
        `list/${mapDocumentsFilter(DOCUMENTS_FILTERS.MINE)}/category?${String(params)}`,
        { category: categoryId },
        undefined,
        'v1'
      ) // update path to documents/folders/list (remove the override)
    return res.data.data.documents as IDocument[]
  }

  public getDocumentFolderList = async (filter?: string | null, folderId?: string): Promise<IDocumentFolder[] | undefined> => {
    return // document categories are not implemented on BE
    // if (!filter) return
    // let res = undefined as any
    // if (!folderId) res = await this.get(`list/${mapDocumentsFilter(filter)}`, undefined, 'v1', 'documentCategories')
    // else
    //   res = await this.post(
    //     `list/${mapDocumentsFilter(filter)}${folderId ? '/category' : ''}`,
    //     { category: folderId },
    //     undefined,
    //     'v1',
    //     'documentCategories'
    //   ) // update path to documents/folders/list/mine (remove the override)
    // // update path to documents/folders/list/mine (remove the override)
    // const folders = res.data.data.folders as IDocumentFolder[]
    // /////////////////////////////////////////////////////////////////////////// //
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // /////////////////////////////////////////////////////////////////////////// //
    // // remove parsing when BE updates categories to folders
    // folders.forEach(folder => {
    //   //@ts-ignore
    //   Object.assign(folder, { parentFolderId: folder.parentCategoryId })
    //   //@ts-ignore
    //   delete folder.parentCategoryId
    // })
    // return folders
  }

  public getDocument = async (id?: string | null, publicFlow: boolean = false): Promise<IDocument | undefined> => {
    if (!id) return
    const res = await this.get(`${publicFlow ? 'public/' : ''}${id}`)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public createDocument = async (document: PartialDocument, category: string = DOCUMENTS_FILTERS.MINE, publicFlow: boolean = false) => {
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // remove parsing when response fixes v3Answers => answers
    const payload = Object.assign({
      new: true,
      templateId: document.templateId,
      name: document.name,
      languages: document.languages,
      integrationEntries: document.integrationEntries,
      externalAPIs: document.externalAPIs,
      v3Answers: document.answers?.map(({ id, values }) => ({ questionId: id, values })) || [],
      progress: document.progress,
      category: category === DOCUMENTS_FILTERS.MINE ? [] : category, // convert [] to "" when BE updates the type
    })
    const res = await this.post(publicFlow ? 'public' : '', payload)
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public uploadPDFDocument = async (base64data: string, filename: string, category: string = DOCUMENTS_FILTERS.MINE) => {
    const payload = {
      base64data,
      filename,
      category: category === DOCUMENTS_FILTERS.MINE ? [] : category, // convert [] to "" when BE updates the type
    }
    const res = await this.post(`documentsPDF`, payload, undefined, 'v1')
    return res.data.data as IDocument
  }

  public updateDocument = async (document: PartialDocument, publicFlow: boolean = false) => {
    if (!document.id) return
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    // remove parsing when response fixes v3Answers => answers
    const payload = Object.assign({}, document)
    if (document.answers?.length) {
      Object.assign(payload, { v3Answers: document.answers?.map(({ id, values }) => ({ questionId: id, values })) || [] })
      delete payload.answers
    }
    const res = await this.patch(`${publicFlow ? 'public/' : ''}${document.id}`, payload)
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public submitDocument = async (id: string, publicFlow: boolean = false) => {
    const res = await this.post(`${publicFlow ? 'public/' : ''}${id}/next`)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public publishDocument = async (id: string, payload: Record<string, unknown>) => {
    const res = await this.post(`${id}/publish`, payload)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data.document as IDocument
  }

  public approveDocument = async (id: string) => {
    if (!id) return
    const res = await this.post(`${id}/approve`)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public requestDocumentSignature = async (
    id: string,
    paragraphs: Segment[],
    securityLevel: SignatureSecurityLevel,
    // legislation: string,
    signees: Signee[],
    message?: string,
    splitId?: string | null,
    publicFlow: boolean = false
  ) => {
    const payload = {
      documentId: id, // documentId => id
      paragraphs,
      quality: securityLevel,
      legislation: 'ZERTES',
      signatureEntities: signees, // signatureEntities => signees
      message,
      splitId,
      requireSkribbleAccount: false,
    }
    const res = await this.post(`${publicFlow ? 'public/' : ''}${id}/signature`, payload)
    // ///////////////////////////// V3 ROLLOUT TODO ///////////////////////////// //
    parseDocument(res.data.data)
    // /////////////////////////////////////////////////////////////////////////// //
    return res.data.data as IDocument
  }

  public downloadDocument = async (
    id: string,
    payload: { storageId: string; paragraphs: unknown[] },
    format: DocumentDownloadFormat = 'docx',
    publicFlow: boolean = false
  ) => {
    if (!id) return
    const res = await this.post(`${publicFlow ? 'public/' : ''}${id}/export/${format}`, payload, { responseType: 'blob' })
    const { headers, data } = res
    const filename = headers['content-disposition']?.match(FILE_NAME_REGEX)?.groups?.filename || ''
    const link = window.document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([data]))
    link.setAttribute('download', filename)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }

  public downloadSignedDocument = async (id: string, split?: string | null, publicFlow: boolean = false) => {
    if (!id) return
    const res = await this.get(`${publicFlow ? 'public/' : ''}${id}${split ? `/${split}` : ''}/signature/pdf/export`, { responseType: 'arraybuffer' })
    const { headers, data } = res
    const filename = headers['content-disposition']?.match(FILE_NAME_REGEX)?.groups?.filename || ''
    const link = window.document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([data]))
    link.setAttribute('download', filename)
    window.document.body.appendChild(link)
    link.click()
    window.document.body.removeChild(link)
  }

  public uploadToBamboo = async (id: string, payload: { storageId: string; paragraphs: unknown[] }) => {
    if (!id) return
    const res = await this.post('external/services/employee/upload', payload, { responseType: 'blob' }, 'v1', 'users/')
    return res
  }

  public duplicateDocument = async (id: string) => {
    const res = await this.post(`${id}/duplicate`)
    return res.data.data as IDocument
  }

  public removeDocument = async (id: string) => {
    await this.patch(id, { status: 'trashed' })
    return
  }

  public generateDocumentTitle = async (payload: { templateName: string; questionnaireOutput: QuestionnaireOutputEntry[] }) => {
    const res = await this.post('ai/name', { templateName: payload.templateName, answers: payload.questionnaireOutput })
    return res.data.data.documentName
  }
}

export const documentsAPI = new Documents()

export default documentsAPI
