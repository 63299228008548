import React, { FunctionComponent } from 'react'

import { TEMPLATES_FILTERS } from '___types'
import FolderTree from './FolderTree'
import { MainLayoutLeftPaneTemplatesSharedProps, mainLayoutLeftPaneTemplatesSharedClasses as classes } from '../../..'

export const Shared: FunctionComponent<MainLayoutLeftPaneTemplatesSharedProps> = React.memo(() => (
  <FolderTree className={classes.wrapper} directory="templates" id={TEMPLATES_FILTERS.SHARED} name="Shared templates" />
))

Shared.displayName = 'MainLayout-LeftPane-Templates-Shared'

export default Shared
