import Configuration from './Configuration'

import './style.scss'

const classes = {
  wrapper: 'Wizard-Configuration-wrapper',
  content: 'Wizard-Configuration-content',
  header: 'Wizard-Configuration-header',
  headerText: 'Wizard-Configuration-headerText',
  headerCollapseButton: 'Wizard-Configuration-headerCollapseButton',
  entries: 'Wizard-Configuration-entries',
  configEntry: {
    wrapper: 'Wizard-Configuration-ConfigEntry-wrapper',
    footer: 'Wizard-Configuration-ConfigEntry-footer',
    questionGeneration: {
      wrapper: 'Wizard-Configuration-QuestionGeneration-wrapper',
      groupList: 'Wizard-Configuration-QuestionGeneration-groupList',
      group: {
        wrapper: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-wrapper',
        header: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-header',
        input: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-input',
        button: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-button',
        actionSelect: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-actionSelect',
        actionSelectOption: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-actionSelectOption',
        content: 'Wizard-Configuration-QuestionGeneration-QuestionLayoutGroup-content',
      },
      listItem: {
        wrapper: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-wrapper',
        row: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-row',
        questionTextSpan: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-questionTextSpan',
        actionSelect: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-actionSelect',
        actionSelectOption: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-actionSelectOption',
        subQuestionsDiv: 'Wizard-Configuration-QuestionGeneration-QuestionListItem-subQuestionsDiv',
      },
    },
    questionConfiguration: {
      wrapper: 'Wizard-Configuration-QuestionConfiguration-wrapper',
      fragment: {
        wrapper: 'Wizard-Configuration-QuestionConfiguration-Fragment-wrapper',
        title: 'Wizard-Configuration-QuestionConfiguration-Fragment-title',
        text: 'Wizard-Configuration-QuestionConfiguration-Fragment-text',
        questionText: {
          input: 'Wizard-Configuration-QuestionConfiguration-QuestionText-input',
        },
        questionOptions: {
          optionGroupList: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-optionGroupList',
          addOptionGroupButton: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-addOptionGroupButton',
          optionGroup: {
            wrapper: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-wrapper',
            header: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-header',
            content: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-content',
            footer: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-footer',
            selectSwitch: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-selectSwitch',
            numberInput: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-numberInput',
            actionButton: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-actionButton',
            option: {
              wrapper: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-wrapper',
              input: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-input',
              actionSelect: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-actionSelect',
              actionSelectOption: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-actionSelectOption',
              subQuestionsDiv: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-subQuestionsDiv',
              subQuestion: {
                wrapper: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-OptionGroup-Option-SubQuestion-wrapper',
              },
            },
          },
          // buttonGroup
          // questionOptionsButtonGroup: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-buttonGroup',
          // questionOptionsButton: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-button',
          // questionOptionsSelect: 'Wizard-Configuration-QuestionConfiguration-QuestionOptions-select',
        },
        questionDetails: {
          input: 'Wizard-Configuration-QuestionConfiguration-QuestionDetails-input',
        },
      },
    },
    optionConfiguration: {
      wrapper: 'Wizard-Configuration-OptionConfiguration-wrapper',
      fragment: {
        wrapper: 'Wizard-Configuration-OptionConfiguration-Fragment-wrapper',
        title: 'Wizard-Configuration-OptionConfiguration-Fragment-title',
        text: 'Wizard-Configuration-OptionConfiguration-Fragment-text',
        placeholderInput: {
          input: 'Wizard-Configuration-OptionConfiguration-PlaceholderInput-input',
          switch: 'Wizard-Configuration-OptionConfiguration-PlaceholderInput-switch',
        },
        valueInput: {
          input: 'Wizard-Configuration-OptionConfiguration-ValueInput-input',
          select: 'Wizard-Configuration-OptionConfiguration-ValueInput-select',
        },
        textInput: {
          input: 'Wizard-Configuration-OptionConfiguration-TextInput-input',
          switch: 'Wizard-Configuration-OptionConfiguration-TextInput-switch',
        },
        limits: {
          input: 'Wizard-Configuration-OptionConfiguration-Limits-input',
        },
      },
    },
    markerConfiguration: {
      wrapper: 'Wizard-Configuration-MarkerConfiguration-wrapper',
      fragment: {
        wrapper: 'Wizard-Configuration-MarkerConfiguration-Fragment-wrapper',
        title: 'Wizard-Configuration-MarkerConfiguration-Fragment-title',
        text: 'Wizard-Configuration-MarkerConfiguration-Fragment-text',
        connect: {
          tabs: {
            wrapper: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-wrapper',
            header: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-header',
            content: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-content',
            accordion: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-accordion',
            expandButton: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-expandButton',
            valueSources: {
              wrapper: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-valueSources-wrapper',
              entry: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-valueSources-entry',
              question: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-valueSources-question',
              external: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-valueSources-external',
              computed: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-valueSources-computed',
            },
            conditionals: {
              wrapper: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-conditionals-wrapper',
              languages: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-conditionals-languages',
              splits: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-conditionals-splits',
              entry: 'Wizard-Configuration-MarkerConfiguration-connect-tabs-conditionals-entry',
            },
          },
        },
        output: {
          wrapper: 'Wizard-Configuration-MarkerConfiguration-output-wrapper',
          switch: 'Wizard-Configuration-MarkerConfiguration-output-switch',
          concatStringOptions: 'Wizard-Configuration-MarkerConfiguration-output-concatStringOptions',
          calculationInput: 'Wizard-Configuration-MarkerConfiguration-output-calculationInput',
        },
        attributes: {
          wrapper: 'Wizard-Configuration-MarkerConfiguration-attributes-wrapper',
          colors: 'Wizard-Configuration-MarkerConfiguration-attributes-colors',
        },
        mapping: {
          wrapper: 'Wizard-Configuration-MarkerConfiguration-mapping-wrapper',
          inputWrapper: 'Wizard-Configuration-MarkerConfiguration-mapping-inputWrapper',
          valueInput: 'Wizard-Configuration-MarkerConfiguration-mapping-valueInput',
          mapInput: 'Wizard-Configuration-MarkerConfiguration-mapping-mapInput',
          mapEntry: 'Wizard-Configuration-MarkerConfiguration-mapping-mapEntry',
        },

        generateQuestionButton: 'Wizard-Configuration-MarkerConfiguration-generateQuestionButton',
        questionAssignSelect: 'Wizard-Configuration-MarkerConfiguration-questionAssignSelect',
        currentQuestionButtonGroup: 'Wizard-Configuration-MarkerConfiguration-currentQuestionButtonGroup',
        assignToCurrentQuestionButton: 'Wizard-Configuration-MarkerConfiguration-assignToCurrentQuestionButton',
        assignToOptionButton: 'Wizard-Configuration-MarkerConfiguration-assignToOptionButton',
        modifiers: 'Wizard-Configuration-MarkerConfiguration-modifiers',
        modifierEntry: 'Wizard-Configuration-MarkerConfiguration-modifierEntry',
      },
    },
    questionnaire: {
      wrapper: 'Wizard-Configuration-Questionnaire-wrapper',
      content: 'Wizard-Configuration-Questionnaire-content',
      scrollBuffer: 'Wizard-Configuration-Questionnaire-scrollBuffer',
      questionFade: 'Wizard-Configuration-Questionnaire-questionFade',
      actions: {
        buttonGroup: 'Wizard-Configuration-Questionnaire-Actions-buttonGroup',
        innerButtonGroup: 'Wizard-Configuration-Questionnaire-Actions-innerButtonGroup',
        button: 'Wizard-Configuration-Questionnaire-Actions-button',
      },
      separator: {
        wrapper: 'Wizard-Configuration-Questionnaire-Separator-wrapper',
      },
      quetionGroup: {
        wrapper: 'Wizard-Configuration-Questionnaire-QuestionGroup-wrapper',
        label: 'Wizard-Configuration-Questionnaire-QuestionGroup-label',
        actionsDiv: 'Wizard-Configuration-Questionnaire-QuestionGroup-actionsDiv',
      },
      questionListItem: {
        wrapper: 'Wizard-Configuration-Questionnaire-QuestionListItem-wrapper',
        text: 'Wizard-Configuration-Questionnaire-QuestionListItem-text',
        description: 'Wizard-Configuration-Questionnaire-QuestionListItem-description',
        example: 'Wizard-Configuration-Questionnaire-QuestionListItem-example',
        hint: 'Wizard-Configuration-Questionnaire-QuestionListItem-hint',
        optionGroup: {
          wrapper: 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-wrapper',
          // label: 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-label',
          limitText: 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-limitText',
          option: {
            wrapper: 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-QuestionOption-wrapper',
            selectComponent: 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-QuestionOption-selectComponent',
            input: 'Wizard-Configuration-Questionnaire-QuestionListItem-QuestionOptionGroup-QuestionOption-input',
          },
        },
      },
    },
  },
}

export { Configuration, classes }
export default Configuration
