import { AppState } from '.'

export type SetTemplatesFilterPayload = string | [string, string]
const setTemplatesFilter = (state: AppState, payload: SetTemplatesFilterPayload): AppState => {
  const index = state.templates.filter.indexOf(typeof payload === 'string' ? payload : payload[0])
  const resultingFilter = (index === -1 ? [] : state.templates.filter.slice(0, index)).concat(payload)
  if (!payload || resultingFilter.join('; ') === state.templates.filter.join('; ')) return state
  return Object.assign(state.templates, { filter: resultingFilter, selected: null }) && Object.assign({}, state)
}

export type SetSelectedTemplateIdPayload = string
const setSelectedTemplateId = (state: AppState, payload: SetSelectedTemplateIdPayload): AppState =>
  !payload || payload === state.templates.selected ? state : Object.assign(state.templates, { selected: payload }) && Object.assign({}, state)

const deselectAllTemplates = (state: AppState): AppState =>
  !state.templates.selected ? state : Object.assign(state.templates, { selected: null }) && Object.assign({}, state)

export { setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates }
