import React, { FunctionComponent, useState } from 'react'

import { Caret } from 'assets/svgIconComponents'
import { Button, CheckBox } from 'components/CasusComponents'
import { MainLayoutRightPaneTemplatePublishingProps, mainLayoutRightPaneTemplatePublishingClasses as classes } from '../../../..'

export const Publishing: FunctionComponent<MainLayoutRightPaneTemplatePublishingProps> = React.memo(({ link }) => {
  const [collapsed, setCollapsed] = useState(true)
  const [signature, setSignature] = useState(false)
  const [preview, setPreview] = useState(false)

  return (
    <div className={classes.wrapper} data-collapsed={collapsed ? '' : undefined}>
      <span className={classes.title}>
        Publishing
        <Button onClick={() => setCollapsed(prev => !prev)} onClickBlur>
          {collapsed ? 'Publish' : <Caret />}
        </Button>
      </span>
      {!collapsed ? (
        <>
          <div className={classes.form}>
            <CheckBox value={signature} label="Signature" onChange={toggle => setSignature(toggle)} />
            <CheckBox value={preview} label="Preview" onChange={toggle => setPreview(toggle)} />
            <Button onClick={() => console.log('PUBLISH!')} tertiary>
              Publish
            </Button>
          </div>
        </>
      ) : null}
    </div>
  )
})

Publishing.displayName = 'MainLayout-RightPane-Template-Publishing'

export default Publishing
