import React, { FunctionComponent, useCallback, useMemo } from 'react'
import useStore, { WizardApproverListSelector, AddApproverAction } from '___store'

import { Author } from '___types'
import { useTeam, useUser } from '___hooks'
import { Select } from 'components/CasusComponents'
import Approver from './Approver'
import { classes } from '.'

type UseStoreHookResultType = { wizardApproverList: WizardApproverListSelector; addApprover: AddApproverAction }

export const ApproverList: FunctionComponent = React.memo(() => {
  const { data: user } = useUser()
  const { wizardApproverList, addApprover } = useStore('selectWizardApproverList', 'addApprover') as UseStoreHookResultType

  const {
    members: { data: members },
  } = useTeam()

  const options = useMemo(
    () =>
      (members || [])
        .concat(user as Author)
        .reduce((result, { id, firstName, lastName, email }) => Object.assign(result, { [id]: [firstName, lastName, email].join(' ') }), {}), // Make Label dynamic to work with different integrations / accounts
    [members, user]
  ) as Record<string, string>

  const entryRender = useCallback((id, label) => <Approver key={`Approver-${id}`} id={id} onClickCallback={id => console.log(id)} />, [])

  return (
    <div className={`${classes.section.wrapper} approvals`}>
      <span className={classes.section.title}>APPROVALS</span>
      <Select
        options={options}
        value={wizardApproverList || []}
        placeholder="Add approver(s)"
        searchable
        multiselect
        onSelect={entryId => addApprover(entryId)}
        entryRender={entryRender}
      />
    </div>
  )
})

ApproverList.displayName = ''

export default ApproverList
