import { QueryFunctionContext, useQuery } from 'react-query'

import { TEMPLATES_FILTERS } from '___types'
import { templatesAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'

const getTemplateFolderListQueryFunction = ({ queryKey }: QueryFunctionContext) => templatesAPI.getTemplateFolderList(queryKey[1] as string)

export const useFetchTemplateFolderList = (filter?: string | null) => {
  const templateListQuery = useQuery({
    queryKey: [QUERY_KEYS.TEMPLATE_FOLDERS].concat(filter === undefined ? TEMPLATES_FILTERS.MINE : filter ?? []),
    queryFn: getTemplateFolderListQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return templateListQuery
}

export default useFetchTemplateFolderList
