import React, { useMemo } from 'react'
import useStore from '___store'
import { DateTime } from 'luxon'

import {
  DOCXDocument,
  Docx,
  Duplicate,
  Edit,
  HorizontalLines,
  Move,
  PDFDocument,
  Pdf,
  Reminders,
  Rename,
  Share,
  Signature,
  Trash,
} from 'assets/svgIconComponents'
import { classes } from '.'
import Download from 'assets/svgIconComponents/Download'
import { useDocumentFileActions } from 'hooks/useDocumentFileActions/useDocumentFileActions'
import { useDocumentsDuplicate } from 'queries/documents/useDocumentDuplicate'

const ActionsDropdown = React.memo(({ actions }) => {
  const dropdown = useMemo(() => {
    return (
      <ul className={classes.thumbnail.sideActions.more.dropdownList}>
        {actions.map((action, index) => {
          return (
            <>
              <li
                key={`Document-Thumbnail-sideActions-dropdown-item-${index}`}
                className={classes.thumbnail.sideActions.more.dropdownListItem}
                onClick={action.onClick}
              >
                <action.icon /> {action.label}
              </li>
              {action.dividerBelow && <li className={classes.thumbnail.sideActions.more.dropdownListItemDivider} />}
            </>
          )
        })}
      </ul>
    )
  }, [actions])
  return (
    <div className={classes.thumbnail.sideActions.more.wrapper}>
      <button className={classes.thumbnail.sideActions.more.button} type="button" onClick={() => {}}>
        <HorizontalLines />
      </button>
      {dropdown}
    </div>
  )
})

export const Thumbnail = React.memo(props => {
  const { selectedDocumentId, setSelectedDocumentId } = useStore('selectSelectedDocumentId', 'setSelectedDocumentId')

  const { onDownload, onEdit } = useDocumentFileActions()

  const duplicate = useDocumentsDuplicate()

  const { loading, id, info = {} } = props
  const { title, edited, extension, signees, signed = 0 } = info

  const actions = [
    { icon: Edit, label: 'Edit', onClick: () => onEdit(id), dividerBelow: true },
    { icon: Docx, label: 'Download', onClick: () => onDownload(id, 'docx') },
    { icon: Pdf, label: 'Download', onClick: () => {onDownload(id, 'pdf')}, dividerBelow: true },
    { icon: Share, label: 'Share', onClick: () => {} },
    { icon: Duplicate, label: 'Duplicate', onClick: () => duplicate.mutate(props) },
    { icon: Rename, label: 'Rename', onClick: () => {} },
    { icon: Move, label: 'Move', onClick: () => {}, dividerBelow: true },
    { icon: Reminders, label: 'Reminders', onClick: () => {} },
    { icon: Signature, label: 'e-Sign', onClick: () => {}, dividerBelow: true },
    { icon: Trash, label: 'Delete', onClick: () => {} },
  ]

  return (
    <div
      className={classes.thumbnail.wrapper}
      data-loading={loading}
      data-status={(signees && (signed < signees ? 'pending' : 'signed')) || undefined}
      data-selected={selectedDocumentId === id}
    >
      <button
        className={classes.thumbnail.selectButton}
        type="button"
        tabIndex={loading ? -1 : 0}
        disabled={loading}
        onClick={event => {
          event.target.blur()
          return setSelectedDocumentId(id)
        }}
      />
      <div className={classes.thumbnail.preview}>{extension === 'pdf' ? <PDFDocument /> : <DOCXDocument />}</div>
      <div className={classes.thumbnail.sideActions.wrapper}>
        <button
          className={classes.thumbnail.sideActions.button}
          type="button"
          tabIndex={loading ? -1 : 0}
          disabled={loading}
          onClick={() => onDownload(id, 'docx')}
        >
          <Download />
        </button>
        <ActionsDropdown actions={actions} />
      </div>
      <div className={classes.thumbnail.base.wrapper} data-show-signbar={Boolean(signees)}>
        <pre className={classes.thumbnail.base.title}>{title}</pre>
        <span className={classes.thumbnail.base.relativeTime}>{DateTime.fromMillis(edited?._seconds * 1000).toRelativeCalendar()}</span>
        <div className={classes.thumbnail.base.signbar.wrapper} style={{ '--signed': `${Math.round((signed / signees) * 100)}%` }}>
          <span className={classes.thumbnail.base.signbar.progress}>
            {`${signed} / ${signees}`}
            <Signature />
          </span>
        </div>
      </div>
    </div>
  )
})

Thumbnail.displayName = 'Document-Thumbnail'

export default Thumbnail

