import React from 'react'

import {
  // answersType,
  dataStructureType,
  locationsType,
  numberingSystemType,
  questionsType,
} from 'utilities/propTypes'

import Wizard from './Wizard'
import './style.scss'

const TemplateCreationWizard = React.memo(
  ({
    id,
    title,
    description,
    dataStructure,
    locations,
    cssData,
    numberingSystem,
    questions,
    questionLayout,
    languages,
    approverList,
    externalAPIs,
    externals,
    splits,
    signatureSecurityLevel,
    closeHandler,
    saveHandler,
  }) => (
    <Wizard
      id={id}
      title={title}
      description={description}
      dataStructure={dataStructure}
      locations={locations}
      cssData={cssData}
      numberingSystem={numberingSystem}
      questions={questions}
      questionLayout={questionLayout}
      languages={languages}
      approverList={approverList}
      externalAPIs={externalAPIs}
      externals={externals}
      splits={splits}
      signatureSecurityLevel={signatureSecurityLevel}
      mode="template-creation"
      closeHandler={closeHandler}
      saveHandler={saveHandler}
    />
  )
)
TemplateCreationWizard.displayName = 'TemplateCreation-Wizard'
TemplateCreationWizard.propTypes = {
  dataStructure: dataStructureType(),
  locations: locationsType(),
  // cssData: cssDataType(),
  numberingSystem: numberingSystemType(),
  questions: questionsType(),
}

const DocumentGenerationWizard = React.memo(
  ({ id, title, dataStructure, locations, cssData, numberingSystem, questions, questionLayout, answers, closeHandler, saveHandler }) => (
    <Wizard
      id={id}
      title={title}
      dataStructure={dataStructure}
      locations={locations}
      cssData={cssData}
      numberingSystem={numberingSystem}
      questions={questions}
      questionLayout={questionLayout}
      answers={answers}
      mode="document-generation"
      closeHandler={closeHandler}
      saveHandler={saveHandler}
    />
  )
)
DocumentGenerationWizard.displayName = 'DocumentGeneration-Wizard'
DocumentGenerationWizard.propTypes = {
  dataStructure: dataStructureType(),
  // locations: locationsType(),
  // cssData: cssDataType(),
  // numberingSystem: numberingSystemType(),
  // questions: questionsType(),
  // answers: answersType(true),
}

export { TemplateCreationWizard, DocumentGenerationWizard }
