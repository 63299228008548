import { QueryFunctionContext, useQuery } from 'react-query'

import { documentsAPI } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'
import { DOCUMENTS_FILTERS } from '___types'

const getDocumentFolderListQueryFunction = ({ queryKey }: QueryFunctionContext) =>
  documentsAPI.getDocumentFolderList(queryKey[1] as string, queryKey[2] as string)

export const useFetchDocumentFolderList = (filter: string | null = DOCUMENTS_FILTERS.MINE) => {
  const documentListQuery = useQuery({
    queryKey: [QUERY_KEYS.DOCUMENT_FOLDERS].concat(filter ?? []),
    queryFn: getDocumentFolderListQueryFunction,
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return documentListQuery
}

export default useFetchDocumentFolderList
