import { DOCUMENTS_FILTERS, TEMPLATES_FILTERS } from '___types'

import { resetAppState } from './general'
import { setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments } from './documents'
import { setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates } from './templates'

import { SetDocumentsFilterPayload, SetSelectedDocumentIdPayload } from './documents'
import { SetTemplatesFilterPayload, SetSelectedTemplateIdPayload } from './templates'

export const initialAppState = {
  documents: { filter: [DOCUMENTS_FILTERS.MINE as string], selected: null as string | null },
  templates: { filter: [TEMPLATES_FILTERS.MINE as string], selected: null as string | null },
}
export type AppState = typeof initialAppState
export type { SetDocumentsFilterPayload, SetSelectedDocumentIdPayload }
export type { SetTemplatesFilterPayload, SetSelectedTemplateIdPayload }

export { resetAppState }
export { setDocumentsFilter, setSelectedDocumentId, deselectAllDocuments }
export { setTemplatesFilter, setSelectedTemplateId, deselectAllTemplates }

export default initialAppState
