import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { InfiniteData, MutationOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENTS_FILTERS, IDocument } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryUnshift, infiniteQueryFilter, infiniteQueryReplace } from '___queries'
import { toBase64 } from 'utilities/helpers'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type UploadPDFDocumentVariables = { base64data: string; filename: string; category?: string }
const uploadPDFDocumentMutationFunction = (variables: UploadPDFDocumentVariables) =>
  documentsAPI.uploadPDFDocument(variables.base64data, variables.filename, variables.category)

export const useUploadPDFDocument = () => {
  const queryClient = useQueryClient()
  const generatedId = useMemo(() => uuid(), [])
  const documentUploadPDFMutation = useMutation<IDocument, unknown, UploadPDFDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, generatedId],
    uploadPDFDocumentMutationFunction,
    {
      onMutate: variables => {
        const pseudoDocument = { id: generatedId, name: variables.filename } as IDocument
        queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.category ?? []), (data: InfiniteData<IDocument[]> | undefined) => {
          if (!data) return { pages: [[pseudoDocument]], pageParams: [null] }
          return infiniteQueryUnshift(data, DOCUMENT_LIST_PER_PAGE, Object.assign({}, pseudoDocument, { optimistic: true }) as IDocument)
        })
        return { mutationId: generatedId }
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.category ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
          infiniteQueryFilter(data!, DOCUMENT_LIST_PER_PAGE, ({ id }) => id !== context?.mutationId)
        )
      },
      onSuccess: (document, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.DOCUMENT, document.id], document)
        queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.category ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
          infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, ({ id }) => id === context?.mutationId, document)
        )
      },
      // onSettled: async (document, error, variables, context) => {
      //   queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.id])
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //   const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.category ?? [])
      //   queryClient.cancelQueries(queryKey) // async
      //   queryClient.invalidateQueries(queryKey) // async
      //   queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   // =========================================================================================== //
      // },
    }
  )

  const uploadPDFMutationFunction = async (
    file: File,
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutationOptions<IDocument, unknown, UploadPDFDocumentVariables, { mutationId: string }>
  ) => {
    const base64data = await toBase64(file)
    const filename = file.name
    return documentUploadPDFMutation.mutate({ base64data, filename, category }, options)
  }
  return { uploadPDF: uploadPDFMutationFunction, uploadingPDF: documentUploadPDFMutation.isLoading }
}

export type UploadPDFDocumentFunctionType = (
  file: File,
  category?: string,
  options?: MutationOptions<IDocument, unknown, UploadPDFDocumentVariables, { mutationId: string }>
) => Promise<void>

export default useUploadPDFDocument
