import React, { FunctionComponent, useState, useMemo, useCallback, FormEventHandler, useEffect, Dispatch, SetStateAction, RefCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { ChooseIntegrationEntryAction, WizardExternalAPIsSelector, WizardIntegrationEntriesSelector } from '___store'

import { extractValueFromInputEvent } from 'utilities/helpers'
import { useFetchExternalServiceEntries, useFetchExternalServiceEntry } from '___queries'
import { Cogwheel, Spinner } from 'assets/svgIconComponents'
import { Button, Select, Input } from 'components/CasusComponents'
import {
  WizardLayoutLeftPaneDocumentConfigurationExternalAPIsIntegrationProps,
  wizardLayoutLeftPaneDocumentConfigurationExternalAPIsIntegrationClasses as classes,
} from 'Layouts/WizardLayout'

const INPUT_TYPE_MAP = {
  text: 'multiline',
  date: 'date-time',
  number: 'number',
  // currency: 'currency'
} as const
const VALUE_TYPE_MAP = {
  text: 'string',
  date: 'date',
  number: 'number',
  // currency: 'currency'
} as const

type UseStoreHookResultType = {
  wizardExternalAPIs: WizardExternalAPIsSelector
  wizardIntegrationEntries: WizardIntegrationEntriesSelector
  chooseIntegrationEntry: ChooseIntegrationEntryAction
}

export const Integration: FunctionComponent<WizardLayoutLeftPaneDocumentConfigurationExternalAPIsIntegrationProps> = React.memo(
  ({ id, title, fields, loadingCallback }) => {
    const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })
    const { wizardExternalAPIs, wizardIntegrationEntries, chooseIntegrationEntry } = useStore(
      'selectWizardExternalAPIs',
      'selectWizardIntegrationEntries',
      'chooseIntegrationEntry'
    ) as UseStoreHookResultType
    const [expanded, setExpanded] = useState(false)
    const [overwrite, setOverwrite] = useState({} as Record<string, string>)

    const [wrapper, setWrapper]: [HTMLDivElement | undefined, Dispatch<SetStateAction<HTMLDivElement | undefined>>] = useState()
    const wrapperRef: RefCallback<HTMLDivElement | undefined> = useCallback(node => node && setWrapper(node), [])

    const selectedEntry = useMemo(() => (wizardIntegrationEntries && wizardIntegrationEntries[id]) || '', [wizardIntegrationEntries, id])

    const { data: entries } = useFetchExternalServiceEntries(id)
    const fieldIds = useMemo(() => fields.map(({ id: fieldId }) => fieldId), [fields])
    const { data: entry, isLoading: entryLoading } = useFetchExternalServiceEntry(id, selectedEntry, fieldIds)

    const options = useMemo(
      () => entries?.reduce((result, entry) => Object.assign(result, { [entry.id]: `${entry.firstName} ${entry.lastName}` }), {}) || {}, // Make Label dynamic to work with different integrations / accounts
      [entries]
    ) as Record<string, string>

    const entryOverwriteCallback = useCallback(
      (id, valueType) =>
        (event => {
          const value = extractValueFromInputEvent(valueType, event)
          const payload = Object.assign({}, overwrite, { [id]: value })
          //@ts-ignore
          if (entry[id] === payload[id]) delete payload[id]
          setOverwrite(payload)
        }) as FormEventHandler<HTMLPreElement>,
      [overwrite, entry]
    )

    const entryFields = useMemo(() => {
      if (!entry) return null
      const storeFields = wizardExternalAPIs && wizardExternalAPIs[id]
      return fields.map(({ id: fieldId, name, type }) => {
        const inputType = INPUT_TYPE_MAP[type as keyof typeof INPUT_TYPE_MAP] || 'multiline'
        const valueType = VALUE_TYPE_MAP[type as keyof typeof VALUE_TYPE_MAP] || 'string'
        const storeValue = storeFields?.find(({ id: storeFieldId }) => storeFieldId === fieldId)?.value
        //@ts-ignore
        const value = storeValue || entry[fieldId]
        return (
          <div key={`${id}-${entry.id}-${fieldId}`} className={classes.entries.field} data-label={fieldId}>
            <Input id={fieldId} type={inputType} onInput={entryOverwriteCallback(fieldId, valueType)} defaultValue={value} placeholder={name} />
          </div>
        )
      })
    }, [fields, wizardExternalAPIs, entry, id, entryOverwriteCallback])

    useEffect(() => {
      loadingCallback(id, entryLoading)
    }, [loadingCallback, id, entryLoading])

    return (
      <div id={id} ref={wrapperRef} className={classes.wrapper} data-expanded={expanded ? '' : undefined}>
        <span className={classes.title}>{title}</span>
        <div className={classes.actions}>
          <Select
            options={options}
            value={selectedEntry}
            placeholder={translate(`select-entry-${id}`)}
            searchable
            onSelect={entryId => {
              chooseIntegrationEntry({ integrationId: id, entryId: entryId as string })
              setOverwrite({})
            }}
          />
          <Button
            onClick={() => {
              if (!expanded && wrapper) setTimeout(() => wrapper.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 0)
              setExpanded(prev => !prev)
            }}
            disabled={!entry}
          >
            {entryLoading ? <Spinner /> : <Cogwheel />}
          </Button>
        </div>
        <div className={classes.entries.wrapper}>{entryFields}</div>
      </div>
    )
  }
)

Integration.displayName = 'WizardLayout-LeftPane-Document-Configuration-ExternalAPIs-Integration'

export default Integration
