import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import FolderTree from './FolderTree'
import { MainLayoutLeftPaneDocumentsMineProps, mainLayoutLeftPaneDocumentsMineClasses as classes } from '../../..'

export const Mine: FunctionComponent<MainLayoutLeftPaneDocumentsMineProps> = React.memo(() => (
  <FolderTree className={classes.wrapper} directory="documents" id={DOCUMENTS_FILTERS.MINE} name="My documents" />
))

Mine.displayName = 'MainLayout-LeftPane-Documents-Mine'

export default Mine
