import React, { useMemo } from 'react'
import useStore from '___store'

import { Cogwheel } from 'assets/svgIconComponents'
import { Button as CasusButton } from 'components/CasusComponents'
import { ButtonGroup, Button, Input } from 'components'

import Logo from 'assets/logoDark.svg'
import { useUploadTemplatePreviewImage } from '___queries'

const classes = {
  wrapper: 'Wizard-Header-wrapper',
  header: 'Wizard-Header-header',
  logo: 'Wizard-Header-logo',
  title: 'Wizard-Header-title',
  editButton: 'Wizard-Header-editButton',
  buttonGroup: 'Wizard-Header-buttonGroup',
  button: 'Wizard-Header-button',
  info: 'Wizard-Header-info',
}

const generateImgParams = () => {
  const section = document.querySelector('._casus_section_root')
  if (!section) return
  const tempSection = section.cloneNode()
  const page = section.querySelector('._casus_page_content_root')
  const tempPage = page.cloneNode()
  tempSection.appendChild(tempPage)
  document.body.appendChild(tempSection)
  const pageWidth = Number(getComputedStyle(tempPage).width.replace('px', ''))
  const pageHeight = Number(getComputedStyle(tempPage).minHeight.replace('px', ''))
  tempPage.style.minHeight = `${pageHeight}px`
  tempPage.style.backgroundColor = 'white'
  const childNodes = Array.from(page.childNodes)
  childNodes.some(node => {
    const nodeClone = node.cloneNode(true)
    Array.from(nodeClone.querySelectorAll('._casus_segments_marker_header')).forEach(node => {
      if (!node.parentNode) return
      node.parentNode.removeChild(node)
    })
    tempPage.appendChild(nodeClone)
    return tempPage.scrollHeight > pageHeight
  })
  const outerHTML = tempSection.outerHTML.replaceAll(/&nbsp;/g, ' ')
  document.body.removeChild(tempSection)
  // const sheets = Array.from(document.head.querySelectorAll('style')).filter(sheet =>
  //   sheet.innerHTML.match(/^(.Wizard-Editor-Section-wrapper|.Wizard-Editor-Segment-Paragraph-wrapper|.Wizard-Editor-Marker-Text-wrapper)/)
  // )
  // const styleSheet = document.head.querySelector('style[data-sheet="Wizard-Editor-Style-Sheet"]')
  // if (styleSheet) sheets.push(styleSheet)
  const sheets = Array.from(document.head.querySelectorAll('style'))
  const xmlns = '<div xmlns="http://www.w3.org/1999/xhtml">' + sheets.map(sheet => sheet.outerHTML).join('') + outerHTML + '</div>'
  const uriComponent = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="${pageWidth}" height="${pageHeight}"><foreignObject width="100%" height="100%">${xmlns}</foreignObject></svg>`
  )
  const src = 'data:image/svg+xml,' + uriComponent
  return [src, pageWidth, pageHeight]
}

export const Header = React.memo(props => {
  const {
    // configuringStack = [], // for header breadcrumbs
    dirty,
    title,
    wizardDescription,
    dataStructure,
    locations,
    questions,
    questionLayout,
    wizardLanguages,
    wizardApproverList,
    wizardExternalAPIs,
    wizardSplits,
    wizardSignatureSecurityLevel,
    answers,
    mode,
    setConfiguring,
    updateTitle,
  } = useStore(
    // 'selectConfiguringStack',
    'selectDirty',
    'selectTitle',
    'selectWizardDescription',
    'selectDataStructure',
    'selectLocations',
    'selectQuestions',
    'selectQuestionLayout',
    'selectWizardLanguages',
    'selectWizardApproverList',
    'selectWizardExternalAPIs',
    'selectWizardSplits',
    'selectWizardSignatureSecurityLevel',
    'selectAnswers',
    'selectMode',
    'setConfiguring',
    'updateTitle'
  )

  const { id, onCloseHandler, onSaveHandler } = props

  const uploadImage = useUploadTemplatePreviewImage(id)

  const input = useMemo(
    () =>
      typeof title === 'string' ? (
        <Input
          className={classes.title}
          placeholder="Title goes here..."
          defaultValue={title}
          needsConfirmation={true}
          onChange={value => updateTitle(value)}
          blurShowUnmodified={true}
        />
      ) : null,
    [title, updateTitle]
  )

  return (
    <section className={classes.wrapper}>
      <div className={classes.header}>
        <img src={Logo} alt="logo" className={classes.logo} />
        {input}
        <CasusButton onClick={() => setConfiguring({ key: 'template', id: '' })}>
          <Cogwheel />
          Configure
        </CasusButton>
        <ButtonGroup className={classes.buttonGroup}>
          <Button className={classes.button} onClick={onCloseHandler}>
            {dirty ? 'Discard Changes' : 'Close'}
          </Button>
          {typeof onSaveHandler === 'function' ? (
            <Button
              className={classes.button}
              onClick={() => {
                const [src, width, height] = generateImgParams(id)
                onSaveHandler({
                  title,
                  description: wizardDescription,
                  dataStructure,
                  locations,
                  questions,
                  questionLayout,
                  languages: wizardLanguages,
                  approverList: wizardApproverList,
                  externalAPIs: wizardExternalAPIs,
                  splits: wizardSplits,
                  signatureLevel: wizardSignatureSecurityLevel,
                  answers,
                })
                uploadImage({ src, width, height })
              }}
              colorScheme="primary"
            >
              {mode === 'template-creation' ? 'Save Template' : 'Save Draft'}
            </Button>
          ) : null}
        </ButtonGroup>
      </div>
      <div className={classes.info}>{null}</div>
    </section>
  )
})

Header.displayName = 'Wizard-Header'

export default Header
