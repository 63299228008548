import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { DOCUMENTS_FILTERS, IDocument } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryFilter, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

export type RemoveDocumentVariables = { id: string; updateCategory?: string }
const removeDocumentMutationFunction = (variables: RemoveDocumentVariables) => documentsAPI.removeDocument(variables.id)

export const useRemoveDocument = (id?: string | null) => {
  const queryClient = useQueryClient()
  const documentRemoveMutation = useMutation<void, unknown, RemoveDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, id],
    removeDocumentMutationFunction,
    {
      onMutate: variables => {
        const originalDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string }
        const mutationId = uuid()
        if (originalDocument) {
          const optimisticDocument = Object.assign({}, originalDocument, { mutationId, beingDeleted: true })
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], optimisticDocument)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, ({ id: documentId }) => documentId === id, optimisticDocument)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          const originalDocument = Object.assign({}, currentDocument)
          delete originalDocument.beingDeleted
          queryClient.setQueryData([QUERY_KEYS.DOCUMENT, id], originalDocument)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, ({ id: documentId }) => documentId === id, originalDocument)
            )
        }
      },
      onSuccess: (document, variables, context) => {
        const currentDocument = queryClient.getQueryData([QUERY_KEYS.DOCUMENT, id]) as IDocument & { mutationId?: string }
        if (currentDocument && currentDocument.mutationId === context?.mutationId) {
          queryClient.removeQueries({ queryKey: [QUERY_KEYS.DOCUMENT, id] })
          queryClient.resetQueries({ queryKey: [QUERY_KEYS.DOCUMENT, id] })
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
              infiniteQueryFilter(data!, DOCUMENT_LIST_PER_PAGE, ({ id: documentId }) => documentId !== id)
            )
        }
      },
      // onSettled: async (document, error, variables, context) => {
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //   if (variables) {
      //     const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? [])
      //     queryClient.cancelQueries(queryKey) // async
      //     queryClient.invalidateQueries(queryKey) // async
      //     queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   }
      //   // =========================================================================================== //
      // },
    }
  )

  const removeMutationFunction = (
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutateOptions<void, unknown, RemoveDocumentVariables, { mutationId: string }>
  ) => documentRemoveMutation.mutate({ id: id!, updateCategory: category }, options)
  return { remove: removeMutationFunction, removing: documentRemoveMutation.isLoading }
}

export type RemoveDocumentFunctionType = (
  category?: string,
  options?: MutateOptions<void, unknown, RemoveDocumentVariables, { mutationId: string }>
) => void

export default useRemoveDocument
