import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, DownloadProps, className } from './index'

const ZOOM = 1

const Download: FunctionComponent<DownloadProps> = React.memo(({ extension }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const maskId = useMemo(uuid, [])

  const d = `M 42 ${extension ? '57' : '42'} v 20 h -7 l 15 18 l 15 -18 h -7 v -20 Z`

  return (
    <svg className={`${className} Download`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width={`${VIEWPORT_SIZE}`} height={`${VIEWPORT_SIZE}`} stroke="none" fill="white" />
          <path d={d} stroke="black" strokeWidth="11" fill="black" />
        </mask>
      </defs>
      <path
        d={`M 5 65 v 15 c 0 10 0 10 10 10 h 3 v -${extension ? '40' : '50'} c 0 -15 0 -15 15 -15 h 34 c 15 0 15 0 15 15 v ${
          extension ? '40' : '50'
        } h -67 h 73 c 10 0 10 0 10 -10 v -15`}
        stroke="currentColor"
        strokeWidth="5"
        fill="none"
        mask={`url(#${maskId}`}
      />
      <path d={d} stroke="currentColor" strokeWidth="5" fill="currentColor" />
      {extension ? (
        <text x="50" y="25" fontSize="32" dominantBaseline="text-top" alignmentBaseline="baseline" textAnchor="middle" fill="currentColor">
          {extension.toUpperCase()}
        </text>
      ) : null}
    </svg>
  )
})

export { Download }
export default Download
