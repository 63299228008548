import { AppState } from './helpers'

type LocalStateType = { app: AppState }

export type DocumentsFilterSelector = string[]
export type SelectedDocumentIdSelector = string | null

export type TemplatesFilterSelector = string[]
export type SelectedTemplateIdSelector = string | null

const selectors = {
  // =============================================================== //
  // ========================== DOCUMENTS ========================== //
  // =============================================================== //
  selectDocumentsFilter: (state: LocalStateType): DocumentsFilterSelector => state.app.documents.filter,
  selectSelectedDocumentId: (state: LocalStateType): SelectedDocumentIdSelector => state.app.documents.selected,

  // =============================================================== //
  // ========================== TEMPLATES ========================== //
  // =============================================================== //
  selectTemplatesFilter: (state: LocalStateType): TemplatesFilterSelector => state.app.templates.filter,
  selectSelectedTemplateId: (state: LocalStateType): SelectedTemplateIdSelector => state.app.templates.selected,
}

export { selectors }
export default selectors
