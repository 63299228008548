import { FormEvent } from 'react'
import { DateTime } from 'luxon'

import { BREAK_TAG_MATCH, OPTION_VALUE_TYPES, OptionValueTypeUnionType } from '___types'

type DebounceFunctionType = <T extends (...params: any[]) => any>(callback: T, timeout?: number) => void | ((...params: any[]) => NodeJS.Timeout)
// ) =>  T extends (...params: infer P) => any ? (...params: P) => NodeJS.Timeout : void

export const debounceFunction: DebounceFunctionType = (callback, timeout = 100) => {
  if (typeof callback !== 'function') return
  let debounceTimeout: NodeJS.Timeout
  return (...params: any[]) => {
    window.clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => callback(...params), timeout)
    return debounceTimeout
  }
}

//@ts-ignore
export const throttleFunction: <T>(callback: (params?: T) => unknown, interval?: number) => (params?: T) => undefined = (
  callback,
  interval = 100
) => {
  let throttle = false
  return (...params) => {
    if (throttle) return
    throttle = true
    setTimeout(() => {
      callback(...params)
      throttle = false
    }, interval)
  }
}

export const isObject = (object: unknown) => typeof object === 'object' && !Array.isArray(object) && object !== null
export const unnestObjectValues = (object: Record<keyof any, unknown>): unknown[] =>
  Object.values(object).reduce(
    (result: unknown[], value) => result.concat(isObject(value) ? unnestObjectValues(value as Record<keyof any, unknown>) : value),
    []
  )

export const dataURLToBlob = (dataURL: string) => {
  const binary = atob(dataURL.split(',')[1])
  const array = Array.from(Array(binary.length), (_, i) => binary.charCodeAt(i))
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

const injectFromString = (array: HighlightStringResultEntryType[], string: string, index: number, type: 'text' | 'highlight') => {
  const lastEntry = array.length && array[array.length - 1]
  const value = string.slice(index)
  if (value.length) {
    if (lastEntry && lastEntry.type === type) lastEntry.value = `${value}${lastEntry.value}`
    else array.push({ type, value })
    return string.slice(0, index)
  }
  return string
}

export type HighlightStringResultEntryType = { type: 'text' | 'highlight'; value: string }
export const highlightString = (string: string, indices: [number, number][]) => {
  //@ts-ignore
  const reversed = indices.slice().toReversed()
  let mutatedString = string
  return (
    reversed
      .reduce((result: HighlightStringResultEntryType[], [start, end]: [number, number]) => {
        mutatedString = injectFromString(result, injectFromString(result, mutatedString, end, 'text'), start, 'highlight')
        return result
      }, [] as HighlightStringResultEntryType[])
      .concat({ type: 'text', value: mutatedString })
      //@ts-ignore
      .toReversed()
  )
}

export const filterObjectFields = (object: Record<keyof any, unknown>, ...keys: (string[] | string)[]): Record<keyof any, unknown> => {
  const ignoreList = keys.reduce((acc: string[], cur) => acc.concat(cur), []) // merging string params with string[] params
  return Object.entries(object).reduce(
    (result, [k, v]) => (v === undefined || ignoreList.includes(k) ? result : Object.assign(result, { [k]: v })),
    {}
  )
}

export const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

const VALUE_TYPE_EXTRACT_METHOD_MAP = {
  [OPTION_VALUE_TYPES.STRING]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => {
    const textarea = document.createElement('textarea')
    textarea.innerHTML =
      //@ts-ignore
      ((event.target as HTMLPreElement)?.innerHTML || (event.target as HTMLInputElement)?.value)?.replaceAll(BREAK_TAG_MATCH, '\n') || ''
    const text = textarea.value
    textarea.remove()
    return text
  },
  [OPTION_VALUE_TYPES.DATE]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
    DateTime.fromFormat((event.target as HTMLInputElement)?.value, 'yyyy-MM-dd')?.toISO() || '',
  [OPTION_VALUE_TYPES.DATE_TIME]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
    DateTime.fromFormat((event.target as HTMLInputElement)?.value, "yyyy-MM-dd'T'HH:mm")?.toISO() || '',
  [OPTION_VALUE_TYPES.NUMBER]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => Number((event.target as HTMLInputElement)?.value),
  [OPTION_VALUE_TYPES.CURRENCY]: (event: FormEvent<HTMLPreElement | HTMLInputElement>) => (event.target as HTMLInputElement)?.value || '',
}

export const extractValueFromInputEvent = (valueType: OptionValueTypeUnionType, event: FormEvent<HTMLPreElement | HTMLInputElement>) =>
  typeof VALUE_TYPE_EXTRACT_METHOD_MAP[valueType] === 'function'
    ? VALUE_TYPE_EXTRACT_METHOD_MAP[valueType](event)
    : (event.target as HTMLInputElement)?.value || ''
