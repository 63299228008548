import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { InfiniteData, MutationOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplate, PartialTemplate } from '___types'
import { templatesAPI } from '___api'
import { TEMPLATE_LIST_PER_PAGE } from '___api/api.templates'
import { QUERY_KEYS, infiniteQueryUnshift, infiniteQueryFilter, infiniteQueryReplace } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type CreateTemplateVariables = { template: PartialTemplate & { base64data: string }; updateCategory?: string; publicFlow?: boolean }
const createTemplateMutationFunction = (variables: CreateTemplateVariables) =>
  templatesAPI.createTemplate(variables.template, variables.updateCategory, variables.publicFlow)

export const useCreateTemplate = (publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const generatedId = useMemo(() => uuid(), [])
  const templateCreateMutation = useMutation<ITemplate, unknown, CreateTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, generatedId],
    createTemplateMutationFunction,
    {
      onMutate: variables => {
        queryClient.setQueryData(
          [QUERY_KEYS.TEMPLATES].concat(publicFlow ? 'public' : []).concat(variables.updateCategory ?? []),
          (data: InfiniteData<ITemplate[]> | undefined) => {
            if (!data) return { pages: [[variables.template as ITemplate]], pageParams: [null] }
            return infiniteQueryUnshift(
              data,
              TEMPLATE_LIST_PER_PAGE,
              Object.assign({}, variables.template, { id: generatedId, optimistic: true }) as ITemplate
            )
          }
        )
        return { mutationId: generatedId }
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
          infiniteQueryFilter(data!, TEMPLATE_LIST_PER_PAGE, ({ id }) => id !== context?.mutationId)
        )
      },
      onSuccess: (template, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.TEMPLATE, template.id], template)
        queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
          infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, ({ id }) => id === context?.mutationId, template)
        )
      },
      // onSettled: async (template, error, variables, context) => {
      //   queryClient.removeQueries([QUERY_KEYS.TEMPLATE, context?.id])
      //   // =========================================================================================== //
      //   // ==================================== REFETCH TEMPLATES ==================================== //
      //   // =========================================================================================== //
      //   const queryKey = [QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? [])
      //   queryClient.cancelQueries(queryKey) // async
      //   queryClient.invalidateQueries(queryKey) // async
      //   queryClient.fetchInfiniteQuery({ queryKey, queryFn: getTemplateListQueryFunction }) // async
      //   // =========================================================================================== //
      // },
    }
  )

  const createMutationFunction = (
    template: PartialTemplate & { base64data: string },
    category: string = TEMPLATES_FILTERS.MINE,
    options?: MutationOptions<ITemplate, unknown, CreateTemplateVariables, { mutationId: string }>
  ) => templateCreateMutation.mutate({ template, updateCategory: category, publicFlow }, options)
  return { create: createMutationFunction, creating: templateCreateMutation.isLoading }
}

export type CreateTemplateFunctionType = (
  template: PartialTemplate & { base64data: string },
  category?: string,
  options?: MutationOptions<ITemplate, unknown, CreateTemplateVariables, { mutationId: string }>
) => void

export default useCreateTemplate
