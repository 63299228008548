import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import { VIEWPORT_SIZE, BurgerProps, className } from './index'

const ZOOM = 1

const Burger: FunctionComponent<BurgerProps> = React.memo(({ lines = 4 }) => {
  const viewBox = useMemo(() => {
    const size = VIEWPORT_SIZE / ZOOM
    const center = VIEWPORT_SIZE / 2
    return `${center - size / 2} ${center - size / 2} ${size} ${size}`
  }, [])

  const iconId = useMemo(uuid, [])

  const render = useMemo(
    () =>
      new Array(lines || 3).fill(null).map((_, i) => {
        const y = `${(VIEWPORT_SIZE / lines) * (i + 0.5)}`
        return (
          <line
            key={`Burger-SVG-Icon-${iconId}-${i}`}
            x1="10"
            x2="90"
            y1={y}
            y2={y}
            stroke="currentColor"
            strokeWidth="8"
            shapeRendering="geometricPrecision"
          />
        )
      }),
    [lines, iconId]
  )

  return (
    <svg className={`${className} Burger`} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} preserveAspectRatio="none">
      <g>{render}</g>
    </svg>
  )
})

export { Burger }
export default Burger
