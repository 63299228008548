import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { DateTime } from 'luxon'

import { BREAK_TAG_MATCH, SECURITY_LEVELS, SignatureSecurityLevel, Signee } from '___types'
import { Cogwheel } from 'assets/svgIconComponents'
import { Button, CheckBox, Input, ModalContent as SignatureModalContent } from 'components/CasusComponents'
import useModal from 'Modal'
import {
  MainLayoutRightPaneDocumentPublishingModalContentProps,
  mainLayoutRightPaneDocumentPublishingModalContentClasses as classes,
} from 'Layouts/___MainLayout'

export const ModalContent: FunctionComponent<MainLayoutRightPaneDocumentPublishingModalContentProps> = React.memo(({ conclude, context, splits }) => {
  const [includeEmail, setIncludeEmail] = useState(context?.includeEmail ?? false)
  const [previewAvailable, setPreviewAvailable] = useState(context?.previewAvailable ?? false)
  const [signatureAvailable, setSignatureAvailable] = useState(context?.signatureAvailable ?? false)
  const [expires, setExpires] = useState(context?.expires ?? false)
  const [expirationTime, setExpirationTime] = useState(context?.expirationTime ?? DateTime.now().toISO())
  // const [singleUse, setSingleUse] = useState(context?.singleUse ?? false)
  const [split, setSplit] = useState(context?.split ?? null)

  const [input, setInput] = useState<HTMLInputElement>()
  const ref = useCallback(node => node && setInput(node), [])

  const open = useModal()

  const signeeCount = useMemo(() => (context.signatureConfig?.signees?.length || 0) + Number(includeEmail), [context, includeEmail])
  const securityLevelText = useMemo(() => {
    switch (context.signatureConfig?.security) {
      case SECURITY_LEVELS.BASE:
        return 'Basic'
      case SECURITY_LEVELS.HIGH:
        return 'High'
      case SECURITY_LEVELS.MAX:
        return 'Maximum'
      default:
        return 'Basic'
    }
  }, [context])

  const onConclude = useCallback((context: Record<string, unknown> | undefined, ...params: unknown[]) => {
    const [security, signees, messageInput] = params as [SignatureSecurityLevel, Signee[], HTMLPreElement]
    const textarea = document.createElement('textarea')
    //@ts-ignore
    textarea.innerHTML = messageInput.innerHTML.replaceAll(BREAK_TAG_MATCH, '\n')
    const message = textarea.value
    textarea.remove()
    if (context) Object.assign(context, { security, signees, message })
  }, [])

  const openModal = useCallback(() => {
    if (context)
      Object.assign(context, {
        notifyEmail: input?.value,
        includeEmail,
        previewAvailable,
        signatureAvailable,
        expires,
        expirationTime,
        // singleUse
      })
    open({
      context: context.signatureConfig,
      header: 'Configure signature',
      content: (...params) => <SignatureModalContent conclude={params[2]} context={params[3]} />,
      onConclude,
      footer: false,
    })
  }, [
    context,
    input,
    includeEmail,
    previewAvailable,
    signatureAvailable,
    expires,
    expirationTime,
    // singleUse,
    open,
    onConclude,
  ])

  return (
    <div className={classes.wrapper}>
      <div className={classes.section}>
        <span className={classes.sectionTitle}>Notification</span>
        <span className={classes.sectionDescription}>Add an email that will be notified of this publication.</span>
        <div className={classes.sectionOptions}>
          <Input ref={ref} type="text" defaultValue={context.notifyEmail || ''} placeholder="e-mail" options={{ format: 'email' }} autoselect />
          <CheckBox className={classes.notify.wrapper} value={includeEmail} onChange={setIncludeEmail} label="Include in signature" onClickBlur />
        </div>
      </div>
      <CheckBox
        className={classes.preview.wrapper}
        value={previewAvailable}
        onChange={setPreviewAvailable}
        label={
          <>
            <span className={classes.preview.label}>Preview</span>
            <span className={classes.preview.description}>
              Make this document's preview public, displaying it alongside the questionnaire. The preview allows the user to see the document while
              filling the questionnaire out.
            </span>
          </>
        }
        onClickBlur
      />
      <CheckBox
        className={classes.signature.wrapper}
        value={signatureAvailable}
        onChange={setSignatureAvailable}
        label={
          <>
            <span className={classes.signature.label}>
              Signature
              <span className={classes.signature.configuration}>
                {securityLevelText} Security Signature {signeeCount ? `[${signeeCount}]` : ''}
              </span>
            </span>
            <span className={classes.signature.description}>Enable signature requests in the last step of the public document flow.</span>
          </>
        }
        onClickBlur
      />
      {signatureAvailable ? (
        <Button className={classes.signature.button} onClick={openModal}>
          <Cogwheel />
          Configure signature
        </Button>
      ) : null}
      <CheckBox
        className={classes.expiration.wrapper}
        value={expires}
        onChange={setExpires}
        label={
          <>
            <span className={classes.expiration.label}>Expiration</span>
            <span className={classes.expiration.description}>
              Limit this document's publication. Accessing the public flow for this document will be unavailable after the publication has expired.
            </span>
          </>
        }
        onClickBlur
      />
      {expires ? (
        <Input
          className={classes.expiration.input}
          type="date-time"
          value={expirationTime as string}
          options={{ inputTime: true }}
          onInput={event => setExpirationTime(DateTime.fromFormat((event.target as HTMLInputElement).value, "yyyy-MM-dd'T'HH:mm").toISO()!)}
          autoselect
        />
      ) : null}
      {/* <div className={classes.section}>
        <span className={classes.sectionTitle}>Configuration</span>
        <span className={classes.sectionDescription}>Configure what should be available when accessing this document from the public flow.</span>
        <div className={classes.sectionOptions}>
          <CheckBox
            className={classes.preview.wrapper}
            value={previewAvailable}
            onChange={setPreviewAvailable}
            label={
              <>
                <span className={classes.preview.label}>Preview</span>
                <span className={classes.preview.description}>
                  Make this document's preview public, displaying it alongside the questionnaire. The preview allows the user to see the document
                  while filling the questionnaire out.
                </span>
              </>
            }
            onClickBlur
          />
          <div className={classes.signature.wrapper}>
            <CheckBox
              value={signatureAvailable}
              onChange={setSignatureAvailable}
              label={
                <>
                  <span className={classes.signature.label}>Signature</span>
                  <span className={classes.signature.description}>Enable signature requests in the last step of the public document flow.</span>
                  <span className={classes.signature.configuration}>
                    {securityLevelText} Security Signature {signeeCount ? `[${signeeCount}]` : ''}
                  </span>
                </>
              }
              onClickBlur
            />
            {signatureAvailable ? (
              <Button onClick={openModal}>
                <Cogwheel />
                Configure signature
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <span className={classes.sectionTitle}>Access</span>
        <span className={classes.sectionDescription}>Define how this document will be accessible through the public flow.</span>
        <div className={classes.sectionOptions}>
          <div className={classes.expiration.wrapper}>
            <CheckBox
              value={expires}
              onChange={setExpires}
              label={
                <>
                  <span className={classes.expiration.label}>Expiration</span>
                  <span className={classes.expiration.description}>
                    Limit this document's publication. Accessing the public flow for this document will be unavailable after the publication has
                    expired.
                  </span>
                </>
              }
              onClickBlur
            />
            {expires ? (
              <Input
                type="date-time"
                value={expirationTime as string}
                options={{ inputTime: true }}
                onInput={event => setExpirationTime(DateTime.fromFormat((event.target as HTMLInputElement).value, "yyyy-MM-dd'T'HH:mm").toISO()!)}
                autoselect
              />
            ) : null}
          </div>
          <CheckBox
            className={classes.singleUse.wrapper}
            value={singleUse}
            onChange={setSingleUse}
            label={
              <>
                <span className={classes.singleUse.label}>Single use</span>
                <span className={classes.singleUse.description}>
                  Make this publication link expire after the first use, any subsequent access will be denied. Note: you can always re-publish a
                  document with an expired link.
                </span>
              </>
            }
            onClickBlur
          />
        </div>
      </div> */}
      <div className={classes.splits.wrapper}>
        <Button onClick={() => setSplit(null)} tertiary={split === null} disabled={split === null}>
          Full document
        </Button>
        {Object.entries(splits || {}).map(([splitId, splitLabel]) => (
          <Button onClick={() => setSplit(splitId)} tertiary={split === splitId} disabled={split === splitId}>
            {splitLabel}
          </Button>
        ))}
      </div>
      <Button
        onClick={() => {
          const email = (input as HTMLInputElement).value
          if (!input?.validity.valid) return input?.focus()
          if (includeEmail && !input?.value) return input?.focus()
          conclude({
            notifyEmail: email,
            includeEmail,
            previewAvailable,
            signatureAvailable,
            expires,
            expirationTime,
            // singleUse,
            split,
          })
        }}
        primary
      >
        Publish
      </Button>
    </div>
  )
})

ModalContent.displayName = 'MainLayout-RightPane-Document-Publishing-ModalContent'

export default ModalContent
