// import DocumentPartialPreview from './Document.PartialPreview'

// export type PartialPreviewProps = {
//   id: string
// }

// export {DocumentPartialPreview}

import { MouseEvent } from 'react'
import { Author, Time } from '___types'

import DocumentThumbnail from './Document.Thumbnail'
import DocumentListItem from './Document.ListItem'

import './style.scss'

export const listItemClasses = {
  wrapper: 'Casus-Components-DocumentListItem-wrapper',
  content: 'Casus-Components-DocumentListItem-content',
}
export const thumbnailClasses = {
  wrapper: 'Casus-Components-DocumentThumbnail-wrapper',
  content: {
    wrapper: 'Casus-Components-DocumentThumbnail-content-wrapper',
    info: 'Casus-Components-DocumentThumbnail-content-info',
    name: 'Casus-Components-DocumentThumbnail-content-name',
    time: 'Casus-Components-DocumentThumbnail-content-time',
  },
}
const classes = {
  listItem: listItemClasses,
  thumbnail: thumbnailClasses,
} as const

export type ListItemProps = {
  id: string
  onClick?: (event: MouseEvent<HTMLButtonElement>, documentId: string) => void
}
export type ThumbnailProps = {
  id?: string | null
  type?: 'docx' | 'pdf'
  folderId?: string | null
  selected?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>, documentId?: string | null) => void
  name?: string
  edited?: Time
  sharedWith?: Author[]
  isPublic?: boolean
  beingDuplicated?: boolean
  beingDeleted?: boolean
}

export { DocumentListItem, DocumentThumbnail, classes }
