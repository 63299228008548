import {
  NESTED_STRUCTURE_KEYS,
  SEGMENT_TYPES,
  DEFAULT_PAGE_LAYOUT,
  DataStructure,
  NestedStructureKeys,
  PartialRecord,
  SegmentsLocation,
  SegmentsLocations,
  NumberingSystem,
  SectionBreak,
  TableCellObject,
  Segment,
  Segments,
  WizardStateDataStructureSection,
  WizardStateDataStructureSections,
  WizardMode,
  ParagraphObject,
  PARAGRAPH_NUMBERING_MATCH,
  CASUS_KEYSTRINGS,
  CASUS_IDS,
  CUSTOM_TEXT_SPLIT,
  WizardStateDataStructureNumbering,
  isModeDocumentFlow,
  EditorMode,
} from '___types'
import { isObject, filterObjectFields } from 'utilities/helpers'
import { WizardState, deepAssign, fullAssign } from '.'

// ====================================================================================================================================== //
// ============================================================= GENERATORS ============================================================= //
// ====================================================================================================================================== //
/**
 * Generates a page range for a section's ["pages"] property.
 * @param {number=}             [start = 0] - The starting page range index (defaults to 0)
 * @param {number=}             [end = -Infinity] - The ending page range index (defaults to -Infinity)
 * @returns {[number, number]}  A generated page range with a [start: number, end: number] signature.
 */
export const generatePageRange = (start: number = 0, end: number = -Infinity): [number, number] => [start, end]
const genericSection = { id: 'generic-section-id', title: 'Generic section title', layout: DEFAULT_PAGE_LAYOUT, pages: [] }
const generateSection = (section: Partial<WizardStateDataStructureSection>): WizardStateDataStructureSection =>
  deepAssign({}, genericSection, section) as WizardStateDataStructureSection
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= PUSH INTO SECTIONS ========================================================= //
// ====================================================================================================================================== //
/**
 * Pushes a new section into the provided sections array (@param sections) and clears the page buffer (@param pageBuffer).
 * @param {WizardStateDataStructureSections} sections - The section array
 * @param {SectionBreak}                     sectionBreak - The segment's section break object
 * @param {[number, number][]}               pageBuffer - The page buffer array containing accumulated page ranges
 * @returns {number}                         0.
 */
const pushIntoSections = (sections: WizardStateDataStructureSections, sectionBreak: SectionBreak, pageBuffer: [number, number][]): 0 =>
  sections.push(
    generateSection(filterObjectFields(Object.assign({}, sectionBreak, { pages: (pageBuffer as [number, number][]).slice() }), 'type'))
  ) && ((pageBuffer as [number, number][]).length = 0) // resets (empties) the page buffer
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= PUSH INTO PAGE BUFFER ======================================================= //
// ===================================================================================================================================== //
/**
 * Pushes a new page into the provided page buffer array (@param pageBuffer).
 * @param {[number, number][]} pageBuffer - The page buffer array
 * @param {number}             lastEndIndex - The index of the last segment of the previous page
 * @param {number}             index - The index of the current segment
 * @returns {number}           the provided index (@param index) + 1.
 */
const pushIntoPageBuffer = (pageBuffer: [number, number][], lastEndIndex: number, index: number): number =>
  pageBuffer.push(generatePageRange(lastEndIndex, index + 1))
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ================================================== EXTRACT DATA STRUCTURE SECTIONS ================================================== //
// ===================================================================================================================================== //
/**
 * Extracts sections from a template's data structure.
 * @param {DataStructure}                      dataStructure - A template's ["dataStructure"] property
 * @returns {WizardStateDataStructureSections} A section array.
 */
export const extractDataStructureSections = (
  dataStructure: DataStructure,
  pageBuffer = [] as never,
  lastEndIndex = 0 as never
): WizardStateDataStructureSections =>
  dataStructure.segments.reduce((result, segment, index) => {
    if (index === dataStructure.segments.length - 1)
      // iterating on the last segment - wraps the last page and generates a section
      return (
        (((lastEndIndex as number) = pushIntoPageBuffer(pageBuffer, lastEndIndex, index)) &&
          pushIntoSections(result, segment.break as SectionBreak, pageBuffer)) ||
        result
      )
    if (!segment.break) return result
    // if there is a break - push a new page into the buffer, wrapping the iterated-upon segments into its range
    pushIntoPageBuffer(pageBuffer, lastEndIndex, index)
    if (segment.break.type === 'section') pushIntoSections(result, segment.break as SectionBreak, pageBuffer) // if the break is of 'section' type push a new section into the section array (with the pages from the buffer)
    return result
  }, [] as WizardStateDataStructureSections)
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= FIND WITHIN STRUCTURE ======================================================= //
// ===================================================================================================================================== //
/**
 * Parses through the nested structure of the provided object (@param structure) looking for the first object that passes the test (@param testFunction).
 * @param {PartialRecord<NestedStructureKeys, unknown[]>} structure - A nested data structure object (including, but not restricted to a template's ["dataStructure"] property itself)
 * @param {(object: unknown) => boolean}                 testFunction - A test function for the object we are looking for within a structure
 * @returns [result, parent, key, index] *
 * @* result (found object) | undefined (if not found)
 * @* parent (parent object of the found object) | undefined (if no object was found or the provided structure (@param structure) passes the test function)
 * @* key (of the array property of the parent object within which the resulting object was found) | undefined (if no object was found or the provided structure (@param structure) passes the test function)
 * @* index (of the resulting object within the parent's [key]: array) | -1 (if no object was found or the provided structure (@param structure) passes the test function)
 */
const findWithinStructure = (
  structure: PartialRecord<NestedStructureKeys, unknown[]> | null,
  testFunction: (object: Record<keyof any, unknown>) => boolean,
  result = undefined as never,
  parent = undefined as never,
  key = undefined as never,
  index = -1 as never
): [Record<keyof any, unknown> | undefined, PartialRecord<NestedStructureKeys, unknown[]> | undefined, NestedStructureKeys | undefined, number] => {
  if (!isObject(structure)) return [result, parent, key, index]
  if (testFunction(structure!)) return (result = structure as never) && [result, parent, key, index]
  NESTED_STRUCTURE_KEYS.find(
    structureKey =>
      Array.isArray(structure![structureKey]) &&
      structure![structureKey]!.find((object, i) => {
        if (!isObject(object)) return false
        const recursiveResult = findWithinStructure(object as Record<keyof any, unknown>, testFunction)
        return (
          recursiveResult[0] && // ======================================================= if found nested within (if not found immediately returns a falsy (undefined) continuing the find loop)
          (result = recursiveResult[0] as never) && // =================================== set the result to the found object
          (parent = (recursiveResult[1] || structure) as never) && // ==================== set the parent to the parent from the return of the recursive function or to structure (@param structure) if the recursive function found object is the object the find function is currently iterating upon, making this structure its parent
          (key = (recursiveResult[2] || structureKey) as never) && // ==================== set the key to the key from the return of the recursive function or to current structureKey if the recursive function found object is the object the find function is currently iterating upon, making this structureKey its relevant array key
          (index = (recursiveResult[3] !== -1 ? recursiveResult[3] : i) as never) // ===== set the index to the index from the return of the recursive function or to i if the recursive function found object is the object the find function is currently iterating upon, making i its index
        )
      })
  )
  return [result, parent, key, index]
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ============================================================ GET SEGMENT ============================================================ //
// ===================================================================================================================================== //
export type PotentionallyFoundSegmentType = [
  Segment | TableCellObject | undefined,
  PartialRecord<NestedStructureKeys, unknown[]> | undefined,
  NestedStructureKeys | undefined,
  number
]
/**
 * Parses through the nested structure of the provided store state's (@param state) wizard data structure looking for the first segment that passes the test (@param testFunction).
 * @param {WizardState}                                   state - Store state
 * @param {(segment: Record<keyof any, unknown>) => boolean} testFunction - A test function for the segment we are looking for within the store state's wizard data structure
 * @returns [result, parent, key, index] *
 * @* result (found object) | undefined (if not found)
 * @* parent (parent object of the found object) | undefined (if no object was found or the provided structure (@param structure) passes the test function)
 * @* key (of the array property of the parent object within which the resulting object was found) | undefined (if no object was found or the provided structure (@param structure) passes the test function)
 * @* index (of the resulting object within the parent's [key]: array) | -1 (if no object was found or the provided structure (@param structure) passes the test function)
 */
const getSegment = (state: WizardState, testFunction: (segment: Record<keyof any, unknown>) => boolean): PotentionallyFoundSegmentType =>
  findWithinStructure(state.dataStructure, testFunction) as PotentionallyFoundSegmentType
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ========================================================= GET SEGMENT BY ID ========================================================= //
// ===================================================================================================================================== //
type FoundSegmentType = [Segment | TableCellObject, PartialRecord<NestedStructureKeys, unknown[]>, NestedStructureKeys, number]
/**
 * A global reference object for storing found segments.
 * @ Entries are manually cleared by certain reducer functions that react to actions that could potentially imply that the previously stored segment has been changed in any way.
 */
type ModeFoundSegments = { [K in EditorMode]: Record<string, FoundSegmentType> }
export const FOUND_SEGMENT_REFERENCES = {} as ModeFoundSegments
/**
 * Parses through the nested structure of the provided store state's (@param state) wizard data structure looking for the first segment whose id matches the provided id (@param id). If found, stores the segment into a global constant object, for later referencing.
 * @param {WizardState} state - Store state
 * @param {string}         id - The id of the segment we are looking for
 * @returns [result, parent, key, index] *
 * @* result (found object) | undefined (if not found)
 * @* parent (parent object of the found object) | undefined (if no object was found or the provided structure (@param structure) passes the test function)
 * @* key (of the array property of the parent object within which the resulting object was found) | undefined (if no object was found or the provided structure (@param structure) passes the test function)
 * @* index (of the resulting object within the parent's [key]: array) | -1 (if no object was found or the provided structure (@param structure) passes the test function)
 */
export const getSegmentById = (state: WizardState, id: string, mode: EditorMode = 'edit'): PotentionallyFoundSegmentType => {
  if (FOUND_SEGMENT_REFERENCES[mode] && FOUND_SEGMENT_REFERENCES[mode][id]) return FOUND_SEGMENT_REFERENCES[mode][id]
  const result = getSegment(state, segment => segment.id === id)
  if (result[0])
    Object.assign(FOUND_SEGMENT_REFERENCES, { [mode]: Object.assign(FOUND_SEGMENT_REFERENCES[mode] || {}, { [id]: result as FoundSegmentType }) })
  return result
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ====================================================== GET CONTENT BY PARENT ID ====================================================== //
// ====================================================================================================================================== //
/**
 * Parses through the wizard state looking for the segment with an id matching the provided parent id (@param parentId) and, if found, returns its content.
 * @param {WizardState}            state - Store state
 * @param {string}                 parentId - The id of the parent whose content we are trying to get
 * @returns {Segments | undefined} An array of segments (the content of the provided parent ided (@param parentId) segment) if found, undefined otherwise.
 */
const getContentByParentId = (state: WizardState, parentId: string, mode: EditorMode = 'edit'): Segments | undefined =>
  parentId === 'root' ? state.dataStructure?.segments : (getSegmentById(state, parentId, mode)[0] as TableCellObject)?.content
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// =================================================== APPLY MARKER ID TO SEGMENT IDS =================================================== //
// ====================================================================================================================================== //
/**
 * Applies the provided marker id (@param markerId) onto relevant segment ids (determined by the marker range (@param range)) by concatinating the segment id and the marker id with a `${segmentId}; marker:${markerId}` signature. Modifies the provided segment ids array (@param segmentIds).
 * @param {string[]}         segmentIds - An array of segment ids
 * @param {string}           markerId - The id of a marker
 * @param {[number, number]} range - The range of segments the marker with the provided id (@param markerId) spans
 * @returns void
 */
const applyMarkerIdToSegmentIds = (segmentIds: string[], markerId: string, range: [number, number]): void => {
  segmentIds.splice(range[0], range[1] - range[0], ...segmentIds.slice(...range).map(segmentId => `${segmentId};marker:${markerId}`))
  return
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ======================================================= GET MAPPED SEGMENT IDS ======================================================= //
// ====================================================================================================================================== //
// ================================================== GET MAPPED SEGMENT IDS: OVERLOAD ================================================== //
/**
 * Looks for a segment with an id matching the provided parent id (@param parentId) and, if found, takes its content's segment's ids as an array of strings (string[]) and maps marker ids (found via provided parent id (@param parentId)) onto the segment ids array. If not found, returns an empty array.
 * @param {WizardState}            state - Store state
 * @param {string}                 parentId - The id of the parent whose content ids we are trying to map
 * @returns {string[]}             An array of mapped segment ids (with marker ids applied upon them).
 */
export function getMappedSegmentIds(state: WizardState, parentId: string, mode?: EditorMode): string[]
/**
 * Maps marker ids (found via provided parent id (@param parentId)) onto the provided segment ids (@param segmentIds) array.
 * @param {WizardState}            state - Store state
 * @param {string}                 parentId - The id of the parent whose content ids we are trying to map
 * @param {string[]}               segmentIds - An array of segment ids
 * @returns {string[]}             An array of mapped segment ids (with marker ids applied upon them).
 */
export function getMappedSegmentIds(state: WizardState, parentId: string, segmentIds: string[], mode?: EditorMode): string[]
/**
 * Looks for a segment with an id matching the provided parent id (@param parentId) and, if found, takes its content's segment's ids as an array of strings (string[]) and maps marker ids (found in the provided marker array (@param markerArray)) onto the segment ids array. If not found, returns an empty array.
 * @param {WizardState}            state - Store state
 * @param {string}                 parentId - The id of the parent whose content ids we are trying to map
 * @param {SegmentsLocation[]}     markerArray - An array of segments locations
 * @returns {string[]}             An array of mapped segment ids (with marker ids applied upon them).
 */
export function getMappedSegmentIds(state: WizardState, parentId: string, markerArray: SegmentsLocation[], mode?: EditorMode): string[]
/**
 * Maps marker ids (found in the provided marker array (@param markerArray)) onto the provided segment ids (@param segmentIds) array.
 * @param {WizardState}            state - Store state
 * @param {string[]}               segmentIds - An array of segment ids
 * @param {SegmentsLocation[]}     markerArray - An array of segments locations
 * @returns {string[]}             An array of mapped segment ids (with marker ids applied upon them).
 */
export function getMappedSegmentIds(state: WizardState, segmentIds: string[], markerArray: SegmentsLocation[], mode?: EditorMode): string[]
// ====================================================================================================================================== //
export function getMappedSegmentIds(state: WizardState, ...args: unknown[]): string[] {
  const parentId = typeof args[0] === 'string' ? args[0] : undefined
  const primary = args[0 + Number(Boolean(parentId))]
  const segmentIds = Array.isArray(primary) && typeof primary[0] === 'string' ? (primary as string[]) : undefined
  const secondary = args[0 + Number(Boolean(parentId)) + Number(Boolean(segmentIds))]
  const markerArray = Array.isArray(secondary) ? (secondary as SegmentsLocation[]) : undefined
  const mode = args[(Number(Boolean(parentId)) + Number(Boolean(segmentIds)), Number(Boolean(markerArray)))] as EditorMode
  return (markerArray || (parentId && (state.locations?.segments || {})[parentId]) || []).reduce((acc, { id, range }) => {
    const childMarkers = (state.locations?.segments || {})[id]
    const mappedInner = childMarkers?.length ? getMappedSegmentIds(state, acc, childMarkers, mode) : acc
    applyMarkerIdToSegmentIds(mappedInner, id, range)
    return mappedInner
  }, segmentIds || ((parentId && getContentByParentId(state, parentId, mode)) || []).map(({ id }) => id))
}
// ====================================================================================================================================== //
//
//
//
//
//
//
//
//
//
//
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================= REDUCER FUNCTIONS ========================================================= //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

const initializeSections = (state: WizardState): WizardState => {
  if (!state.dataStructure) return state
  return fullAssign({}, state, {
    dataStructure: Object.assign({}, state.dataStructure, { sections: extractDataStructureSections(state.dataStructure) }),
  }) as WizardState
}

const setNumberingDepthLevel = (levels: Record<string, number[]>, key: string, depth: number, value: number = 0) =>
  Object.assign(levels, {
    [key]: levels[key].slice(0, depth).concat(value, new Array(Math.max(levels[key]?.length - depth - 1, 0)).fill(0)),
  })
const incrementNumberingDepthLevel = (levels: Record<string, number[]>, key: string, depth: number) =>
  setNumberingDepthLevel(levels, key, depth, levels[key][depth] + 1)

const generateDummyParagraph = (id: string, index: number, styles: string[], customStyle: string) => ({
  id: `${id}-${index}`,
  type: SEGMENT_TYPES.PARAGRAPH,
  styles,
  customStyle,
})

const getRelevantLocations = (locations: SegmentsLocations, id: string): SegmentsLocation[] =>
  (locations[id] || []).reduce(
    (acc, segmentsLocation) => acc.concat(segmentsLocation).concat(getRelevantLocations(locations, segmentsLocation.id)),
    [] as SegmentsLocation[]
  )

const applySegmentsMarkerDiscardAndReplace = (
  array: Record<string, unknown>[],
  locations: SegmentsLocations,
  id: string,
  offset: never = 0 as never
): void => {
  const relevantLocations = getRelevantLocations(locations, id).reduce(
    (accumulated, segmentsLocation) => {
      const { keep, replace } = segmentsLocation
      if (!keep) accumulated.discard.push(segmentsLocation)
      if (keep && replace) accumulated.replace.push(segmentsLocation)
      return accumulated
    },
    { discard: [] as SegmentsLocation[], replace: [] as SegmentsLocation[] }
  )
  relevantLocations.discard.forEach(({ range: [start, end] }) => array.splice(start, end - start, ...new Array(end - start).fill({})))
  relevantLocations.replace.forEach(({ id, contentStyles, contentCustomStyle, replace, range: [start, end] }) => {
    const replaceValuesCount = replace?.split(CUSTOM_TEXT_SPLIT).length || 0
    const fillArray = new Array(replaceValuesCount).fill(null).map((_, i) => generateDummyParagraph(id, i, contentStyles, contentCustomStyle))
    array.splice(start - offset, end - start, ...fillArray)
    return [array, end - start - replaceValuesCount] as [Record<string, unknown>[], number]
  })
}

const generateParagraphNumbering = (
  structure: ParagraphObject,
  styleMap: Record<string, string>,
  propagatedLevels: Record<string, number[]>,
  propagatedObject: WizardStateDataStructureNumbering = {}
): WizardStateDataStructureNumbering => {
  const { customStyle, styles = [] } = structure
  const styleNumbering = styles.reduce(
    (result, style) => (style.match(PARAGRAPH_NUMBERING_MATCH)?.groups as { systemKey: string; depthLevel: string }) || result,
    undefined as { systemKey: string; depthLevel: string } | undefined
  )
  const [systemKey, depthLevel] = (styleMap[customStyle] ? styleMap[customStyle]?.split('-') : styleNumbering && Object.values(styleNumbering)) || []
  if (!(systemKey && propagatedLevels[systemKey])) return propagatedObject
  const shouldReset = Boolean(styleMap[customStyle] && styleNumbering)
  const resetValue =
    styleNumbering && propagatedLevels[styleNumbering.systemKey] && propagatedLevels[styleNumbering.systemKey][Number(styleNumbering.depthLevel)]
  if (shouldReset && (resetValue || resetValue === 0)) {
    setNumberingDepthLevel(propagatedLevels, systemKey, Number(depthLevel), resetValue)
    incrementNumberingDepthLevel(propagatedLevels, styleNumbering!.systemKey, Number(styleNumbering!.depthLevel))
  }
  incrementNumberingDepthLevel(propagatedLevels, systemKey, Number(depthLevel))
  return Object.assign(propagatedObject, {
    [structure.id]: {
      value: propagatedLevels[systemKey][Number(depthLevel)],
      system: `${CASUS_KEYSTRINGS.NUMBERING_SYSTEM_KEY}_${systemKey}${CASUS_KEYSTRINGS.NUMBERING_LEVEL}_${depthLevel}`,
    },
  })
}

const generateParagraphsNumbering = (
  mode: WizardMode,
  structure: Record<string, unknown>,
  locations: SegmentsLocations,
  styleMap: Record<string, string>,
  propagatedLevels: Record<string, number[]>,
  propagatedObject: WizardStateDataStructureNumbering = {}
): WizardStateDataStructureNumbering => {
  if (structure?.id && structure.type === SEGMENT_TYPES.PARAGRAPH)
    return generateParagraphNumbering(structure as unknown as ParagraphObject, styleMap, propagatedLevels, propagatedObject)
  return structure
    ? NESTED_STRUCTURE_KEYS.reduce((result, structureKey) => {
        if (!Array.isArray(structure[structureKey])) return result
        const id = (structure.id === CASUS_IDS.DATASTRUCTURE_ID ? 'root' : structure.id) as string
        const array = (structure[structureKey] as Record<string, unknown>[]).slice()
        if (isModeDocumentFlow(mode)) applySegmentsMarkerDiscardAndReplace(array, locations, id) // Add preview mode ( || mode === DOCUMENT_PREVIEW_MODE)
        return array.reduce(
          (accumulated: WizardStateDataStructureNumbering, innerStructure) =>
            generateParagraphsNumbering(mode, innerStructure, locations, styleMap, propagatedLevels, accumulated),
          result
        )
      }, propagatedObject)
    : propagatedObject
}

const generateNumberingLevels = (numberingSystem: NumberingSystem): Record<string, number[]> =>
  Object.entries(numberingSystem).reduce((acc, [systemKey, levels]) => Object.assign(acc, { [systemKey]: new Array(levels.length).fill(0) }), {})

const generateStyleMap = (numberingSystem: NumberingSystem): Record<string, string> =>
  Object.entries(numberingSystem).reduce(
    (resultingMap, [systemKey, levels]) =>
      levels.reduce((acc, { styleName }, i) => (styleName ? Object.assign(acc, { [styleName]: `${systemKey}-${i}` }) : acc), resultingMap),
    {} as Record<string, string>
  )

const applyParagraphNumbering = (state: WizardState): WizardState => {
  if (!(state.mode && state.dataStructure?.numberingSystem)) return state
  const { dataStructure, locations } = state
  const { segments: segmentsLocations = {} } = locations || {}
  const { numberingSystem, numberings } = dataStructure

  const numbering = Object.assign({}, numberingSystem, numberings)
  const styleLevelMap = generateStyleMap(numbering)
  const numberingLevels = generateNumberingLevels(numbering)
  const generatedNumbering = generateParagraphsNumbering(state.mode, dataStructure, segmentsLocations, styleLevelMap, numberingLevels)
  Object.assign(state.dataStructure, { numbering: generatedNumbering })
  return Object.assign({}, state) as WizardState
}

export { initializeSections, applyParagraphNumbering }
