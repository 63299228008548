import React, { FunctionComponent } from 'react'

import { ProfileProps, classes } from '.'

export const Profile: FunctionComponent<ProfileProps> = React.memo(({ profile, showInfo = false }) => {
  const { firstName, lastName, email, imageUrl } = profile
  const initials = firstName || lastName ? `${firstName?.slice(0, 1) || ''}${lastName?.slice(0, 1) || ''}` : 'NN'
  const label = [firstName, lastName].filter(s => s).join(' ')
  return (
    <div className={classes.wrapper}>
      {imageUrl ? <img className={classes.profile} src={imageUrl} alt="" /> : <div className={classes.profile}>{initials}</div>}
      {showInfo ? (
        <div className={classes.details}>
          {label ? <span className={classes.moniker}>{label}</span> : null}
          {email ? <span className={classes.address}>{email}</span> : null}
        </div>
      ) : null}
    </div>
  )
})

Profile.displayName = 'Casus-Components-Profile'

export default Profile
