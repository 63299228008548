import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplate } from '___types'
import { templatesAPI } from '___api'
import { TEMPLATE_LIST_PER_PAGE } from '___api/api.templates'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type UnshareTemplateVariables = { id: string; shareId: string; updateCategory?: string }
const unshareTemplateMutationFunction = (variables: UnshareTemplateVariables) => templatesAPI.unshareTemplate(variables.id, variables.shareId)

export const useUnshareTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const templateUnshareMutation = useMutation<ITemplate, unknown, UnshareTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, id],
    unshareTemplateMutationFunction,
    {
      onMutate: variables => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        const mutationId = uuid()
        if (currentTemplate) {
          const originalTemplate = currentTemplate.original || currentTemplate
          const originalShareIndex = originalTemplate.sharedWith.findIndex(({ id }) => id === variables.id)
          if (originalShareIndex !== -1) {
            const resultingShare = Object.assign({}, originalTemplate.sharedWith[originalShareIndex], { mutating: mutationId })
            const resultingShared = originalTemplate.sharedWith.slice()
            resultingShared.splice(originalShareIndex, 1, resultingShare)
            const optimisticTemplate = Object.assign({}, originalTemplate, { sharedWith: resultingShared, mutationId, original: originalTemplate })
            queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
            if (variables.updateCategory)
              queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
                infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, optimisticTemplate)
              )
          }
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, currentTemplate.original)
            )
        }
      },
      onSuccess: (template, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, template)
            )
        }
      },
    }
  )

  const unshareMutationFunction = (
    shareId: string,
    category: string = TEMPLATES_FILTERS.MINE,
    options?: MutateOptions<ITemplate, unknown, UnshareTemplateVariables, { mutationId: string }>
  ) => templateUnshareMutation.mutate({ id: id!, shareId, updateCategory: category }, options)
  return { unshare: unshareMutationFunction, unsharing: templateUnshareMutation.isLoading }
}

export type UnshareTemplateFunctionType = (
  shareId: string,
  category?: string,
  options?: MutateOptions<ITemplate, unknown, UnshareTemplateVariables, { mutationId: string }>
) => void

export default useUnshareTemplate
