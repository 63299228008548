import React, { FunctionComponent } from 'react'

import { DOCUMENTS_FILTERS } from '___types'
import FolderTree from './FolderTree'
import { MainLayoutLeftPaneDocumentsSharedProps, mainLayoutLeftPaneDocumentsSharedClasses as classes } from '../../..'

export const Shared: FunctionComponent<MainLayoutLeftPaneDocumentsSharedProps> = React.memo(() => (
  <FolderTree className={classes.wrapper} directory="documents" id={DOCUMENTS_FILTERS.SHARED} name="Shared documents" />
))

Shared.displayName = 'MainLayout-LeftPane-Documents-Shared'

export default Shared
