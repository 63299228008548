import React, { FunctionComponent, useCallback, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import useStore, { WizardTemplateIdSelector } from '___store'

import { useTemplate } from '___hooks'
import { Profile } from 'components/CasusComponents'
import { Actions, ExternalAPIs, Languages, Paywall } from './Configuration.Components'
import { wizardLayoutLeftPaneDocumentConfigurationClasses as classes, useWizardLayoutContext } from 'Layouts/WizardLayout'

type UseStoreHookResultType = { wizardTemplateId: WizardTemplateIdSelector }

export const Configuration: FunctionComponent = React.memo(() => {
  const { templateId, documentId } = useParams() as { templateId: string; documentId: string }
  const [integrationsLoading, setIntegrationsLoading] = useState([] as string[])
  const { wizardTemplateId } = useStore('selectWizardTemplateId') as UseStoreHookResultType

  const publicFlow = useWizardLayoutContext()

  const { data: template } = useTemplate(wizardTemplateId!, publicFlow)
  const { name, description, paywallBlocked } = template || {}

  const integrationLoadingCallback = useCallback(
    (integrationId, isLoading) =>
      setIntegrationsLoading(prev => {
        if (isLoading) return Array.from(new Set(prev.concat(integrationId)))
        else return prev.filter(id => id !== integrationId)
      }),
    []
  )

  return (
    <div className={classes.wrapper}>
      {publicFlow ? <Redirect to={`/public/${templateId}/${documentId}`} /> : null}
      <div className={classes.info.wrapper}>
        <span className={classes.info.title}>{name}</span>
        <span className={classes.info.description}>{description}</span>
        {template?.author ? <Profile profile={Object.assign({}, template.author, template.displayAuthor)} showInfo /> : null}
      </div>
      <div className={classes.settings.wrapper}>
        {paywallBlocked ? <Paywall /> : null}
        {!paywallBlocked ? <Languages /> : null}
        {!paywallBlocked ? <ExternalAPIs loadingCallback={integrationLoadingCallback} /> : null}
      </div>
      <Actions isLoading={Boolean(integrationsLoading.length)} />
    </div>
  )
})

Configuration.displayName = 'WizardLayout-LeftPane-Document-Configuration'

export default Configuration
