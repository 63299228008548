import React, { useMemo } from 'react'
import useStore from '___store'

import { useFetchExternalServices } from '___queries'
import { classes } from '.'
import { Button } from 'components/CasusComponents'
import { Cogwheel } from 'assets/svgIconComponents'

export const Integrations = React.memo(() => {
  const { wizardExternalAPIs, setConfiguring } = useStore('selectWizardExternalAPIs', 'setConfiguring')
  const { data, isLoading } = useFetchExternalServices()

  const entries = useMemo(
    () =>
      !isLoading && data
        ? data.map(entry => (
            <div key={`Integrations-entry-${entry.id}`} className={classes.integratons.entry}>
              <div className={classes.integratons.entryInfo}>
                <span>{entry.title}</span>
                <span>{(wizardExternalAPIs || {})[entry.id]?.length || 0} fields selected</span>
              </div>
              <Button onClick={() => setConfiguring({ key: 'integration', id: entry.id })}>
                <Cogwheel />
                Configure fields
              </Button>
            </div>
          ))
        : null,
    [isLoading, data, wizardExternalAPIs, setConfiguring]
  )

  return entries?.length ? (
    <div className={`${classes.section.wrapper} integrations`}>
      <span className={classes.section.title}>INTEGRATIONS</span>
      {entries}
    </div>
  ) : null
})

Integrations.displayName = ''

export default Integrations
