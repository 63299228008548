import React, { FunctionComponent, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import { ValuesOf } from '___types'
import { useUser } from '___hooks'
import { Burger, Document, Signature, Squares, Team, Template } from 'assets/svgIconComponents'
import { Button, Dropdown } from 'components/CasusComponents'
import { MainLayoutHeaderNavigationProps, mainLayoutHeaderClasses as classes } from '../..'

const PAGES = {
  dashboard: { path: '/', icon: <Squares />, label: 'Dashboard' },
  documents: { path: '/documents', icon: <Document />, label: 'Documents' },
  templates: { path: '/templates', icon: <Template />, label: 'Templates' },
  signatures: { path: '/signatures', icon: <Signature />, label: 'Signatures' },
  team: { path: '/team', icon: <Team />, label: 'Team' },
} as const

export const Navigation: FunctionComponent<MainLayoutHeaderNavigationProps> = React.memo(() => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { data: { type } = {} } = useUser()

  const availablePages = useMemo(() => {
    const resultingPages = Object.assign({}, PAGES) as Record<string, ValuesOf<typeof PAGES>>
    const directory = pathname.split('/').filter(s => s)[0]
    delete resultingPages[directory]
    if (type !== 'USER_TYPE_CLIENT_ADMIN') delete resultingPages.templates
    return resultingPages
  }, [pathname, type])

  const dropdownOptions = useMemo(
    () =>
      Object.entries(availablePages).map(([id, { path, icon, label }]) => (
        <Button key={`MainLayout-Header-Navigation-Button-${id}`} onClick={() => push(path)} onClickBlur noBackgroundSVG noOverlaySVG>
          {icon}
          {label}
        </Button>
      )),
    [availablePages, push]
  )

  return (
    <Dropdown className={classes.navigation} hideCaret label={<Burger />} transparent>
      {dropdownOptions}
    </Dropdown>
  )
})

Navigation.displayName = 'MainLayout-Header-Navigation'

export default Navigation
