import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplate, Author } from '___types'
import { templatesAPI } from '___api'
import { TEMPLATE_LIST_PER_PAGE } from '___api/api.templates'
import { QUERY_KEYS, infiniteQueryReplace } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type ShareTemplateVariables = { id: string; email: string; permissions: { use: boolean; edit: boolean }; updateCategory?: string }
const shareTemplateMutationFunction = (variables: ShareTemplateVariables) =>
  templatesAPI.shareTemplate(variables.id, variables.email, variables.permissions)

export const useShareTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const templateShareMutation = useMutation<ITemplate, unknown, ShareTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, id],
    shareTemplateMutationFunction,
    {
      onMutate: variables => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        const mutationId = uuid()
        if (currentTemplate) {
          const originalTemplate = currentTemplate.original || currentTemplate
          const resultingShared = originalTemplate.sharedWith.concat({ email: variables.email, mutating: mutationId } as unknown as Author)
          const optimisticTemplate = Object.assign({}, originalTemplate, { sharedWith: resultingShared }, { mutationId, original: originalTemplate })
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], optimisticTemplate)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, optimisticTemplate)
            )
        }
        return { mutationId }
      },
      onError: (error, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], currentTemplate.original)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, currentTemplate.original)
            )
        }
      },
      onSuccess: (template, variables, context) => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate & { mutationId?: string; original: ITemplate }
        if (currentTemplate && currentTemplate.mutationId === context?.mutationId) {
          queryClient.setQueryData([QUERY_KEYS.TEMPLATE, id], template)
          if (variables.updateCategory)
            queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.updateCategory ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
              infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === id, template)
            )
        }
      },
    }
  )

  const shareMutationFunction = (
    email: string,
    use: boolean = true,
    edit: boolean = false,
    category: string = TEMPLATES_FILTERS.MINE,
    options?: MutateOptions<ITemplate, unknown, ShareTemplateVariables, { mutationId: string }>
  ) => templateShareMutation.mutate({ id: id!, email, permissions: { use, edit }, updateCategory: category }, options)
  return { share: shareMutationFunction, sharing: templateShareMutation.isLoading }
}

export type ShareTemplateFunctionType = (
  email: string,
  use?: boolean,
  edit?: boolean,
  category?: string,
  options?: MutateOptions<ITemplate, unknown, ShareTemplateVariables, { mutationId: string }>
) => void

export default useShareTemplate
