import { useFetchTemplateList, useFetchTemplateFolderList } from '___queries'
import { useCreateTemplateFolder } from '___queries/templates'

export const useTemplateList = (filter?: string | null) => {
  const templateList = useFetchTemplateList(filter)
  const templateFolderList = useFetchTemplateFolderList(filter)
  const { createFolder, creatingFolder } = useCreateTemplateFolder()
  return { list: templateList, folders: templateFolderList, createFolder, creatingFolder }
}

export default useTemplateList
