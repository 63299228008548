import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { InfiniteData, MutationOptions, useMutation, useQueryClient } from 'react-query'
import { WizardState, generateAnswerRelevance, generateQuestionOrder } from '___store'

import { Answers, DOCUMENTS_FILTERS, DocumentProgress, IDocument, ITemplate, PartialDocument, QuestionLayout, Questions } from '___types'
import { documentsAPI } from '___api'
import { DOCUMENT_LIST_PER_PAGE } from '___api/api.documents'
import { QUERY_KEYS, infiniteQueryUnshift, infiniteQueryFilter, infiniteQueryReplace } from '___queries'
// import { getDocumentListQueryFunction } from './useFetchDocumentList'

const generateDocumentProgress = (questionLayout: QuestionLayout, questions: Questions, answers?: Answers) => {
  const pseudoState = { questions: questions, answers: answers } as WizardState
  const answerRelevance = generateAnswerRelevance(pseudoState)
  const questionOrder = generateQuestionOrder(questionLayout, questions, answerRelevance)
  return questionOrder.reduce((result, questionOrderEntry) => {
    const questionId = questionOrderEntry.split(':')[1]
    const question = questions.find(({ id }) => id === questionId)
    if (!question) return result
    const key = question.private ? 'private' : 'public'
    const resultingQuestionCount = (result[key]?.questions || 0) + 1
    const resultingAnsweredCount = (result[key]?.answered || 0) + Number(Boolean((answers || []).find(({ id }) => id === questionId)))
    Object.assign(result, { [key]: { questions: resultingQuestionCount, answered: resultingAnsweredCount } })
    return result
  }, {} as DocumentProgress)
}

export type CreateDocumentVariables = { document: PartialDocument; updateCategory?: string; publicFlow?: boolean }
const createDocumentMutationFunction = (variables: CreateDocumentVariables) =>
  documentsAPI.createDocument(variables.document, variables.updateCategory, variables.publicFlow)

export const useCreateDocument = (publicFlow: boolean = false) => {
  const queryClient = useQueryClient()
  const generatedId = useMemo(() => uuid(), [])
  const documentCreateMutation = useMutation<IDocument, unknown, CreateDocumentVariables, { mutationId: string }>(
    [QUERY_KEYS.DOCUMENT, generatedId].concat(publicFlow ? 'public' : []),
    createDocumentMutationFunction,
    {
      onMutate: variables => {
        if (!publicFlow)
          queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) => {
            if (!data) return { pages: [[variables.document as IDocument]], pageParams: [null] }
            return infiniteQueryUnshift(
              data,
              DOCUMENT_LIST_PER_PAGE,
              Object.assign({}, variables.document, { id: generatedId, optimistic: true }) as IDocument
            )
          })
        return { mutationId: generatedId }
      },
      onError: (error, variables, context) => {
        if (publicFlow) return
        queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
          infiniteQueryFilter(data!, DOCUMENT_LIST_PER_PAGE, ({ id }) => id !== context?.mutationId)
        )
      },
      onSuccess: (document, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.DOCUMENT, document.id], document)
        if (publicFlow) return
        queryClient.setQueryData([QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? []), (data: InfiniteData<IDocument[]> | undefined) =>
          infiniteQueryReplace(data!, DOCUMENT_LIST_PER_PAGE, ({ id }) => id === context?.mutationId, document)
        )
      },
      // onSettled: async (document, error, variables, context) => {
      //   queryClient.removeQueries([QUERY_KEYS.DOCUMENT, context?.id])
      //   if (publicFlow) return
      //   // =========================================================================================== //
      //   // ==================================== REFETCH DOCUMENTS ==================================== //
      //   // =========================================================================================== //
      //   const queryKey = [QUERY_KEYS.DOCUMENTS].concat(variables.updateCategory ?? [])
      //   queryClient.cancelQueries(queryKey) // async
      //   queryClient.invalidateQueries(queryKey) // async
      //   queryClient.fetchInfiniteQuery({ queryKey, queryFn: getDocumentListQueryFunction }) // async
      //   // =========================================================================================== //
      // },
    }
  )

  const createMutationFunction = (
    document: PartialDocument,
    category: string = DOCUMENTS_FILTERS.MINE,
    options?: MutationOptions<IDocument, unknown, CreateDocumentVariables, { mutationId: string }>
  ) => {
    const template = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, document.templateId]) as ITemplate
    if (template) {
      const { questionLayout, questions } = template
      Object.assign(document, { progress: generateDocumentProgress(questionLayout, questions, document.answers) })
    }
    return documentCreateMutation.mutate({ document, updateCategory: category, publicFlow }, options)
  }
  return { create: createMutationFunction, creating: documentCreateMutation.isLoading }
}

export type CreateDocumentFunctionType = (
  document: PartialDocument,
  category?: string,
  options?: MutationOptions<IDocument, unknown, CreateDocumentVariables, { mutationId: string }>
) => void

export default useCreateDocument
