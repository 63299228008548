import React, { FunctionComponent, RefCallback, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useStore, { DocumentsFilterSelector } from '___store'

import { DOCUMENT_DIRECTORY } from '___types'
import { useDocument } from '___hooks'
import { Document, Folder, Plus } from 'assets/svgIconComponents'
import { Button } from 'components/CasusComponents'
import Mine from './Documents.Mine'
import Shared from './Documents.Shared'
import { MainLayoutLeftPaneDocumentsProps, mainLayoutLeftPaneDocumentsClasses as classes } from '../../..'

type UseStoreHookResultType = { documentsFilter: DocumentsFilterSelector }

export const Documents: FunctionComponent<MainLayoutLeftPaneDocumentsProps> = React.memo(() => {
  const history = useHistory()
  const [uploadInput, setUploadInput] = useState<HTMLInputElement>()
  const uploadInputRef: RefCallback<HTMLInputElement> = useCallback(node => node && setUploadInput(node), [])

  const { documentsFilter } = useStore('selectDocumentsFilter') as UseStoreHookResultType

  const categoryId = documentsFilter.slice(-1)[0]

  const { uploadPDF } = useDocument()

  return (
    <div className={classes.wrapper}>
      <Button className={classes.newDocument} onClick={() => history.push(`/${DOCUMENT_DIRECTORY}`)} primary>
        <Document />
        New Document
        <Plus />
      </Button>
      <div className={classes.uploadPdf}>
        <input
          ref={uploadInputRef}
          type="file"
          accept=".pdf"
          onInput={event => {
            const file = (event.target as HTMLInputElement).files?.[0]
            if (file) return uploadPDF(file, categoryId)
          }}
        />
        <Button onClick={() => uploadInput?.click()}>
          <Document function="create" />
          Upload .pdf
        </Button>
      </div>
      <Button className={classes.newFolder} onClick={() => console.log('UPLOAD PDF!')} disabled>
        <Folder function="add" />
        New folder
      </Button>
      <Mine />
      <Shared />
      {/* <Copy type="link" /> */}
    </div>
  )
})

Documents.displayName = 'MainLayout-LeftPane-Documents'

export default Documents
