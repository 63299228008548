import React, { FunctionComponent, useMemo } from 'react'
import useStore, { WizardExternalAPIsSelector } from '___store'

import { EXTERNAL_SERVICE_TITLES, ExternalServiceId } from '___types'
import External from './Available.External'

type UseStoreHookResultType = { wizardExternalAPIs: WizardExternalAPIsSelector }
//  Apply proper type after creating a .ts selector

type Props = { id: string; valueSources: string[] }
export const Externals: FunctionComponent<Props> = React.memo(({ id, valueSources }) => {
  const { wizardExternalAPIs } = useStore('selectWizardExternalAPIs') as UseStoreHookResultType

  const availableExternals = useMemo(
    () =>
      (wizardExternalAPIs ? Object.entries(wizardExternalAPIs) : []).map(([externalId, fields]) => (
        <External
          key={`Available-Value-Source-Set-${externalId}`}
          id={id}
          valueSources={valueSources}
          externalId={externalId}
          externalLabel={EXTERNAL_SERVICE_TITLES[externalId as ExternalServiceId]}
          fields={fields}
        />
      )),
    [wizardExternalAPIs, id, valueSources]
  )

  return <>{availableExternals}</>
})

Externals.displayName = ''

export default Externals
