import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStore, { WizardExternalAPIsSelector } from '___store'

import { EXTERNAL_SERVICE_TITLES, ExternalServiceId } from '___types'
import Integration from './ExternalAPIs.Integration'
import {
  WizardLayoutLeftPaneDocumentConfigurationExternalAPIsProps,
  wizardLayoutLeftPaneDocumentConfigurationExternalAPIsClasses as classes,
} from 'Layouts/WizardLayout'

type UseStoreHookResultType = { wizardExternalAPIs: WizardExternalAPIsSelector }

export const ExternalAPIs: FunctionComponent<WizardLayoutLeftPaneDocumentConfigurationExternalAPIsProps> = React.memo(({ loadingCallback }) => {
  const { t: translate } = useTranslation('translation', { keyPrefix: 'wizard.document-flow.configuration' })

  const { wizardExternalAPIs } = useStore('selectWizardExternalAPIs') as UseStoreHookResultType

  const render = useMemo(
    () =>
      wizardExternalAPIs
        ? Object.entries(wizardExternalAPIs).map(([id, fields]) => (
            <Integration
              key={`WizardLayout-LeftPane-Document-Configuration-ExternalAPIs-Integration-${id}`}
              id={id}
              title={EXTERNAL_SERVICE_TITLES[id as ExternalServiceId]}
              fields={fields}
              loadingCallback={loadingCallback}
            />
          ))
        : null,
    [wizardExternalAPIs, loadingCallback]
  )

  return render?.length ? (
    <div className={classes.wrapper}>
      <span className={classes.title}>{translate('integrations')}</span>
      <div className={classes.entries}>{render}</div>
    </div>
  ) : null
})

ExternalAPIs.displayName = 'WizardLayout-LeftPane-Document-Configuration-ExternalAPIs'

export default ExternalAPIs
