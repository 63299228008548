import React, { useState, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import useStore from '___store'

import { extractValueFromInputEvent } from 'utilities/helpers'
import { QUERY_KEYS } from '___queries'
import { CheckBox } from 'assets/svgIconComponents'
import { Button, Input } from 'components/CasusComponents'
import { ButtonGroup, Button as Button2 } from 'components'
import { classes } from '.'
import { classes as classes2 } from '../..'

const splitString = (string, indices) => {
  const reversed = indices.slice()
  reversed.reverse()
  const result = reversed
    .reduce((acc, [start, end]) => {
      const lastEntry = acc.length && acc[acc.length - 1]
      const endValue = string.slice(end)
      if (endValue.length) {
        if (lastEntry && lastEntry.type === 'text') lastEntry.value += endValue
        else acc.push({ type: 'text', value: endValue })
        string = string.slice(0, end)
      }
      const startValue = string.slice(start)
      if (startValue.length) {
        if (lastEntry && lastEntry.type === 'highlight') lastEntry.value += startValue
        else acc.push({ type: 'highlight', value: startValue })
        string = string.slice(0, start)
      }
      return acc
    }, [])
    .concat({ type: 'text', value: string })
  result.reverse()
  return result
}

export const IntegrationConfig = React.memo(({ id }) => {
  const { wizardExternalAPIs, addExternalAPIField, removeExternalAPIField, concludeConfiguring } = useStore(
    'selectWizardExternalAPIs',
    'addExternalAPIField',
    'removeExternalAPIField',
    'concludeConfiguring'
  )

  const [search, setSearch] = useState('')

  const queryClient = useQueryClient()
  const integrationFields =
    queryClient.getQueryData([QUERY_KEYS.EXTERNAL_SERVICES])?.find(({ id: integrationId }) => integrationId === id)?.fields || []
  const addedFields = useMemo(() => (wizardExternalAPIs && wizardExternalAPIs[id])?.map(({ id: fieldId }) => fieldId), [wizardExternalAPIs, id])

  const render = useMemo(
    () =>
      integrationFields.reduce((result, field) => {
        const nameMatch = search.length ? Array.from(field.name.toLowerCase().matchAll(search.toLowerCase()) || []) : []
        const typeMatch = search.length ? Array.from(field.type.toLowerCase().matchAll(search.toLowerCase()) || []) : []
        const idMatch = search.length ? Array.from(field.id.toLowerCase().matchAll(search.toLowerCase()) || []) : []
        if (search.length && !(nameMatch.length || typeMatch.length || idMatch.length)) return result
        const added = addedFields?.includes(field.id)
        const nameSplit = splitString(
          field.name,
          nameMatch.map(({ index }) => [index, index + search.length])
        )
        const typeSplit = splitString(
          field.type,
          typeMatch.map(({ index }) => [index, index + search.length])
        )
        const idSplit = splitString(
          field.id,
          idMatch.map(({ index }) => [index, index + search.length])
        )
        return result.concat(
          <Button
            key={`${field.id}-checkBox-button`}
            onClick={() => {
              if (added) removeExternalAPIField({ id, fieldId: field.id })
              else addExternalAPIField({ id, field })
            }}
            tertiary={added ? true : undefined}
          >
            <CheckBox active={added} />
            <span>{nameSplit.map(({ type, value }) => (type === 'text' ? value : <mark>{value}</mark>))}</span>
            <span>{typeSplit.map(({ type, value }) => (type === 'text' ? value : <mark>{value}</mark>))}</span>
            <span>{idSplit.map(({ type, value }) => (type === 'text' ? value : <mark>{value}</mark>))}</span>
          </Button>
        )
      }, []),
    [integrationFields, search, addedFields, removeExternalAPIField, id, addExternalAPIField]
  )

  return (
    <>
      <div className={classes.wrapper}>
        <span>Integration Configuration</span>
        <Input
          type="multiline"
          defaultValue={search}
          placeholder="Search for fields..."
          debouncedInput={300}
          showActionButtons={false}
          onInput={event => setSearch(extractValueFromInputEvent('string', event) || '')}
        />
        {render}
      </div>
      <div className={classes2.configEntry.footer}>
        <ButtonGroup>
          <Button2 onClick={concludeConfiguring} colorScheme="primary">
            Conclude
          </Button2>
        </ButtonGroup>
      </div>
    </>
  )
})

IntegrationConfig.displayName = ''

export default IntegrationConfig
