import useStore, { WizardAnswersSelector, WizardExternalAPIsSelector, WizardLanguagesSelector, WizardState } from '___store'
import { getOptionPropertiesFromAnswer } from '___store/storeSegments/wizard/typified/helpers'

import { Answers, ExternalAPIs, LanguageValue, OptionValueProperties } from '___types'

const compareDocumentAndWizardAnswers = (documentAnswers: Answers, wizardAnswers: WizardAnswersSelector): boolean => {
  if (documentAnswers.length !== wizardAnswers?.length) return true
  return Boolean(
    wizardAnswers.find((answer, index) => {
      const documentAnswer = documentAnswers[index]
      if (!documentAnswer || answer.id !== documentAnswer.id) return true
      const answerValueEntries = Object.entries(getOptionPropertiesFromAnswer(answer))
      const documentAnswerOptionProperties = getOptionPropertiesFromAnswer(documentAnswer)
      if (answerValueEntries.length !== Object.keys(documentAnswerOptionProperties).length) return true
      return answerValueEntries.find(([id, properties]) => {
        const relevantDocumentValue = documentAnswerOptionProperties[id]
        if (relevantDocumentValue === undefined || Object.keys(properties).length !== Object.keys(relevantDocumentValue).length) return true
        return Object.entries(properties).find(([key, value]) => relevantDocumentValue[key as keyof OptionValueProperties] !== value)
      })
    })
  )
}

export const isDocumentDirty = (
  wizardLanguages: WizardLanguagesSelector,
  wizardExternalAPIs: WizardExternalAPIsSelector,
  wizardAnswers: WizardAnswersSelector,
  languages?: LanguageValue[],
  externalAPIs?: ExternalAPIs,
  documentAnswers?: Answers
): boolean => (!documentAnswers ? true : compareDocumentAndWizardAnswers(documentAnswers, wizardAnswers)) // implement language and externalAPIs checks

type UseStoreHookResultType = { wizardState: WizardState }

export const useIsDocumentDirty = (languages?: LanguageValue[], externalAPIs?: ExternalAPIs, answers?: Answers) => {
  const {
    wizardState: { languages: wizardLanguages, externalAPIs: wizardExternalAPIs, answers: wizardAnswers },
  } = useStore('selectWizardState') as UseStoreHookResultType
  return isDocumentDirty(wizardLanguages, wizardExternalAPIs, wizardAnswers, languages, externalAPIs, answers)
}

export default useIsDocumentDirty
