import React, { FunctionComponent, useMemo } from 'react'

import { ITemplate } from '___types'
import { useTemplateList } from '___hooks'
import { TemplatesAccordionProps } from '.'
import Accordion from 'components/CasusComponents/Accordion'
import { TemplateListItem } from 'components/CasusComponents/Template'

export const TemplatesAccordion: FunctionComponent<TemplatesAccordionProps> = React.memo(({ filter, onClick }) => {
  const {
    list: { isLoading: listIsLoading, isFetching: listIsFetching, data: { pages = [] } = {} },
    // folders: { isLoading: foldersLoading, isFetching: foldersFetching, error: foldersError, data: folders },
  } = useTemplateList(filter)

  const templates = useMemo(() => {
    const templateEntries = pages.reduce((acc: ITemplate[], page = []) => acc.concat(page), [])
    return templateEntries.map(template => {
      return <TemplateListItem key={`Content-Documents-TemplateListItem-${template.id}`} id={template.id} onClick={onClick} />
    })
  }, [pages, onClick])

  return (
    <Accordion isLoading={listIsLoading} isFetching={listIsFetching}>
      {templates}
    </Accordion>
  )
})

TemplatesAccordion.displayName = 'Casus-Components-Accordion-Templates'

export default TemplatesAccordion
