import { UseQueryResult } from 'react-query'

import { DocumentDownloadFormat, IDocument, ITemplate } from '___types'
import {
  ApproveDocumentFunctionType,
  CreateDocumentFunctionType,
  DownloadDocumentFunctionType,
  DownloadSignedDocumentFunctionType,
  DuplicateDocumentFunctionType,
  PublishDocumentFunctionType,
  RemoveDocumentFunctionType,
  RequestDocumentSignatureFunctionType,
  SubmitDocumentFunctionType,
  UpdateDocumentFunctionType,
  UploadPDFDocumentFunctionType,
  useApproveDocument,
  useCreateDocument,
  useDownloadDocument,
  useDuplicateDocument,
  useFetchDocument,
  usePublishDocument,
  useRemoveDocument,
  useRequestDocumentSignature,
  useSubmitDocument,
  useUpdateDocument,
  useUploadPDFDocument,
} from '___queries'

export type UseDocumentHookUnknownIdReturnType = { [K in keyof UseQueryResult<IDocument>]?: UseQueryResult<IDocument>[K] } & {
  template?: UseQueryResult<ITemplate>
  approve?: ApproveDocumentFunctionType
  create?: CreateDocumentFunctionType
  download?: DownloadDocumentFunctionType
  downloadSigned?: DownloadSignedDocumentFunctionType
  duplicate?: DuplicateDocumentFunctionType
  publish?: PublishDocumentFunctionType
  remove?: RemoveDocumentFunctionType
  requestSignature?: RequestDocumentSignatureFunctionType
  submit?: SubmitDocumentFunctionType
  update?: UpdateDocumentFunctionType
  uploadPDF?: UploadPDFDocumentFunctionType
  approving?: boolean
  creating: boolean
  downloading?: Record<DocumentDownloadFormat | 'signed', boolean>
  duplicating?: boolean
  publishing?: boolean
  removing?: boolean
  requestingSignature?: boolean
  submitting?: boolean
  updating?: boolean
  uploadingPDF?: boolean
}

// ======================= USE DOCUMENT OVERLOAD ======================= //
function useDocument(
  id?: null,
  publicFlow?: boolean
): {
  create: CreateDocumentFunctionType
  uploadPDF: UploadPDFDocumentFunctionType
  creating: boolean
  uploadingPDF: boolean
}
function useDocument(
  id: string,
  publicFlow?: boolean
): UseQueryResult<IDocument> & {
  template: UseQueryResult<ITemplate>
  approve: ApproveDocumentFunctionType
  download: DownloadDocumentFunctionType | undefined
  downloadSigned: DownloadSignedDocumentFunctionType | undefined
  duplicate: DuplicateDocumentFunctionType
  publish: PublishDocumentFunctionType
  remove: RemoveDocumentFunctionType
  requestSignature: RequestDocumentSignatureFunctionType
  submit: SubmitDocumentFunctionType
  update: UpdateDocumentFunctionType
  approving: boolean
  creating: boolean
  downloading: Record<DocumentDownloadFormat | 'signed', boolean>
  duplicating: boolean
  publishing: boolean
  removing: boolean
  requestingSignature: boolean
  submitting: boolean
  updating: boolean
}
// ===================================================================== //
function useDocument(id?: string | null, publicFlow: boolean = false) {
  const document = useFetchDocument(id, publicFlow)
  const { approve, approving } = useApproveDocument(id)
  const { create, creating } = useCreateDocument(publicFlow)
  const { download, downloadSigned, downloading } = useDownloadDocument(id!, publicFlow)
  const { duplicate, duplicating } = useDuplicateDocument(id)
  const { publish, publishing } = usePublishDocument(id)
  const { remove, removing } = useRemoveDocument(id)
  const { requestSignature, requestingSignature } = useRequestDocumentSignature(id, publicFlow)
  const { submit, submitting } = useSubmitDocument(id, publicFlow)
  const { update, updating } = useUpdateDocument(id, publicFlow)
  const { uploadPDF, uploadingPDF } = useUploadPDFDocument()

  if (!id) return { create, uploadPDF, creating, uploadingPDF }

  return Object.assign({}, document, {
    approve,
    download,
    downloadSigned,
    duplicate,
    publish,
    remove,
    requestSignature,
    submit,
    update,
    approving,
    creating,
    downloading,
    duplicating,
    publishing,
    removing,
    requestingSignature,
    submitting,
    updating,
  })
}

export default useDocument
