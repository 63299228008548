import React, { FunctionComponent, Dispatch, SetStateAction, useState, useMemo } from 'react'
import useStore, { WizardLocationByIdSelector, WizardQuestionsSelector, WizardExternalAPIsSelector } from '___store'

import { SegmentsLocation, TextLocation } from '___types'
import { Button } from 'components/CasusComponents'
import { classes } from '../..'
import { Computed, Externals, Questions, Replacement, Languages, Splits } from './Connect.Components'
import MarkerAssign from './MarkerAssign'

type UseStoreHookResultType = {
  wizardLocationById: WizardLocationByIdSelector
  wizardQuestions: WizardQuestionsSelector
  wizardExternalAPIs: WizardExternalAPIsSelector
  currentConfiguringQuestion: any
}

type Tab = 'replacement' | 'show-hide'
type Props = { id: string }
export const Connect: FunctionComponent<Props> = React.memo(({ id }) => {
  const {
    wizardLocationById,
    wizardQuestions,
    wizardExternalAPIs,
    currentConfiguringQuestion: { id: currentQuestionId } = {},
  } = useStore(
    `selectWizardLocationById[${id}]`,
    'selectWizardQuestions',
    'selectWizardExternalAPIs',
    'selectCurrentConfiguringQuestion' // TEMPORARY
  ) as UseStoreHookResultType

  const [tab, setTab]: [Tab, Dispatch<SetStateAction<Tab>>] = useState('replacement' as Tab)

  const { valueSources, conditionals } = (wizardLocationById || {}) as TextLocation | SegmentsLocation

  const optionConnectRender = useMemo(() => {
    //@ts-ignore
    return <MarkerAssign markerId={id} currentQuestionId={currentQuestionId} />
  }, [id, currentQuestionId]) // TEMPORARY

  return (
    <div className={classes.configEntry.markerConfiguration.fragment.wrapper}>
      <span className={classes.configEntry.markerConfiguration.fragment.title}>Connect Marker</span>
      <span className={classes.configEntry.markerConfiguration.fragment.text}>Connect this marker to one or more value sources.</span>
      <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.wrapper}>
        <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.header}>
          <Button onClick={() => setTab('replacement')} dataSet={{ active: tab === 'replacement' ? '' : undefined }}>
            Replacement
          </Button>
          <Button onClick={() => setTab('show-hide')} dataSet={{ active: tab === 'show-hide' ? '' : undefined }}>
            Show or Hide
          </Button>
        </div>
        {tab === 'replacement' ? (
          <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.content}>
            <Replacement id={id} valueSources={valueSources} questions={wizardQuestions} externals={wizardExternalAPIs} />
            <Questions id={id} valueSources={valueSources} />
            <Externals id={id} valueSources={valueSources} />
            <Computed id={id} valueSources={valueSources} />
          </div>
        ) : (
          <div className={classes.configEntry.markerConfiguration.fragment.connect.tabs.content}>
            {optionConnectRender}
            <Languages id={id} conditionals={conditionals} />
            <Splits id={id} conditionals={conditionals} />
          </div>
        )}
      </div>
    </div>
  )
})

Connect.displayName = 'Wizard-Configuration-MarkerConfiguration-Connect'

export default Connect
