import { QueryFunctionContext, useInfiniteQuery } from 'react-query'

import { TEMPLATES_FILTERS } from '___types'
import { templatesAPI, TEMPLATE_LIST_PER_PAGE } from '___api'
import { QUERY_KEYS, STALE_TIME } from '___queries'
import { useUser } from '___hooks'
import { tenantPaywallCheck } from '___utilities'

export const getTemplateListQueryFunction = ({ queryKey, pageParam }: QueryFunctionContext, requiresPayment: boolean) =>
  templatesAPI.getTemplateList(queryKey[1] as string, pageParam as string, requiresPayment)

export const useFetchTemplateList = (filter?: string | null) => {
  const { data: user } = useUser()

  const mapTemplateList = (context: QueryFunctionContext) => {
    const paywall = !(user && !tenantPaywallCheck(user))
    const paid = user?.subscriptions?.some(subscription => subscription.status === 'active')
    return getTemplateListQueryFunction(context, paywall && !paid)
  }
  const templateListQuery = useInfiniteQuery({
    queryKey: [QUERY_KEYS.TEMPLATES].concat(filter === undefined ? TEMPLATES_FILTERS.MINE : filter ?? []),
    queryFn: mapTemplateList,
    getNextPageParam: lastPage => {
      if (!lastPage || lastPage.length < TEMPLATE_LIST_PER_PAGE) return
      const lastTemplate = lastPage[lastPage.length - 1]
      return lastTemplate?.id
    },
    staleTime: filter === TEMPLATES_FILTERS.MINE ? Infinity : STALE_TIME,
    refetchOnWindowFocus: false,
  })
  return templateListQuery
}

export default useFetchTemplateList
