import { InfiniteData } from 'react-query'
import {
  useApproveDocument,
  useCreateDocument,
  useDownloadDocument,
  useDuplicateDocument,
  useFetchDocument,
  useFetchDocumentFolderList,
  useFetchDocumentList,
  usePublishDocument,
  useRemoveDocument,
  useRequestDocumentSignature,
  useSubmitDocument,
  useUpdateDocument,
  useUploadPDFDocument,
} from './documents'
import { useUploadToEmployee, useFetchEmployeeCategories } from './employees'
import { useSubscribe, useFetchSubscriptionList } from './subscriptions'
import {
  useCreateTemplate,
  useCreateTemplateFolder,
  useDuplicateTemplate,
  useFetchTemplate,
  useFetchTemplateList,
  useFetchTemplateFolderList,
  useFetchTemplatePreviewImageSrcURL,
  useRemoveTemplate,
  useShareTemplate,
  useUnshareTemplate,
  useUpdateTemplate,
  useUploadTemplatePreviewImage,
} from './templates'
import { useFetchExternalServices, useFetchExternalServiceEntries, useFetchExternalServiceEntry, useFetchUser, useLogoutUser } from './users'

const QUERY_KEYS = {
  USER: 'QUERY_USER',
  TEAM: 'QUERY_TEAM',
  DOCUMENTS: 'QUERY_DOCUMENTS',
  DOCUMENT_FOLDERS: 'QUERY_DOCUMENT_FOLDERS',
  DOCUMENT: 'QUERY_DOCUMENT',
  DOCUMENT_DOWNLOAD: 'QUERY_DOCUMENT_DOWNLOAD',
  SIGNED_DOCUMENT_DOWNLOAD: 'QUERY_SIGNED_DOCUMENT_DOWNLOAD',
  TEMPLATES: 'QUERY_TEMPLATES',
  TEMPLATE_FOLDERS: 'QUERY_TEMPLATE_FOLDERS',
  TEMPLATE: 'QUERY_TEMPLATE',
  TEMPLATE_PREVIEW_IMAGE: 'QUERY_TEMPLATE_PREVIEW_IMAGE',
  EXTERNAL_SERVICES: 'QUERY_EXTERNAL_SERVICES', // ------------------ eg. Bamboo HR
  EXTERNAL_SERVICE_ENTRIES: 'QUERY_EXTERNAL_SERVICE_ENTRIES', // ---- eg. employee list
  EXTERNAL_SERVICE_ENTRY: 'QUERY_EXTERNAL_SERVICE_ENTRY', // -------- eg. Pera Peric (an employee)
  EXTERNAL_SERVICE_EMPLOYEE_CATEGORIES: 'QUERY_EXTERNAL_SERVICE_EMPLOYEE_CATEGORIES', // eg. Pera Peric's categories
  UPLOAD_TO_EXTERNAL: 'MUTATE_UPLOAD_TO_EXTERNAL',
  SUBSCRIPTIONS: 'QUERY_SUBSCRIPTIONS',
  SUBSCRIPTION: 'QUERY_SUBSCRIPTION',
}
const STALE_TIME = 5 * 60 * 1000
export { QUERY_KEYS, STALE_TIME }

export type {
  ApproveDocumentVariables,
  ApproveDocumentFunctionType,
  CreateDocumentVariables,
  CreateDocumentFunctionType,
  DownloadDocumentVariables,
  DownloadDocumentFunctionType,
  DownloadSignedDocumentVariables,
  DownloadSignedDocumentFunctionType,
  DuplicateDocumentVariables,
  DuplicateDocumentFunctionType,
  PublishDocumentVariables,
  PublishDocumentFunctionType,
  RemoveDocumentVariables,
  RemoveDocumentFunctionType,
  RequestDocumentSignatureVariables,
  RequestDocumentSignatureFunctionType,
  SubmitDocumentVariables,
  SubmitDocumentFunctionType,
  UpdateDocumentVariables,
  UpdateDocumentFunctionType,
  UploadPDFDocumentVariables,
  UploadPDFDocumentFunctionType,
} from './documents'
export type {
  CreateTemplateVariables,
  CreateTemplateFunctionType,
  DuplicateTemplateVariables,
  DuplicateTemplateFunctionType,
  RemoveTemplateVariables,
  RemoveTemplateFunctionType,
  ShareTemplateVariables,
  ShareTemplateFunctionType,
  UnshareTemplateVariables,
  UnshareTemplateFunctionType,
  UpdateTemplateVariables,
  UpdateTemplateFunctionType,
  UploadImageVariables,
} from './templates'

export {
  useApproveDocument,
  useCreateDocument,
  useDownloadDocument,
  useDuplicateDocument,
  useFetchDocument,
  useFetchDocumentFolderList,
  useFetchDocumentList,
  usePublishDocument,
  useRemoveDocument,
  useRequestDocumentSignature,
  useSubmitDocument,
  useUpdateDocument,
  useUploadPDFDocument,
}
export { useUploadToEmployee, useFetchEmployeeCategories }
export { useSubscribe, useFetchSubscriptionList }
export {
  useCreateTemplate,
  useCreateTemplateFolder,
  useDuplicateTemplate,
  useFetchTemplate,
  useFetchTemplateList,
  useFetchTemplateFolderList,
  useFetchTemplatePreviewImageSrcURL,
  useRemoveTemplate,
  useShareTemplate,
  useUnshareTemplate,
  useUpdateTemplate,
  useUploadTemplatePreviewImage,
}
export { useFetchExternalServices, useFetchExternalServiceEntries, useFetchExternalServiceEntry, useFetchUser, useLogoutUser }

const reduceEntriesIntoPages = <T extends { id: string }>(entries: T[], perPage: number): InfiniteData<T[]> =>
  entries.reduce(
    (accumulated, entry) => {
      const { pages, pageParams } = accumulated
      const lastPage = pages[pages.length - 1]
      if (!lastPage) {
        pages.push([entry])
        pageParams.push(null)
        return accumulated
      }
      if (lastPage.length < perPage) lastPage.push(entry)
      else {
        pages.push([entry])
        const lastEntry = lastPage[perPage - 1]
        pageParams.push(lastEntry.id)
      }
      return accumulated
    },
    { pages: [] as T[][], pageParams: [] as (string | null)[] }
  )

export const infiniteQueryUnshift = <T extends { id: string }>(data: InfiniteData<T[]>, perPage: number, toPrepend: T): InfiniteData<T[]> =>
  reduceEntriesIntoPages([Object.assign({}, toPrepend)].concat(data?.pages.flat(1) || []), perPage)

export const infiniteQueryFilter = <T extends { id: string }>(
  data: InfiniteData<T[]>,
  perPage: number,
  filterTest: (entry: T) => boolean
): InfiniteData<T[]> => {
  const entries = data?.pages.flat(1) || []
  const filteredEntries = entries.filter(filterTest)
  if (entries.length === filteredEntries.length) return data || reduceEntriesIntoPages(entries, perPage)
  return reduceEntriesIntoPages(filteredEntries, perPage)
}

export const infiniteQueryReplace = <T extends { id: string }>(
  data: InfiniteData<T[]>,
  perPage: number,
  replaceTest: (entry: T) => boolean,
  replaceWith: T
): InfiniteData<T[]> => {
  const entries = data?.pages.flat(1) || []
  const entryIndex = entries.findIndex(replaceTest)
  if (entryIndex === -1) return data || reduceEntriesIntoPages(entries, perPage)
  entries.splice(entryIndex, 1, replaceWith)
  return reduceEntriesIntoPages(entries, perPage)
}

export const infiniteQueryMap = <T extends { id: string }>(
  data: InfiniteData<T[]>,
  perPage: number,
  mapper: (value: T, index: number, array: T[]) => unknown & { id: string }
): InfiniteData<(unknown & { id: string })[]> => {
  const entries = data?.pages.flat(1) || []
  const mappedEntries = entries.map(mapper)
  return reduceEntriesIntoPages(mappedEntries, perPage)
}
