import { v4 as uuid } from 'uuid'
import { InfiniteData, MutateOptions, useMutation, useQueryClient } from 'react-query'

import { TEMPLATES_FILTERS, ITemplate } from '___types'
import { templatesAPI } from '___api'
import { TEMPLATE_LIST_PER_PAGE } from '___api/api.templates'
import { QUERY_KEYS, infiniteQueryFilter, infiniteQueryReplace, infiniteQueryUnshift } from '___queries'
// import { getTemplateListQueryFunction } from './useFetchTemplateList'

export type DuplicateTemplateVariables = { id: string; category?: string }
const duplicateTemplateMutationFunction = (variables: DuplicateTemplateVariables) => templatesAPI.duplicateTemplate(variables.id)

export const useDuplicateTemplate = (id?: string | null) => {
  const queryClient = useQueryClient()
  const templateDuplicateMutation = useMutation<ITemplate, unknown, DuplicateTemplateVariables, { mutationId: string }>(
    [QUERY_KEYS.TEMPLATE, id],
    duplicateTemplateMutationFunction,
    {
      onMutate: variables => {
        const currentTemplate = queryClient.getQueryData([QUERY_KEYS.TEMPLATE, id]) as ITemplate
        const mutationId = uuid()
        const duplicatedTemplate = Object.assign(JSON.parse(JSON.stringify(currentTemplate)), { id: mutationId, beingDuplicated: true })
        queryClient.setQueryData([QUERY_KEYS.TEMPLATE, mutationId], duplicatedTemplate)
        if (variables.category)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.category ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
            infiniteQueryUnshift(data!, TEMPLATE_LIST_PER_PAGE, duplicatedTemplate)
          )
        return { mutationId }
      },
      onError: (error, variables, context) => {
        if (variables.category)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.category ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
            infiniteQueryFilter(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === context?.mutationId)
          )
      },
      onSuccess: (template, variables, context) => {
        queryClient.setQueryData([QUERY_KEYS.TEMPLATE, context?.mutationId], template)
        if (variables.category)
          queryClient.setQueryData([QUERY_KEYS.TEMPLATES].concat(variables.category ?? []), (data: InfiniteData<ITemplate[]> | undefined) =>
            infiniteQueryReplace(data!, TEMPLATE_LIST_PER_PAGE, entry => entry.id === context?.mutationId, template)
          )
      },
      // onSettled: async (template, error, variables, context) => {
      //   queryClient.removeQueries([QUERY_KEYS.TEMPLATE, context?.mutationId])
      //   // =========================================================================================== //
      //   // ==================================== REFETCH TEMPLATES ==================================== //
      //   // =========================================================================================== //
      //   const queryKey = [QUERY_KEYS.TEMPLATES].concat(variables.category ?? [])
      //   queryClient.cancelQueries(queryKey) // async
      //   queryClient.invalidateQueries(queryKey) // async
      //   queryClient.fetchInfiniteQuery({ queryKey, queryFn: getTemplateListQueryFunction }) // async
      //   // =========================================================================================== //
      // },
    }
  )

  const duplicateMutationFunction = (
    category: string = TEMPLATES_FILTERS.MINE,
    options?: MutateOptions<ITemplate | undefined, unknown, DuplicateTemplateVariables, { mutationId: string }>
  ) => templateDuplicateMutation.mutate({ id: id!, category }, options)
  return { duplicate: duplicateMutationFunction, duplicating: templateDuplicateMutation.isLoading }
}

export type DuplicateTemplateFunctionType = (
  category?: string,
  options?: MutateOptions<ITemplate | undefined, unknown, DuplicateTemplateVariables, { mutationId: string }>
) => void

export default useDuplicateTemplate
